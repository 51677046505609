import React from 'react';
import history from '../Configs/history'

const FrontHeader = () => {
  return (
    <div className='footer'>
      <div className='row cRow'>
        <div className='col-md-6'>
          <h4>
          Hire <span style={{ color: '#3aa7e4' }}>Interviewer,Inc</span>
          </h4>
        </div>

        <div className='col-md-6'>
          <div className='row'>
            <div className='col-md-6'>
              <h5>Sitemap</h5>
              <p onClick={() => history.push('/terms-and-conditions')} style={{cursor: 'pointer'}}>Terms and conditions</p>
            </div>
            <div className='col-md-6'>
              <h5>Contact</h5>
              <p>Contact@experts-labs.com</p>
              {/* <p>sales@hello.com</p> */}
              {/* <p>+009233333333</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className='row cRow'>
        <div className='col-md-6'>
          <p>&copy; Copyright 2021 Hire Interviewer,Inc</p>
        </div>

        <div className='col-md-3'></div>
        <div className='col-md-3'>
          {/* <i className='fab fa-linkedin-in footer-icon'></i>
          <i className='fab fa-twitter footer-icon'></i>
          <i className='fab fa-pinterest footer-icon'></i> */}
        </div>
      </div>
    </div>
  );
};

export default FrontHeader;
