import React from 'react';

const InputField = ({
  value,
  label,
  name,
  placeholder,
  type,
  keyRef,
  keyError,
  onChange,
  style
}) => {
  return (
    <div className='form-group'>
      {label && <label htmlFor='input-field'>{label}</label>}
      <input
        style={style}
        type={type}
        defaultValue={value}
        name={name}
        ref={keyRef}
        className='form-control custom-input'
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
