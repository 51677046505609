import React from 'react';

export default function Expert({ title, font }) {
  return (
    <div className='col-md-3'>
      <div className='e-p'>
        <div>
          <i className={`${font} e-icon`}></i>
        </div>
        <div>
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
}
