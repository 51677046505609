import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import userSagas from './User';
import clientProfileSagas from './ClientProfile';
import expertProfileSagas from './ExpertProfile';
import commonSagas from './Common';
import dashboardSagas from './Dashboard';
import adminRequestSagas from './AdminRequest';

import requestSagas from './Request';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    clientProfileSagas(),
    userSagas(),
    expertProfileSagas(),
    commonSagas(),
    dashboardSagas(),
    requestSagas(),
    adminRequestSagas()
  ]);
}
