import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_SINGLE_USER,
  FETCH_SINGLE_USER_SUCCESS,
  FETCH_SINGLE_USER_ERROR,
  FILTER_USERS,
  FILTER_USERS_SUCCESS,
  FILTER_USERS_ERROR,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_ERROR
} from '../constants';

export const fetchUser = () => ({
  type: FETCH_USER
});

export const fetchUserSuccess = details => ({
  type: FETCH_USER_SUCCESS,
  details
});

export const fetchuserError = status => ({
  type: FETCH_USER_ERROR,
  status
});

export const fetchSingleUser = id => ({
  type: FETCH_SINGLE_USER,
  id
});

export const fetchSingleUserError = status => ({
  type: FETCH_SINGLE_USER_ERROR,
  status
});

export const fetchSingleUserSuccess = user => ({
  type: FETCH_SINGLE_USER_SUCCESS,
  user
});

export const filterUsers = (query, role, status) => ({
  type: FILTER_USERS,
  query,
  role,
  status
});

export const filterUsersSuccess = details => ({
  type: FILTER_USERS_SUCCESS,
  details
});

export const filterUsersError = status => ({
  type: FILTER_USERS_ERROR,
  status
});

export const updateUserStatus = (details, id) => ({
  type: UPDATE_USER_STATUS,
  details,
  id
});

export const updateUserStatusSucess = () => ({
  type: UPDATE_USER_STATUS_SUCCESS
});

export const updateUserStatusError = status => ({
  type: UPDATE_USER_STATUS_ERROR,
  status
});
