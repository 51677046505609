import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../../Components/InputField';
import Spinner from '../../../../Components/Spinner';
import { login } from '../../../../actions';
import { useForm } from 'react-hook-form';

export default function LoginModal({ setAuthStatus }) {
  const dispatch = useDispatch();
  const authSelector = useSelector(state => state.auth);
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => dispatch(login(data));
  return (
    <div>
      <div className='m-body'>
        <p className='title'>Welcome Back</p>
        <p className='subTitle'>Login with your email & password</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            keyError={errors}
            name='email'
            type='email'
            value=''
            keyRef={register({ required: true })}
            placeholder='Email'
          />
          {errors.email && <span className='error'>Email is required</span>}
          <InputField
            keyError={errors}
            name='password'
            type='password'
            keyRef={register({ required: true })}
            value=''
            placeholder='Password'
          />
          {errors.password && (
            <span className='error'>Password is required</span>
          )}
          <button
            disabled={authSelector.state === 'loading' ? true : false}
            className='btn btn-block btn-primary custom-btn'
            type='submit'
          >
            {authSelector.state === 'loading' ? <Spinner /> : 'Continue'}
          </button>
        </form>
        <p className='subTitle' style={{ marginTop: 20 }}>
          Forgot your password.{' '}
          <span
            style={{ color: '#3aa7e4', fontWeight: 'bold' }}
            onClick={() => setAuthStatus('reset')}
          >
            Reset it
          </span>
        </p>
      </div>
      <div className='modal-footer-body'>
        <p className='subTitle text-center' style={{ marginTop: 20 }}>
          Already have an account.{' '}
          <span
            style={{ color: '#3aa7e4', fontWeight: 'bold' }}
            onClick={() => setAuthStatus('signup')}
          >
            Sign Up
          </span>
        </p>
      </div>
    </div>
  );
}
