import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN,
  ADMIN_LOGIN,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_ERROR,
  REGISTER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  ADMIN_LOGOUT,
  ADMIN_LOGOUT_SUCCESS,
  ADMIN_LOGOUT_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VERIFY_MAIL,
  VERIFY_MAIL_SUCCESS,
  VERIFY_MAIL_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  FORGOT_ADMIN_PASSWORD,
  FORGOT_ADMIN_PASSWORD_SUCCESS,
  FORGOT_ADMIN_PASSWORD_ERROR,
  RESET_ADMIN_PASSWORD,
  RESET_ADMIN_PASSWORD_SUCCESS,
  RESET_ADMIN_PASSWORD_ERROR,
  CLEAR_USER,
  CLEAR_ADMIN
} from '../constants';

const initialAuthState = {
  state: 'initial',
  mode: 'initial',
  status: ''
};

export function authReducer(state = initialAuthState, action) {
  switch (action.type) {
    case ADMIN_LOGIN:
      return { ...state, mode: 'adminLogin', state: 'loading', status: '' };
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        admin: action.user,
        adminToken: action.token,
        state: 'success'
      };
    case ADMIN_LOGIN_ERROR:
      return { ...state, state: 'error', status: action.status };
    case LOGIN:
      return { ...state, mode: 'login', state: 'loading', status: '' };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        token: action.token,
        state: 'success'
      };
    case LOGIN_ERROR:
      return { ...state, state: 'error', status: action.status };
    case REGISTER:
      return { ...state, mode: 'register', state: 'loading', status: '' };
    case REGISTER_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };
    case REGISTER_ERROR:
      return { ...state, state: 'error', status: action.status };
    case LOGOUT:
      return { ...state, state: 'loading', mode: 'logout', status: '' };
    case LOGOUT_SUCCESS:
      return { ...state, state: 'success', user: {}, token: '' };
    case LOGOUT_ERROR:
      return { ...state, state: 'error', status: action.status };
    case ADMIN_LOGOUT:
      return { ...state, state: 'loading', mode: 'admin_logout', status: '' };
    case ADMIN_LOGOUT_SUCCESS:
      return { ...state, state: 'success', admin: {}, adminToken: '' };
    case ADMIN_LOGOUT_ERROR:
      return { ...state, state: 'error', status: action.status };
    case CLEAR_USER:
      return { ...state, user: {}, token: '' };
    case CLEAR_ADMIN:
      return { ...state, admin: {}, adminToken: '' };
    case FORGOT_PASSWORD:
      return {
        ...state,
        mode: 'forget-password',
        state: 'loading',
        status: ''
      };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, state: 'success' };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, state: 'error', status: action.status };
    case RESET_PASSWORD:
      return {
        ...state,
        mode: 'reset-password',
        state: 'loading',
        status: ''
      };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, state: 'success' };
    case RESET_PASSWORD_ERROR:
      return { ...state, state: 'error', status: action.status };

    case FORGOT_ADMIN_PASSWORD:
      return {
        ...state,
        mode: 'forget-admin-password',
        state: 'loading',
        status: ''
      };
    case FORGOT_ADMIN_PASSWORD_SUCCESS:
      return { ...state, state: 'success' };
    case FORGOT_ADMIN_PASSWORD_ERROR:
      return { ...state, state: 'error', status: action.status };
    case RESET_ADMIN_PASSWORD:
      return {
        ...state,
        mode: 'reset-admin-password',
        state: 'loading',
        status: ''
      };
    case RESET_ADMIN_PASSWORD_SUCCESS:
      return { ...state, state: 'success' };
    case RESET_ADMIN_PASSWORD_ERROR:
      return { ...state, state: 'error', status: action.status };

    case CHANGE_PASSWORD:
      return {
        ...state,
        mode: 'change-password',
        state: 'loading',
        status: ''
      };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, state: 'success' };
    case CHANGE_PASSWORD_ERROR:
      return { ...state, state: 'error', status: action.status };

    case VERIFY_MAIL:
      return {
        ...state,
        mode: 'verify-email',
        state: 'loading',
        status: ''
      };
    case VERIFY_MAIL_SUCCESS:
      return { ...state, state: 'success' };
    case VERIFY_MAIL_ERROR:
      return { ...state, state: 'error', status: action.status };
    default:
      return state;
  }
}
