import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../Components/InputField';
import Spinner from '../../Components/Spinner';
import history from '../../Configs/history';
import { forgotAdminPassword } from '../../actions';
import { useForm } from 'react-hook-form';

const ForgotAdminPassword = () => {
  const dispatch = useDispatch();
  const authSelector = useSelector(state => state.auth);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => dispatch(forgotAdminPassword(data));
  return (
    <div className='auth-bg'>
      <div className='auth'>
        <h3 className='logo' onClick={() => history.push('/')}>
          Enter <span style={{ color: '#0d1136' }}>Marketplace</span>
        </h3>
        <div className='au-section'>
          <p className='title'>Forgot Password</p>
          <p className='subTitle'>
            We'll send you a link to reset your password
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              keyError={errors}
              name='email'
              type='email'
              value=''
              keyRef={register({ required: true })}
              placeholder='Email'
            />
            {errors.email && <span className='error'>Email is required</span>}

            <button
              disabled={authSelector.state === 'loading' ? true : false}
              className='btn btn-block btn-primary custom-btn'
              type='submit'
            >
              {authSelector.state === 'loading' ? <Spinner /> : 'Continue'}
            </button>
          </form>

          <p className='subTitle text-center' style={{ marginTop: 20 }}>
            Back to{' '}
            <span
              style={{ color: '#3aa7e4', fontWeight: 'bold' }}
              onClick={() => history.push('/admin')}
            >
              Login
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotAdminPassword;
