import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import history from '../Configs/history';
import {
  Dashboard,
  QuestionAnswerOutlined,
  DoneAllOutlined,
  ClearAllOutlined
} from '@material-ui/icons';
import { adminLogoutUser } from '../actions';

export default function LeftSideBar() {
  const dispatch = useDispatch();
  return (
    <div className='sidebar'>
      <div className='innderSidebar'>
        <NavLink
          activeClassName='active'
          exact
          to='/dashboard'
          title='Dashboard'
        >
          <Dashboard className='sidebarIcon' />
          <span className='sidebarText'>Dashboard</span>
        </NavLink>
        <NavLink to='/users' activeClassName='active' exact title='Users'>
          <i className='fas fa-users sidebarIcon'></i>{' '}
          <span className='sidebarText'>Users</span>
        </NavLink>
        <NavLink to='/requests' activeClassName='active' exact title='Requests'>
          {' '}
          <QuestionAnswerOutlined className='sidebarIcon' />
          <span className='sidebarText'>Requests</span>
        </NavLink>
        <NavLink
          to='/project-cancelled'
          activeClassName='active'
          exact
          title='Project Cancelled'
        >
          {' '}
          <ClearAllOutlined className='sidebarIcon' />
          <span className='sidebarText'>Project Cancelled</span>
        </NavLink>
        <NavLink
          to='/project-completion'
          activeClassName='active'
          exact
          title='Project Completion'
        >
          {' '}
          <DoneAllOutlined className='sidebarIcon' />
          <span className='sidebarText'>Project Completion</span>
        </NavLink>

        <div
          style={{
            position: 'absolute',
            bottom: 0,
            padding: 15
          }}
        >
          {' '}
          <div
            onClick={() =>
              history.push({
                pathname: '/changePassword',
                search: '?changeType=Admin'
              })
            }
            style={{ marginBottom: 20 }}
          >
            <i className='fas fa-user sidebarIcon'></i>
            <span className='sidebarText'>Change Password</span>
          </div>
          <div onClick={() => dispatch(adminLogoutUser())}>
            <i className='fas fa-sign-out-alt sidebarIcon'></i>
            <span className='sidebarText'>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
}
