import {
  FETCH_CLIENT_PROFILE,
  FETCH_CLIENT_PROFILE_SUCCESS,
  FETCH_CLIENT_PROFILE_ERROR,
  UPDATE_CLIENT_PROFILE,
  UPDATE_CLIENT_PROFILE_SUCCESS,
  UPDATE_CLIENT_PROFILE_ERROR,
  UPLOAD_CLIENT_PROFILE_MEDIA,
  UPLOAD_CLIENT_PROFILE_MEDIA_SUCCESS,
  UPLOAD_CLIENT_PROFILE_MEDIA_ERROR,
  FETCH_CLIENT_EXPERT_PROFILE,
  FETCH_CLIENT_EXPERT_PROFILE_SUCCESS,
  FETCH_CLIENT_EXPERT_PROFILE_ERROR,
  CREATE_EXPERT_REQUEST,
  CREATE_EXPERT_REQUEST_SUCCESS,
  CREATE_EXPERT_REQUEST_ERROR,
  FILTER_EXPERT_PROFILE,
  FILTER_EXPERT_PROFILE_SUCCESS,
  FILTER_EXPERT_PROFILE_ERROR,
  SET_OFFSET_COUNT,
  CLEAR_CLIENT_STATE
} from '../constants';

const initialAuthState = {
  state: 'initial',
  mode: 'initial',
  userProfile: {},
  count: 1,
  offset: -1,
  experts: [],
  requests: [],
  status: ''
};

export function clientProfileReducer(state = initialAuthState, action) {
  switch (action.type) {
    case FETCH_CLIENT_PROFILE:
      return { ...state, mode: 'fetch', state: 'loading', status: '' };

    case FETCH_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        state: 'success',
        userProfile: action.details,
        status: ''
      };

    case FETCH_CLIENT_PROFILE_ERROR:
      return { ...state, state: 'error', status: action.status };

    case FETCH_CLIENT_EXPERT_PROFILE:
      return {
        ...state,
        mode: 'fetch_experts_profile',
        state: 'loading',
        status: ''
      };

    case FETCH_CLIENT_EXPERT_PROFILE_SUCCESS:
      let prevArray = state.experts;
      let newArray = action.details;
      newArray.map(arr => {
        return prevArray.indexOf(arr.id) === -1 && prevArray.push(arr);
      });

      return {
        ...state,
        state: 'success',
        count: action.count,
        experts: prevArray,
        status: ''
      };

    case SET_OFFSET_COUNT:
      return {
        ...state,
        offset: action.offset,
        status: ''
      };

    case FETCH_CLIENT_EXPERT_PROFILE_ERROR:
      return { ...state, state: 'error', status: action.status };

    case FILTER_EXPERT_PROFILE:
      return {
        ...state,
        mode: 'fetch_experts_profile',
        state: 'loading',
        status: ''
      };

    case FILTER_EXPERT_PROFILE_SUCCESS:
      return {
        ...state,
        state: 'success',
        experts: action.details,
        status: ''
      };

    case FILTER_EXPERT_PROFILE_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPDATE_CLIENT_PROFILE:
      return { ...state, mode: 'update', state: 'loading', status: '' };

    case UPDATE_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPDATE_CLIENT_PROFILE_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPLOAD_CLIENT_PROFILE_MEDIA:
      return { ...state, mode: 'upload', state: 'loading', status: '' };

    case UPLOAD_CLIENT_PROFILE_MEDIA_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPLOAD_CLIENT_PROFILE_MEDIA_ERROR:
      return { ...state, state: 'error', status: action.status };

    case CREATE_EXPERT_REQUEST:
      return {
        ...state,
        mode: 'create_request',
        state: 'loading',
        status: ''
      };

    case CREATE_EXPERT_REQUEST_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case CREATE_EXPERT_REQUEST_ERROR:
      return { ...state, state: 'error', status: action.status };

    case CLEAR_CLIENT_STATE:
      return { ...state, status: '' };

    default:
      return state;
  }
}
