import React from 'react';
import Expert from './Expert';
import { leftBanner } from '../Assets';
import Join from './Join'

export default function PopuplarExperts() {
  console.log()
  return (
    <div className='main-banner-section'>
      <div className='popular-details'>
        <div className=''>
          <h2>Most Popular Experts</h2>

          <div className='e-parent row' style={{ marginTop: 20 }}>
            <Expert title='Educational' font='far fa-list-alt' />
            <Expert title='Banking' font='fas fa-dollar-sign' />
            <Expert title='Technology' font='fas fa-chalkboard' />
            <Expert title='Financial' font='fas fa-chart-bar' />
          </div>
        </div>

        {/* Why Section */}
          <div className='interview'>
          <h2 className='int-h3'>
          Why <span style={{ color: '#3aa7e4' }}>Experts </span> Labs?
          </h2>
          <p>
          Our Experts are neither our in-house employees nor they are full-time freelancers. They are highly reputed and well knows experts working in reputed organizations. 
           
          We have top industry experts and renowned authors along with faculty members which you will not find on other platforms.
          </p>
        </div>

        <div className='main-banner-section'>
        <div className='banner-details'>
          <div className='banner-img'>
            <img alt='Banner' src={leftBanner} />
          </div>
          <div className='' style={{ marginLeft: 30 }}>
            <h2 className='int-h3'>
              How It <span style={{ color: '#3aa7e4' }}>Works</span>!
            </h2>
            <p style={{  paddingRight: 0 }}>
              <strong>E</strong>xperts <strong>L</strong>abs is exclusive
              network of top experts working in well-known companies, senior
              faculty from prestigious universities, books & technical blog
              writers and the most valuable professionals from every major
              Industry.
     
              <br />
              Companies can choose from our On-Board Experts for their essential
              tasks.
              <br />
              Step 1: Create free login.
              <br />
              Step 2: Browse\Search expert according to your need. 
              <br />
              Step 3: Send invite with your customize requirement.  
              <br />

              Our team will help the client companies in every aspect.
              <br />
            </p>
            <div className='adminNotes' style={{ marginTop: '-30px', flexDirection: 'row' }}>
              <Join text='Get Hired' action='signup' customClass='custom-adm-btn' />
            </div>
          </div>
        </div>
      </div>


        <div className='interview'>
          {/* <h3 className='int-h3' style={{marginBottom: 30}}>
          <span style={{ color: '#3aa7e4' }}>Handpicked Experts</span> can help hiring managers.
          </h3> */}
          {/* <p className='text-center'>
            <strong style={{ color: '#3aa7e4' }}>
              In your crucial task, get help from your Hand-Selected Expert
            </strong>
          </p> */}

          <div>
            <iframe
              width='100%'
              height='600'
              src='https://www.youtube.com/embed/v_YVxtwJjAY'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {/*  */}
      </div>
    </div>
  );
}
