import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import apiUrl from '../Configs/ApiUrl';
import apiCall from '../Services/ApiCall';
import {
  FETCH_SKILLS_PROFILE,
  FETCH_INDUSTRIES_PROFILE,
  FETCH_SERVICES_PROFILE
} from '../constants';
import {
  fetchSkillsSuccess,
  fetchSkillsError,
  fetchServicesSuccess,
  fetchServicesError,
  fetchIndustriesSuccess,
  fetchIndustriesError,
  logoutUser
} from '../actions';
import AuthorizedGetHeaders from '../Configs/AuthorizedGetHeaders';
import { tokenSelector } from '../selectors/authSelecter';

export function* fetchSkillDetails() {
  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/skills`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchSkillsError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(fetchSkillsSuccess(response.data.Skills));
    }
  } catch (e) {
    yield put(fetchSkillsError(e.message));
  }
}

function* fetchSkillsSaga() {
  yield takeLatest(FETCH_SKILLS_PROFILE, fetchSkillDetails);
}

export function* fetchServicesDetails() {
  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/services`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchServicesError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(fetchServicesSuccess(response.data));
    }
  } catch (e) {
    yield put(fetchServicesError(e.message));
  }
}

function* fetchServicesSaga() {
  yield takeLatest(FETCH_SERVICES_PROFILE, fetchServicesDetails);
}

export function* fetchIndustriesDetails() {
  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/industries`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchIndustriesError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(fetchIndustriesSuccess(response.data.Industries));
    }
  } catch (e) {
    yield put(fetchIndustriesError(e.message));
  }
}

function* fetchIndustriesSaga() {
  yield takeLatest(FETCH_INDUSTRIES_PROFILE, fetchIndustriesDetails);
}

export default function* rootSaga() {
  yield all([fetchSkillsSaga(), fetchServicesSaga(), fetchIndustriesSaga()]);
}
