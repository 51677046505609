import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import apiUrl from '../Configs/ApiUrl';
import apiCall from '../Services/ApiCall';
import { toastr } from 'react-redux-toastr';
import {
  UPDATE_EXPERT_GENERAL_DETAILS,
  FETCH_EXPERT_PROFILE,
  UPLOAD_EXPERT_PROFILE_MEDIA,
  CREATE_EXPERT_ACHEIVEMENT,
  CREATE_EXPERT_PUBLICATION,
  UPDATE_EXPERT_INDUSTRY,
  CREATE_TIMINGS,
  UPDATE_EXPERT_PUBLICATION
} from '../constants';
import {
  updateGeneralDetailsSuccess,
  updateGeneralDetailsError,
  clearUser,
  fetchExpertProfileError,
  fetchExpertProfileSuccess,
  uploadExpertProfileMediaSuccess,
  uploadExpertProfileMediaError,
  fetchExpertProfile,
  createAcheivementSuccess,
  createAcheivementError,
  createPublicationSuccess,
  createPublicationError,
  updateExpertIndustrySuccess,
  updateExpertIndustryError,
  createTimingsSuccess,
  createTimingsError,
  updatePublicationError,
  logoutUser
} from '../actions';
import AuthorizedPutHeaders from '../Configs/AuthorizedPutHeaders';
import AuthorizedPutMediaHeaders from '../Configs/AuthorizedPutMediaHeaders';
import AuthorizedGetHeaders from '../Configs/AuthorizedGetHeaders';
import AuthorizedPostHeaders from '../Configs/AuthorizedPostHeaders';
import AuthorizedPutDataHeaders from '../Configs/AuthorizedPutDataHeaders';
import AuthorizedDeleteHeaders from '../Configs/AuthorizedDeleteHeaders';
import AuthorizedPostMediaHeaders from '../Configs/AuthorizedPostMediaHeaders';
import { tokenSelector } from '../selectors/authSelecter';

export function* updateExpertGeneral(action) {
  const token = yield select(tokenSelector);
  let requestUrl = '';

  let options = '';
  if (action.formType === 'formData') {
    requestUrl = `${apiUrl}/expertProfile/${action.endPoint}`;
    options = AuthorizedPutDataHeaders(action.details, token);
  } else if (action.formType === 'media') {
    requestUrl = `${apiUrl}/expertProfile/${action.endPoint}`;
    options = AuthorizedPutDataHeaders(action.details, token);
  } else if (action.formType === 'delete') {
    requestUrl = `${apiUrl}/expertProfile/${action.endPoint}?id=${action.details.id}`;
    options = AuthorizedDeleteHeaders(action.details, token);
  } else {
    requestUrl = `${apiUrl}/expertProfile/${action.endPoint}`;
    options = AuthorizedPutHeaders(action.details, token);
  }

  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(updateGeneralDetailsError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(updateGeneralDetailsSuccess());
      toastr.success(
        'Success!',
        `Successfully ${
          action.formType && action.formType === 'delete'
            ? 'Deleted'
            : 'Updated'
        }`
      );
      yield put(fetchExpertProfile());
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      toastr.error('Error!', e.message);
      yield put(updateGeneralDetailsError(e.message));
    }
  }
}

function* updateExpertGeneralSaga() {
  yield takeLatest(UPDATE_EXPERT_GENERAL_DETAILS, updateExpertGeneral);
}

export function* createAcheivementDetails(action) {
  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/expertProfile/createAchievement`;
  const options = AuthorizedPostHeaders(action.details, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(createAcheivementError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(createAcheivementSuccess());
      toastr.success('Success!', 'Achievement added successfully');
      yield put(fetchExpertProfile());
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(createAcheivementError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* createAcheivementSaga() {
  yield takeLatest(CREATE_EXPERT_ACHEIVEMENT, createAcheivementDetails);
}

export function* createPublicationDetails(action) {
  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/expertProfile/createPublication`;
  const options = AuthorizedPostMediaHeaders(action.details, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(createPublicationError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(createPublicationSuccess());
      toastr.success('Success!', 'Successfully added.');
      yield put(fetchExpertProfile());
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(createPublicationError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* createPublicationSaga() {
  yield takeLatest(CREATE_EXPERT_PUBLICATION, createPublicationDetails);
}

export function* updatePublicationDetails(action) {
  const token = yield select(tokenSelector);

  let requestUrl = '';

  let options = '';
  if (action.formType === 'edit') {
    requestUrl = `${apiUrl}/expertProfile/updatePublication`;
    options = AuthorizedPutDataHeaders(action.details, token);
  } else {
    requestUrl = `${apiUrl}/expertProfile/updatePublication?id=${action.details.id}`;
    options = AuthorizedDeleteHeaders(action.details, token);
  }
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(updatePublicationError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(updateGeneralDetailsSuccess());
      toastr.success(
        'Success!',
        `Successfully ${
          action.formType && action.formType === 'delete'
            ? 'Deleted'
            : 'Updated'
        }`
      );
      yield put(fetchExpertProfile());
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(updatePublicationError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* updatePublicationSaga() {
  yield takeLatest(UPDATE_EXPERT_PUBLICATION, updatePublicationDetails);
}

export function* createTimingsDetails(action) {
  const token = yield select(tokenSelector);
  const timings = { timings: action.details };
  const requestUrl = `${apiUrl}/expertProfile/createTimeAvailabilities`;
  const options = AuthorizedPostHeaders(timings, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(createTimingsError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(createTimingsSuccess());
      toastr.success('Success!', 'Successfully added.');
      yield put(fetchExpertProfile());
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(createTimingsError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* createTimingsSaga() {
  yield takeLatest(CREATE_TIMINGS, createTimingsDetails);
}

export function* fetchExpertProfileDetails(action) {
  const token = yield select(tokenSelector);
  let endPoint = '';
  if (action.id) {
    endPoint = `/getExpertById?expertProfileId=${action.id}`;
  }
  const requestUrl = `${apiUrl}/expertProfile${endPoint}`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchExpertProfileError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(fetchExpertProfileSuccess(response.data));
    }
  } catch (e) {
    yield put(fetchExpertProfileError(e.message));
  }
}

function* fetchProfileSaga() {
  yield takeLatest(FETCH_EXPERT_PROFILE, fetchExpertProfileDetails);
}

export function* uploadExpertProfileMedia(action) {
  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/expertProfile/${action.endPoint}`;
  const options = AuthorizedPutMediaHeaders(
    action.files,
    token,
    action.fieldName
  );
  try {
    const response = yield call(apiCall, requestUrl, options);
    if (response.status === 'error') {
      yield put(uploadExpertProfileMediaError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(uploadExpertProfileMediaSuccess());
      toastr.success('Success!', 'Successfully updated.');
      yield put(fetchExpertProfile());
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(uploadExpertProfileMediaError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* uploadExpertProfileMediaSaga() {
  yield takeLatest(UPLOAD_EXPERT_PROFILE_MEDIA, uploadExpertProfileMedia);
}

export function* updateExpertIndustryDetails(action) {
  const { industry, selectedSkills, selectedServices } = action.details;

  let skillIds = [];

  selectedSkills.map(skill => skillIds.push(skill.id));

  let serviceIds = [];

  selectedServices.map(service => serviceIds.push(service.id));

  const formData = {
    industryId: parseInt(industry),
    skillIds,
    serviceIds
  };

  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/expertProfile/updateIndustry`;
  const options = AuthorizedPutHeaders(formData, token);
  try {
    const response = yield call(apiCall, requestUrl, options);
    if (response.status === 'error') {
      yield put(updateExpertIndustryError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(updateExpertIndustrySuccess());
      toastr.success('Success!', 'Successfully updated.');
      yield put(fetchExpertProfile());
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(updateExpertIndustryError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* updateExpertIndustrySaga() {
  yield takeLatest(UPDATE_EXPERT_INDUSTRY, updateExpertIndustryDetails);
}

export default function* rootSaga() {
  yield all([
    fetchProfileSaga(),
    uploadExpertProfileMediaSaga(),
    updateExpertGeneralSaga(),
    createAcheivementSaga(),
    createPublicationSaga(),
    updateExpertIndustrySaga(),
    createTimingsSaga(),
    updatePublicationSaga()
  ]);
}
