import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import TopHeader from '../../Components/TopHeader';
import BorderedModal from '../../Components/BorderedModal';
import { placeholder } from '../../Assets';
import {
  fetchSingleUser,
  updateUserStatus,
  updateCompensation
} from '../../actions';
import apiUrl from '../../Configs/ApiUrl';
import history from '../../Configs/history';
import autoCap from '../../helpers/autoCap';
import Spinner from '../../Components/Spinner';

export default function Expert() {
  const params = useParams();
  const dispatch = useDispatch();
  const [showLinkedin, setShowLinkedin] = useState(false);
  const [showCalendly, setShowCalendly] = useState(false);
  const [compensation, setCompensation] = useState(null);
  const userState = useSelector(state => state.user);
  useEffect(() => {
    if (params.role === 'Client') {
      history.push(`/profile/${params.id}/${params.role}`);
    }
    dispatch(fetchSingleUser(params.id));
  }, [dispatch, params.role, params.id]);
  useEffect(() => {
    if (userState.mode === 'single_user' && userState.state === 'success') {
      setCompensation(userState.singleUser.compensation);
    }
  }, [userState.state]);
  const dayGetter = date => {
    var singleDay = moment(date, 'YYYY-MM-DD');
    var dayName = singleDay.format('dddd');
    return dayName;
  };

  const handleLinkedinClose = () => {
    setShowLinkedin(false);
  };
  const handleLinkedinShow = () => {
    setShowLinkedin(true);
  };

  const handleCalendlyClose = () => {
    setShowCalendly(false);
  };
  const handleCalendlyShow = () => {
    setShowCalendly(true);
  };

  const onSubmitUserStatusHandler = (status, id) => {
    dispatch(updateUserStatus(status, id));
  };

  const onSubmitCompensation = id => {
    dispatch(updateCompensation(compensation !== '' ? compensation : null, id));
  };
  const renderLinkedin = expert => {
    return (
      <>
        <div className='m-body' style={{ minHeight: 200 }}>
          <>
            <div
              style={{
                minHeight: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F1F1F1',
                fontSize: 13,
                borderRadius: 10
              }}
            >
              {expert.linkedInProfileUrl}
            </div>
          </>
        </div>
      </>
    );
  };

  const renderCalendly = expert => {
    return (
      <>
        <div className='m-body' style={{ minHeight: 200 }}>
          <>
            <div
              style={{
                minHeight: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F1F1F1',
                fontSize: 13,
                borderRadius: 10
              }}
            >
              {expert.meetingUrl}
            </div>
          </>
        </div>
      </>
    );
  };
  return (
    <React.Fragment>
      {showLinkedin && (
        <BorderedModal
          title='Linkedin'
          show={showLinkedin}
          handleClose={handleLinkedinClose}
          handleShow={handleLinkedinShow}
        >
          {renderLinkedin(userState.singleUser)}
        </BorderedModal>
      )}
      {showCalendly && (
        <BorderedModal
          title='Meeting Url'
          show={showCalendly}
          handleClose={handleCalendlyClose}
          handleShow={handleCalendlyShow}
        >
          {renderCalendly(userState.singleUser)}
        </BorderedModal>
      )}
      <TopHeader role='Admin' showArrow={true} />
      {userState.mode === 'single_user' && userState.state === 'loading' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 100
          }}
        >
          <Spinner color='#000' cWdith={50} cHeight={60} />
        </div>
      )}

      {userState.mode === 'update_user_status' &&
        userState.state === 'loading' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 100
            }}
          >
            <Spinner color='#000' cWdith={50} cHeight={60} />
          </div>
        )}

      {userState.mode === 'single_user' && userState.state === 'success' && (
        <div style={{ background: '#e3e1e1' }}>
          <div className='profileSection'>
            <div className='profileExpert'>
              <div className='expertBanner'></div>
              <img
                src={
                  userState.singleUser.profileImageUrl
                    ? apiUrl + '/' + userState.singleUser.profileImageUrl
                    : placeholder
                }
                className='profileExpertImg roundImg'
                style={{ marginLeft: 40 }}
                alt='User Logo'
              />

              <div className='profileExpertBody'>
                <div className='expertSection'>
                  <h5>
                    {userState.singleUser &&
                      userState.singleUser.User.firstName &&
                      autoCap(userState.singleUser.User.firstName) +
                        ' ' +
                        autoCap(userState.singleUser.User.lastName)}
                  </h5>
                  <p>{userState.singleUser && userState.singleUser.headline}</p>
                </div>
                <div className='expertSection'>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <h5>Current Employement</h5>
                    </div>
                    <div className='empRight'>
                      {userState.singleUser &&
                        userState.singleUser.cvUrl !== null && (
                          <div>
                            <a
                              className='btn btnCv'
                              href={`${
                                userState.singleUser.cvUrl !== null
                                  ? apiUrl + `/` + userState.singleUser.cvUrl
                                  : '#!'
                              }`}
                              target='_blank'
                            >
                              <i
                                className='fas fa-arrow-circle-down'
                                style={{
                                  color: '#3aa7e4',
                                  marginRight: 5,
                                  marginLeft: '-8px',
                                  fontSize: 30
                                }}
                              ></i>
                              Download CV
                            </a>
                          </div>
                        )}
                      {userState.singleUser &&
                        userState.singleUser.linkedInProfileUrl && (
                          <a
                            className='employe-icon'
                            href={userState.singleUser.linkedInProfileUrl}
                            target='_blank'
                          >
                            <i
                              className='fab fa-linkedin-in'
                              style={{
                                color: '#fff',
                                fontSize: 25
                              }}
                            ></i>
                          </a>
                        )}
                    </div>
                  </div>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <div>
                        <img
                          src={
                            userState.singleUser.organizationImageUrl
                              ? apiUrl +
                                '/' +
                                userState.singleUser.organizationImageUrl
                              : placeholder
                          }
                          className='expertImg'
                          alt='User Logo'
                        />
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <p className='p-exp'>
                          {userState.singleUser &&
                          userState.singleUser.organization !== null
                            ? userState.singleUser.organization
                            : 'Organization: N/A'}
                        </p>
                        <p className='p-det'>
                          {userState.singleUser &&
                          userState.singleUser.year !== null
                            ? userState.singleUser.year
                            : 'Year: N/A'}
                        </p>
                        <p className='p-det'>
                          {userState.singleUser &&
                          userState.singleUser.role !== null
                            ? userState.singleUser.role
                            : 'Role: N/A'}
                        </p>
                        <p className='p-det'>
                          {userState.singleUser &&
                          userState.singleUser.description !== null
                            ? userState.singleUser.description
                            : 'Description: N/A'}
                        </p>
                      </div>
                    </div>

                    {/* right */}
                  </div>
                </div>

                <div className='expertSection'>
                  <h5>Industry</h5>
                  <div className='exployeSkills'>
                    {userState.singleUser.ExpertIndustry &&
                    Object.keys(userState.singleUser.ExpertIndustry).length >
                      0 ? (
                      <div className='skill'>
                        <span className='text-center'>
                          {userState.singleUser.ExpertIndustry.Industry.name}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <h6>N/A</h6>
                      </div>
                    )}
                  </div>
                </div>

                <div className='expertSection'>
                  <h5>Skills</h5>
                  <div className='exployeSkills'>
                    {userState.singleUser.ExpertSkill &&
                    userState.singleUser.ExpertSkill.length > 0 ? (
                      userState.singleUser.ExpertSkill.map(skill => (
                        <div className='skill' key={`skill-${skill.Skill.id}`}>
                          <span className='text-center'>
                            {skill.Skill.title}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h6>N/A</h6>
                      </div>
                    )}
                  </div>
                </div>

                <div className='expertSection'>
                  <h5>Services</h5>
                  <div className='exployeSkills'>
                    {userState.singleUser.ExpertService &&
                    userState.singleUser.ExpertService.length > 0 ? (
                      userState.singleUser.ExpertService.map(service => (
                        <div
                          className='skill'
                          key={`service-${service.Service.id}`}
                        >
                          <span className='text-center'>
                            {service.Service.title}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h6>N/A</h6>
                      </div>
                    )}
                  </div>
                </div>

                {/*  */}
                <div className='expertSection'>
                  <h5>Publication</h5>
                  {userState.singleUser.Publication &&
                  userState.singleUser.Publication.length > 0 ? (
                    userState.singleUser.Publication.map(userPublication => (
                      <div
                        key={'d-' + userPublication.id}
                        className='exployeDetails'
                        style={{ marginTop: 10 }}
                      >
                        <div className='empLeft'>
                          <div>
                            <img
                              src={placeholder}
                              className='expertImg'
                              alt='User Logo'
                            />
                          </div>
                          <div style={{ marginLeft: 10 }}>
                            <p className='p-exp'>{userPublication.title}</p>
                            <p className='p-det'>
                              {userPublication.description}
                            </p>
                            <a
                              href={`${apiUrl}/${userPublication.fileUrl}`}
                              target='_blank'
                              className='p-det'
                              style={{ color: '#3aa7e4', fontWeight: 'bold' }}
                            >
                              View File
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <h6>N/A</h6>
                    </div>
                  )}
                </div>

                {/* end */}

                {/* start */}
                <div className='expertSection'>
                  <h5>Achievement(s)</h5>
                  {userState.singleUser.Achievement &&
                  userState.singleUser.Achievement.length > 0 ? (
                    userState.singleUser.Achievement.map(userAcheivement => (
                      <div
                        className='exployeDetails'
                        key={'da' + userAcheivement.id}
                        style={{ marginTop: 10 }}
                      >
                        <div className='empLeft'>
                          <div>
                            <img
                              src={placeholder}
                              className='expertImg'
                              alt='User Logo'
                            />
                          </div>
                          <div style={{ marginLeft: 10 }}>
                            <p className='p-exp'>{userAcheivement.title} </p>
                            <p className='p-det'>
                              {userAcheivement.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <h6>N/A</h6>
                    </div>
                  )}
                </div>
                {/* end */}

                {/* start */}
                <div className='expertSection'>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <h5>Time Availability</h5>
                    </div>

                    <div className='empRight'>
                      {userState.singleUser && userState.singleUser.meetingUrl && (
                        <a
                          className='edit-icon'
                          href={userState.singleUser.meetingUrl}
                          target='_blank'
                        >
                          <i
                            className='far fa-calendar'
                            style={{
                              color: '#fff',
                              fontSize: 25
                            }}
                          ></i>
                        </a>
                      )}
                    </div>
                  </div>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div>
                      {userState.singleUser.TimeAvailability &&
                      userState.singleUser.TimeAvailability.length > 0 ? (
                        userState.singleUser.TimeAvailability.map(time => (
                          <p className='t-emp'>
                            <span>
                              {' '}
                              {dayGetter(time.date) + ', '}
                              <Moment format='DD MMMM, YYYY'>
                                {time.date}
                              </Moment>
                            </span>{' '}
                            <small>
                              {moment(time.startTime, 'hh:mm').format(
                                'hh:mm A'
                              )}{' '}
                              -{' '}
                              {moment(time.endTime, 'hh:mm').format('hh:mm A')}{' '}
                              - {time.TimeZone.title}
                            </small>{' '}
                            {time.isRecursive ? ' - Recurring' : ''}
                          </p>
                        ))
                      ) : (
                        <div>
                          <h6>N/A</h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* end */}

                {/* start */}
                <div className='expertSection'>
                  <h5>General Information</h5>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      {userState.singleUser.phone ? (
                        <div>
                          <p className='p-exp'>Phone </p>
                          <p className='p-det'>{userState.singleUser.phone}</p>

                          <p className='p-exp'>Email </p>
                          <p className='p-det'>{userState.singleUser.email}</p>

                          <p className='p-exp'>Mobile </p>
                          <p className='p-det'>{userState.singleUser.mobile}</p>
                        </div>
                      ) : (
                        <div>
                          <p className='p-exp'>Phone </p>
                          <p className='p-det'>N/A</p>

                          <p className='p-exp'>Email </p>
                          <p className='p-det'>N/A</p>

                          <p className='p-exp'>Mobile </p>
                          <p className='p-det'>N/A</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* end */}

                {/* start */}
                <div className='expertSection'>
                  <h5>Admin Notes</h5>
                  <div className='adminNotes' style={{ marginTop: 10 }}>
                    <h6>Expert Compensation</h6>

                    <textarea
                      onChange={e => setCompensation(e.target.value)}
                      value={compensation}
                      className='form-control'
                      rows='6'
                      style={{ background: '#F1F1F1' }}
                    >
                      {compensation}
                    </textarea>

                    <button
                      className='btn custom-adm-btn '
                      onClick={() =>
                        onSubmitCompensation(userState.singleUser.id)
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
                {/* end */}
              </div>
            </div>
          </div>
          <div className='actionBodyExpert'>
            <div className='row cRow'>
              <div className='col-md-2'></div>
              <div className='col-md-4'>
                <button
                  onClick={() =>
                    onSubmitUserStatusHandler(
                      false,
                      userState.singleUser.userId
                    )
                  }
                  className='btn btn-block '
                >
                  Block
                </button>
              </div>

              <div className='col-md-4'>
                <button
                  onClick={() =>
                    onSubmitUserStatusHandler(true, userState.singleUser.userId)
                  }
                  className='btn btn-block custom-btn'
                >
                  Active
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
