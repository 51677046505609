import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TopHeader from '../../Components/TopHeader';
import { placeholder } from '../../Assets';
import { fetchSingleUser } from '../../actions';
import apiUrl from '../../Configs/ApiUrl';
import history from '../../Configs/history';
import Spinner from '../../Components/Spinner';

import { updateUserStatus } from '../../actions';

export default function Profile() {
  const params = useParams();
  const dispatch = useDispatch();
  const userState = useSelector(state => state.user);

  useEffect(() => {
    if (params.role === 'Expert') {
      history.push(`/expert/${params.id}/${params.role}`);
    }
    dispatch(fetchSingleUser(params.id));
  }, [dispatch]);

  const onSubmitUserStatusHandler = (status, id) => {
    dispatch(updateUserStatus(status, id));
  };
  return (
    <React.Fragment>
      <TopHeader role='Admin' showArrow={true} />
      <div>
        {userState.mode === 'single_user' && userState.state === 'loading' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 100
            }}
          >
            <Spinner color='#000' cWdith={50} cHeight={60} />
          </div>
        )}
        {userState.mode === 'single_user' && userState.state === 'success' && (
          <>
            <div className='profileSection'>
              <div className='profile'>
                <div className='profileImg'>
                  <img
                    className='expertImg roundImg'
                    src={
                      userState.singleUser.profileImageUrl
                        ? apiUrl + '/' + userState.singleUser.profileImageUrl
                        : placeholder
                    }
                    alt='User'
                  />
                </div>
                <div className='profileBody'>
                  <h5>
                    {userState.singleUser.organization
                      ? userState.singleUser.organization
                      : 'Organization'}
                  </h5>
                  <hr />
                  <p>
                    {userState.singleUser.description
                      ? userState.singleUser.description
                      : 'Description'}
                  </p>
                  <hr />
                  <p>
                    {userState.singleUser.contactPerson
                      ? userState.singleUser.contactPerson
                      : 'Contact Person'}
                  </p>
                  <hr />
                  <p>
                    {userState.singleUser.contactEmail
                      ? userState.singleUser.contactEmail
                      : 'Contact Email'}
                  </p>
                  <hr />
                  <p>
                    {userState.singleUser.contactPhone
                      ? userState.singleUser.contactPhone
                      : 'Contact Phone'}
                  </p>
                  <hr />
                </div>
              </div>
            </div>
            <div className='actionBody'>
              <div className='row cRow'>
                <div className='col-md-6'>
                  <button
                    className='btn btn-block'
                    onClick={() =>
                      onSubmitUserStatusHandler(
                        false,
                        userState.singleUser.userId
                      )
                    }
                  >
                    Block
                  </button>
                </div>

                <div className='col-md-6'>
                  <button
                    onClick={() =>
                      onSubmitUserStatusHandler(
                        true,
                        userState.singleUser.userId
                      )
                    }
                    className='btn btn-block custom-btn'
                  >
                    Active
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
