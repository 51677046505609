import React from 'react';
import FrontHeader from '../../Components/FrontHeader';
import Banner from '../../Components/Banner';
import PopuplarExperts from '../../Components/PopularExperts';
import Footer from '../../Components/Footer';
import {  banner } from '../../Assets';
import LoginModal from '../../Components/LoginModal';
import Testimonials from '../../Components/Testimonials';
import history from '../../Configs/history';

export default function Home() {
  return (
    <div>
      <FrontHeader />
      <Banner history={history} />
      <PopuplarExperts />
      
      <Testimonials />
      {/* start */}
      <div className='main-banner-section'>
        <div className='banner-details'>
          <div className='' style={{ marginLeft: 30 }}>
            <h2 className='int-h3' style={{ textAlign: 'left' }}>
              Patents, <br /> Intellectual <br />{' '}
              <span style={{ color: '#3aa7e4' }}>Property</span>!
            </h2>
            <p style={{ textAlign: 'left', paddingRight: 0 }}>
              We do have patent application filed which secure our business
              model & method.
            </p>
          </div>
          <div className='banner-img'>
            <img alt='Banner' src={banner} />
          </div>
        </div>
      </div>
      {/* End */}
      <LoginModal />
      <Footer />
    </div>
  );
}
