import {
  FETCH_EXPERT_PROFILE,
  FETCH_EXPERT_PROFILE_SUCCESS,
  FETCH_EXPERT_PROFILE_ERROR,
  UPDATE_EXPERT_PROFILE,
  UPDATE_EXPERT_PROFILE_SUCCESS,
  UPDATE_EXPERT_PROFILE_ERROR,
  UPDATE_EXPERT_GENERAL_DETAILS,
  UPDATE_EXPERT_GENERAL_DETAILS_SUCCESS,
  UPDATE_EXPERT_GENERAL_DETAILS_ERROR,
  UPLOAD_EXPERT_PROFILE_MEDIA,
  UPLOAD_EXPERT_PROFILE_MEDIA_SUCCESS,
  UPLOAD_EXPERT_PROFILE_MEDIA_ERROR,
  CREATE_EXPERT_ACHEIVEMENT,
  CREATE_EXPERT_ACHEIVEMENT_SUCCESS,
  CREATE_EXPERT_ACHEIVEMENT_ERROR,
  CREATE_EXPERT_PUBLICATION,
  CREATE_EXPERT_PUBLICATION_SUCCESS,
  CREATE_EXPERT_PUBLICATION_ERROR,
  UPDATE_EXPERT_PUBLICATION,
  UPDATE_EXPERT_PUBLICATION_SUCCESS,
  UPDATE_EXPERT_PUBLICATION_ERROR,
  UPDATE_EXPERT_INDUSTRY,
  UPDATE_EXPERT_INDUSTRY_SUCCESS,
  UPDATE_EXPERT_INDUSTRY_ERROR,
  CREATE_TIMINGS,
  CREATE_TIMINGS_SUCCESS,
  CREATE_TIMINGS_ERROR,
  CLEAR_STATUS
} from '../constants';

const initialAuthState = {
  state: 'initial',
  mode: 'initial',
  userProfile: {},
  status: ''
};

export function expertProfileReducer(state = initialAuthState, action) {
  switch (action.type) {
    case FETCH_EXPERT_PROFILE:
      return { ...state, mode: 'fetch_expert', state: 'loading', status: '' };

    case FETCH_EXPERT_PROFILE_SUCCESS:
      return {
        ...state,
        state: 'success',
        userProfile: action.details,
        status: ''
      };

    case FETCH_EXPERT_PROFILE_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPDATE_EXPERT_PROFILE:
      return { ...state, mode: 'update_expert', state: 'loading', status: '' };

    case UPDATE_EXPERT_PROFILE_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPDATE_EXPERT_PROFILE_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPDATE_EXPERT_GENERAL_DETAILS:
      return {
        ...state,
        mode: action.mode,
        state: 'loading',
        status: ''
      };

    case UPDATE_EXPERT_GENERAL_DETAILS_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPDATE_EXPERT_GENERAL_DETAILS_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPLOAD_EXPERT_PROFILE_MEDIA:
      return { ...state, mode: action.mode, state: 'loading', status: '' };

    case UPLOAD_EXPERT_PROFILE_MEDIA_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPLOAD_EXPERT_PROFILE_MEDIA_ERROR:
      return { ...state, state: 'error', status: action.status };

    case CREATE_EXPERT_ACHEIVEMENT:
      return {
        ...state,
        mode: 'create_acheivement',
        state: 'loading',
        status: ''
      };

    case CREATE_EXPERT_ACHEIVEMENT_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case CREATE_EXPERT_ACHEIVEMENT_ERROR:
      return { ...state, state: 'error', status: action.status };

    case CREATE_EXPERT_PUBLICATION:
      return {
        ...state,
        mode: 'create_publication',
        state: 'loading',
        status: ''
      };

    case CREATE_EXPERT_PUBLICATION_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case CREATE_EXPERT_PUBLICATION_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPDATE_EXPERT_PUBLICATION:
      return {
        ...state,
        mode: 'update_publication',
        state: 'loading',
        status: ''
      };

    case UPDATE_EXPERT_PUBLICATION_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPDATE_EXPERT_PUBLICATION_ERROR:
      return { ...state, state: 'error', status: action.status };

    case CREATE_TIMINGS:
      return {
        ...state,
        mode: 'create_timings',
        state: 'loading',
        status: ''
      };

    case CREATE_TIMINGS_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case CREATE_TIMINGS_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPDATE_EXPERT_INDUSTRY:
      return {
        ...state,
        mode: 'update_industry',
        state: 'loading',
        status: ''
      };

    case UPDATE_EXPERT_INDUSTRY_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPDATE_EXPERT_INDUSTRY_ERROR:
      return { ...state, state: 'error', status: action.status };

    case CLEAR_STATUS: {
      return { ...state, mode: 'initial' };
    }

    default:
      return state;
  }
}
