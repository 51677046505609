import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import hydrateReducer from './HydrateReducer';
import { authReducer } from './Auth';
import { clientProfileReducer } from './ClientProfile';
import { expertProfileReducer } from './ExpertProfile';
import { commonReducer } from './Common';
import { dashboardReducer } from './Dashboard';
import { requestReducer } from './Request';
import { adminRequestReducer } from './AdminRequest';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { userReducer } from './User';

export default function createReducer(history) {
  const reducers = {
    router: connectRouter(history),
    hydrate: hydrateReducer,
    auth: authReducer,
    client: clientProfileReducer,
    expert: expertProfileReducer,
    user: userReducer,
    common: commonReducer,
    dashboard: dashboardReducer,
    requests: requestReducer,
    adminRequests: adminRequestReducer,
    toastr: toastrReducer
  };
  const reducer = combineReducers(reducers);
  return reducer;
}
