import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopHeader from '../../Components/TopHeader';
import BorderedModal from '../../Components/BorderedModal';
import DropZone from '../../Pages/DropZone';
import Spinner from '../../Components/Spinner';
import { placeholder } from '../../Assets';
import apiUrl from '../../Configs/ApiUrl';
import { updateClientProfile, fetchClientProfile } from '../../actions';
import { useForm } from 'react-hook-form';

export default function ProfileClient() {
  const dispatch = useDispatch();
  const [showFile, setShowFile] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [fileDetails, setFileDetails] = useState(null);
  const clientStateSelector = useSelector(state => state.client);
  const token = useSelector(state => state.auth.token);
  const { register, handleSubmit } = useForm();
  useEffect(() => {
    if (token) {
      dispatch(fetchClientProfile(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    setTimeout(() => setFileDetails(previewFile), 1000);
  }, [previewFile]);

  const onSubmit = data => {
    const formData = new FormData();
    formData.append('organization', data.organization);
    formData.append('contactEmail', data.contactEmail);
    formData.append('contactPerson', data.contactPerson);
    formData.append('contactPhone', data.contactPhone);
    formData.append('description', data.description);
    if (previewFile) {
      formData.append('imageProfile', previewFile);
    }
    dispatch(updateClientProfile(formData));
  };
  const { userProfile } = clientStateSelector;

  const handleFileClose = () => {
    setShowFile(false);
  };
  const handleFileShow = () => {
    setShowFile(true);
  };

  const onUploadMedia = fileNames => {
    setPreviewFile(fileNames);
    setShowFile(false);
  };

  return (
    <React.Fragment>
      <BorderedModal
        title='Upload Media'
        show={showFile}
        handleClose={handleFileClose}
        handleShow={handleFileShow}
      >
        <DropZone
          state={clientStateSelector.state}
          mode={clientStateSelector.mode}
          styles={{ padding: 30 }}
          onUploadMedia={onUploadMedia}
          type='autoSubmit'
          dropType='image/*'
        />
      </BorderedModal>
      <TopHeader role='Client' />
      <div>
        <div className='profileSection'>
          <div className='profile'>
            <div className='mod-img'>
              <div className='img-download'>
                {previewFile ? (
                  <img
                    className='expertImg roundImg'
                    src={
                      fileDetails && fileDetails.preview
                        ? fileDetails.preview
                        : placeholder
                    }
                    alt={previewFile.name}
                  />
                ) : (
                  <img
                    src={
                      userProfile.profileImageUrl
                        ? apiUrl + '/' + userProfile.profileImageUrl
                        : placeholder
                    }
                    className='expertImg roundImg'
                    alt='User'
                  />
                )}

                <div
                  className='edit-icon'
                  style={{ bottom: 5 }}
                  onClick={() => handleFileShow()}
                >
                  <i title='Edit Image' className='fas fa-camera'></i>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='profileBody'>
                <div
                  className='input-row'
                  style={{ justifyContent: 'space-between' }}
                >
                  <input
                    style={{ fontWeight: 'bold', fontSize: 20 }}
                    name='organization'
                    type='text'
                    defaultValue={userProfile.organization}
                    ref={register({ required: true })}
                    className='form-control'
                    placeholder='Organization'
                  />
                  <i title='Edit' className='fas fa-pencil-alt'></i>
                </div>

                <hr />
                <div className='input-row'>
                  <textarea
                    placeholder='Only 300 characters allowed'
                    maxLength='300'
                    placeholder='Description'
                    className='form-control'
                    name='description'
                    defaultValue={userProfile.description}
                    ref={register}
                  ></textarea>
                  <i title='Edit' className='fas fa-pencil-alt'></i>
                </div>
                <hr />
                <div className='input-row'>
                  <input
                    name='contactPerson'
                    type='text'
                    defaultValue={userProfile.contactPerson}
                    ref={register}
                    className='form-control'
                    placeholder='Contact Person'
                  />
                  <i title='Edit' className='fas fa-pencil-alt'></i>
                </div>
                <hr />
                <div className='input-row'>
                  <input
                    className='form-control'
                    placeholder='Contact Email'
                    name='contactEmail'
                    type='email'
                    defaultValue={userProfile.contactEmail}
                    ref={register}
                  />
                  <i title='Edit' className='fas fa-pencil-alt'></i>
                </div>
                <hr />
                <div className='input-row'>
                  <input
                    className='form-control'
                    placeholder='Contact Phone'
                    name='contactPhone'
                    type='text'
                    defaultValue={userProfile.contactPhone}
                    ref={register}
                  />
                  <i title='Edit' className='fas fa-pencil-alt'></i>
                </div>
                <hr />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    disabled={
                      clientStateSelector.state === 'loading' ? true : false
                    }
                    className='btn custom-btn'
                    style={{ width: '200px' }}
                    type='submit'
                  >
                    {clientStateSelector.state === 'loading' ? (
                      <Spinner />
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
