export const filterSkills = (query, skills) => {
  if (query !== '') {
    var results = skills.filter(skill => {
      return skill.title.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    });

    return results;
  } else {
    return [];
  }
};

export const filterServices = (query, services) => {
  if (query !== '') {
    var results = services.filter(service => {
      return service.title.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    });

    return results;
  } else {
    return [];
  }
};

export const filterTimeZone = (timezoneId, globalTimeZones) => {
  if (timezoneId) {
    var results = globalTimeZones.filter(timezone => {
      return timezone.id === timezoneId;
    });
    if (results.length > 0) {
      return results[0].title;
    }
  } else {
    return [];
  }
};

export const filterSkillFront = skills => {
  var results = skills.filter(skill => {
    return skill.userId === 1;
  });

  return results.filter(result => {
    return result.title !== 'Others';
  });
};

export const filterServiceFront = services => {
  var results = services.filter(service => {
    return service.userId === 1;
  });

  return results.filter(result => {
    return result.title !== 'Others';
  });
};
