import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { placeholder } from '../Assets';
import history from '../Configs/history';
import apiUrl from '../Configs/ApiUrl';
import Spinner from '../Components/Spinner';
import DropZoneRequest from '../Pages/DropZoneRequest';

const useStyles = makeStyles({
  root: {
    background: '#EEEEEE',
    height: '100%'
  },
  list: {
    width: 950,
    '@media (max-width: 780px)': {
      width: 300
    }
  },
  profile: {
    background: 'blue'
  }
});

export default function DrawerComponent({
  toggleDrawer,
  state,
  status,
  singleUser,
  notes,
  setAdminNotes,
  onSubmitAdminNotesHanlder,
  feedback,
  setAdminFeedback,
  onSubmitAdminFeedbackHanlder,
  requestSelector,
  onSubmitRequestStatus,
  notesActive,
  setNotesActive,
  feedbackActive,
  setFeedbackActive,
  onUploadExpertMedia,
  pathReload
}) {
  const classes = useStyles();

  const list = () => {
    if (singleUser && Object.keys(singleUser).length > 0) {
      let clientFileName = '';
      let fileDetails = '';
      if (singleUser.clientFileUrl !== null) {
        clientFileName = singleUser.clientFileUrl.split('_');
        fileDetails = clientFileName[2];
      }

      let expertFileName = '';
      let feedbackFileDetails = '';
      if (singleUser.expertFileUrl !== null) {
        expertFileName = singleUser.expertFileUrl.split('_');
        feedbackFileDetails = expertFileName[2];
      }

      return (
        <div className={clsx(classes.list, classes.root)} role='presentation'>
          {/* <div className='close-drawer'>
        <i className='fa fa-times'></i>
      </div> */}

          <div className='drawer-content'>
            <h5>Request Details</h5>
            <div className='row' style={{ marginTop: 30, marginRight: 15 }}>
              <div className='col-md-4'>
                <p className='p-drawer'>Client</p>
              </div>

              <div className='col-md-8'>
                <div className='profile-details'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <img
                        alt='User'
                        className='drawer-img'
                        src={placeholder}
                      />
                    </div>

                    <div className='col-md-8'>
                      <h6>
                        {singleUser.ClientUser.firstName +
                          ' ' +
                          singleUser.ClientUser.lastName}
                      </h6>

                      <p className='p-desc'>{singleUser.ClientUser.mobile}</p>
                      <p className='p-desc'>
                        {singleUser.ClientUser.ClientProfile.organization}
                      </p>

                      <button
                        onClick={() =>
                          history.push({
                            pathname: `/profile/${singleUser.ClientUser.id}/Client`,
                            search: `?from=${pathReload}`
                          })
                        }
                        className='profile-btn'
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          color: '#0D1136',
                          marginLeft: '-7px'
                        }}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* start */}
            <div className='row' style={{ marginTop: 30, marginRight: 15 }}>
              <div className='col-md-4'>
                <p className='p-drawer'>Expert</p>
              </div>

              <div className='col-md-8'>
                <div className='profile-details'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <img
                        alt='User'
                        className='drawer-img'
                        src={placeholder}
                      />
                    </div>

                    <div className='col-md-8'>
                      <h6>
                        {singleUser.ExpertUser.firstName +
                          ' ' +
                          singleUser.ExpertUser.lastName}
                      </h6>
                      <p className='p-desc'>
                        {singleUser.ExpertUser.ExpertProfile.role +
                          ' at ' +
                          singleUser.ExpertUser.ExpertProfile.organization}
                      </p>
                      <p className='p-desc'>
                        <p className='p-desc'>{singleUser.ExpertUser.mobile}</p>
                      </p>
                      <p className='p-desc'>
                        {singleUser.ExpertUser.ExpertProfile.organization}
                      </p>

                      <button
                        onClick={() =>
                          history.push({
                            pathname: `/expert/${singleUser.ExpertUser.id}/Expert`,
                            search: `?from=${pathReload}`
                          })
                        }
                        className='profile-btn'
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          color: '#0D1136',
                          marginLeft: '-7px'
                        }}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End */}

            {/* start */}
            <div className='row' style={{ marginTop: 30, marginRight: 15 }}>
              <div className='col-md-4'>
                <p className='p-drawer'>Client Notes</p>
              </div>

              <div className='col-md-8'>
                <div className='profile-details'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <p className='p-desc' style={{ fontSize: 12 }}>
                        {singleUser.description}
                      </p>
                      {fileDetails && (
                        <div className='file-details'>
                          <small>
                            {fileDetails ? fileDetails : 'file.pdf'}
                          </small>
                          <a
                            target='_blank'
                            href={`${apiUrl}/${singleUser.clientFileUrl}`}
                            className='btn fileBtn'
                          >
                            <i
                              className='fas fa-arrow-circle-down'
                              style={{
                                color: '#fff',
                                marginRight: 5,
                                marginLeft: '-8px',
                                fontSize: 23
                              }}
                            ></i>
                            Download File
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End */}

            {/* start */}
            <div className='row' style={{ marginTop: 30, marginRight: 15 }}>
              <div className='col-md-4'>
                <p className='p-drawer'>Admin Notes</p>
              </div>

              <div className='col-md-8'>
                <div className='profile-details'>
                  <div
                    onClick={() => setNotesActive(!notesActive)}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginBottom: 10
                    }}
                  >
                    <i
                      title='Edit Admin Notes'
                      className='fas fa-pencil-alt'
                    ></i>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <textarea
                        placeholder='Only 300 characters allowed'
                        maxLength='300'
                        disabled={notesActive ? false : true}
                        onChange={ev => setAdminNotes(ev.target.value)}
                        required='required'
                        rows='5'
                        className='form-control'
                        style={{
                          background: notesActive ? '#ffffff' : '#F1F1F1',
                          color: 'black',
                          fontSize: 13
                        }}
                      >
                        {notes !== 'null' || notes !== null ? notes : ''}
                      </textarea>
                      <div className='adminNotes'>
                        <button
                          disabled={
                            requestSelector.state === 'loading' &&
                            requestSelector.mode === 'create_admin_notes'
                              ? true
                              : false
                          }
                          className='btn custom-adm-btn'
                          onClick={() =>
                            onSubmitAdminNotesHanlder(singleUser.id, status)
                          }
                        >
                          {requestSelector.state === 'loading' &&
                          requestSelector.mode === 'create_admin_notes' ? (
                            <Spinner cWdith={20} cHeight={20} />
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End */}

            {/* start */}
            <div
              className='row'
              style={{
                marginTop: 30,
                marginBottom: 70,
                marginRight: 15
              }}
            >
              <div className='col-md-4'>
                <p className='p-drawer'>Expert Feedback</p>
              </div>

              <div className='col-md-8'>
                <div className='profile-details'>
                  <div
                    onClick={() => setFeedbackActive(!feedbackActive)}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginBottom: 10
                    }}
                  >
                    <i
                      title='Edit Admin Notes'
                      className='fas fa-pencil-alt'
                    ></i>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <textarea
                        placeholder='Only 300 characters allowed'
                        maxLength='300'
                        disabled={feedbackActive ? false : true}
                        onChange={ev => setAdminFeedback(ev.target.value)}
                        required='required'
                        rows='5'
                        className='form-control'
                        style={{
                          background: feedbackActive ? '#ffffff' : '#F1F1F1',
                          color: 'black',
                          fontSize: 13
                        }}
                      >
                        {feedback === 'null' ? null : feedback}
                      </textarea>

                      <DropZoneRequest
                        state={requestSelector.state}
                        mode={requestSelector.mode}
                        onUploadMedia={onUploadExpertMedia}
                        requestSelector={requestSelector}
                      />
                      {singleUser && singleUser.expertFileUrl && (
                        <div className='file-details'>
                          <small>
                            {feedbackFileDetails
                              ? feedbackFileDetails
                              : 'file.pdf'}
                          </small>
                          <a
                            target='_blank'
                            href={`${apiUrl}/${singleUser.expertFileUrl}`}
                            className='btn fileBtn'
                          >
                            <i
                              className='fas fa-arrow-circle-down'
                              style={{
                                color: '#fff',
                                marginRight: 5,
                                marginLeft: '-8px',
                                fontSize: 23
                              }}
                            ></i>
                            Download File
                          </a>
                        </div>
                      )}

                      <div className='adminNotes'>
                        <button
                          disabled={
                            requestSelector.state === 'loading' &&
                            requestSelector.mode === 'create_admin_feedback'
                              ? true
                              : false
                          }
                          className='btn custom-adm-btn'
                          onClick={() =>
                            onSubmitAdminFeedbackHanlder(singleUser.id, status)
                          }
                        >
                          {requestSelector.state === 'loading' &&
                          requestSelector.mode === 'create_admin_feedback' ? (
                            <Spinner cWdith={20} cHeight={20} />
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End */}
          </div>

          <div className='drawer-footer'>
            {status === 'Requested' && (
              <div className='row cRow'>
                <div className='col-md-2'></div>
                <div className='col-md-4'>
                  <button
                    className='btn btn-block btn-danger'
                    style={{ height: 50 }}
                    onClick={() => onSubmitRequestStatus('Cancelled')}
                  >
                    Cancel
                  </button>
                </div>

                <div className='col-md-4'>
                  <button
                    className='btn btn-block custom-btn'
                    onClick={() => onSubmitRequestStatus('Completed')}
                  >
                    Project Completion
                  </button>
                </div>
              </div>
            )}

            {status === 'Completed' && (
              <small style={{ fontSize: 18, fontWeight: 'bold' }}>
                Project Completed
              </small>
            )}

            {status === 'Cancelled' && (
              <small style={{ fontSize: 18, fontWeight: 'bold' }}>
                Project Cancelled
              </small>
            )}
          </div>
        </div>
      );
    } else {
      <div>Loading Content</div>;
    }
  };

  return (
    <div>
      <React.Fragment key='right'>
        <Drawer
          anchor='right'
          open={state['right']}
          onClose={toggleDrawer('right', false, singleUser)}
        >
          {list('right')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
