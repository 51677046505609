import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_ERROR
} from '../constants';

export const fetchDashboard = () => ({
  type: FETCH_DASHBOARD
});

export const fetchDashboardSuccess = details => ({
  type: FETCH_DASHBOARD_SUCCESS,
  details
});

export const fetchDashboardError = status => ({
  type: FETCH_DASHBOARD_ERROR,
  status
});
