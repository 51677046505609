import '../Assets/styles/app.scss';
import MainNavigator from '../Navigation/MainNavigator';

function App() {
  return (
    <div className='App'>
      <MainNavigator />
    </div>
  );
}

export default App;
