export default function authorizedPutMediaHeaders(request, token, fieldName) {
  const formData = new FormData();
  formData.append(fieldName, request[0]);

  return {
    method: 'PUT',
    headers: {
      Accept: '*/*',
      'x-access-token': `${token}`
    },
    body: formData
  };
}
