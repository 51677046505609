import { call, put, takeLatest, select, all } from 'redux-saga/effects';

import { push } from 'connected-react-router';
import apiUrl from '../Configs/ApiUrl';
import apiCall from '../Services/ApiCall';
import history from '../Configs/history';
import {
  FETCH_USER,
  FETCH_SINGLE_USER,
  FILTER_USERS,
  UPDATE_USER_STATUS
} from '../constants';
import {
  fetchUserSuccess,
  fetchuserError,
  clearUser,
  fetchSingleUserSuccess,
  fetchSingleUserError,
  filterUsersSuccess,
  filterUsersError,
  updateUserStatusSucess,
  updateUserStatusError,
  adminLogoutUser
} from '../actions';
import AuthorizedPutHeaders from '../Configs/AuthorizedPutHeaders';
import AuthorizedGetHeaders from '../Configs/AuthorizedGetHeaders';
import { adminTokenSelector } from '../selectors/adminSelecter';

export function* fetchUserDetails() {
  const token = yield select(adminTokenSelector);

  const requestUrl = `${apiUrl}/admin/getUsers`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchuserError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(fetchUserSuccess(response.data));
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(fetchuserError(e.message));
    }
  }
}

function* fetchUserSaga() {
  yield takeLatest(FETCH_USER, fetchUserDetails);
}

export function* fetchSingleUserDetails(action) {
  const token = yield select(adminTokenSelector);
  const requestUrl = `${apiUrl}/admin/getUserById?userId=${action.id}`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchSingleUserError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(fetchSingleUserSuccess(response.data));
    }
  } catch (e) {
    yield put(fetchSingleUserError(e.message));
  }
}

function* fetchSingleUserSaga() {
  yield takeLatest(FETCH_SINGLE_USER, fetchSingleUserDetails);
}

export function* filterUserDetails(action) {
  const token = yield select(adminTokenSelector);
  let endPoint = '';
  if (action.query !== '') {
    endPoint += `&q=${action.query}`;
  }
  if (action.role !== '-1') {
    endPoint += `&role=${action.role}`;
  }
  if (action.status !== '-1') {
    endPoint += `&isActive=${action.status}`;
  }

  const requestUrl = `${apiUrl}/admin/getUsers?limit=50&offset=0${endPoint}`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(filterUsersError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(filterUsersSuccess(response.data));
    }
  } catch (e) {
    yield put(filterUsersError(e.message));
  }
}

function* filterUsersSaga() {
  yield takeLatest(FILTER_USERS, filterUserDetails);
}

export function* updateUserStatusDetails(action) {
  const token = yield select(adminTokenSelector);
  const data = {
    id: parseInt(action.id),
    isActive: action.details
  };
  const requestUrl = `${apiUrl}/admin/updateUserStatusById`;
  const options = AuthorizedPutHeaders(data, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(updateUserStatusError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(updateUserStatusSucess(response.data));
      history.push('/users');
    }
  } catch (e) {
    yield put(updateUserStatusError(e.message));
  }
}

function* updateUserStatusSaga() {
  yield takeLatest(UPDATE_USER_STATUS, updateUserStatusDetails);
}

export default function* rootSaga() {
  yield all([
    fetchUserSaga(),
    fetchSingleUserSaga(),
    filterUsersSaga(),
    updateUserStatusSaga()
  ]);
}
