import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import RequestModal from './RequestModal';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { logo, placeholder } from '../Assets';
import { logoutUser, fetchRequests } from '../actions';
import history from '../Configs/history';
import apiUrl from '../Configs/ApiUrl';
import autoCap from '../helpers/autoCap';
import { useSelector } from 'react-redux';
import Join from './Join';

const FrontHeader = () => {
  const dispatch = useDispatch();

  const [showRequests, setShowRequests] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authState = useSelector(state => state.auth);
  const requestState = useSelector(state => state.requests);
  const { token, user } = authState;
  const handleClickDropDown = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  const handleCloseRequest = () => {
    setShowRequests(false);
  };
  const handleShowRequest = () => {
    setShowRequests(true);
  };


  const logoutHandler = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (token) {
      if (user.role === 'Client') {
        dispatch(fetchRequests());
      }
    }
  }, [token, dispatch]);

  const renderRequest = () => {
    return (
      <>
        <div className='m-body'>
          {requestState.state === 'success' &&
            requestState.requests.length > 0 &&
            requestState.requests.map((request, i) => (
              <div key={`request-${request.id}`}>
                <div
                  className='row cRow'
                  style={{ marginTop: 30, padding: 10 }}
                >
                  <div className='col-md-3 col-sm-12 col-xs-12'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <img
                        alt='User'
                        className='request-img'
                        src={
                          request.ExpertUser.ExpertProfile &&
                          request.ExpertUser.ExpertProfile.profileImageUrl !==
                            null
                            ? apiUrl +
                              '/' +
                              request.ExpertUser.ExpertProfile.profileImageUrl
                            : placeholder
                        }
                      />
                    </div>
                  </div>

                  <div className='col-md-9 col-sm-12 col-xs-12'>
                    <h6>
                      {autoCap(request.ExpertUser.firstName) +
                        ' ' +
                        autoCap(request.ExpertUser.lastName)}
                    </h6>
                    {request.ExpertUser.ExpertProfile?.organization && (
                      <p className='p-desc'>
                        {request.ExpertUser.ExpertProfile.role} at{' '}
                        {request.ExpertUser.ExpertProfile.organization}
                      </p>
                    )}
                    <p className='p-desc'>
                      {request.ExpertUser.ExpertProfile.mobile}
                    </p>
                    <p className='p-desc'>{request.description}</p>

                    <button
                      className='profile-btn'
                      style={{
                        fontSize: 10,
                        fontWeight: 'bold',
                        color: '#0D1136',
                        marginLeft: '-7px'
                      }}
                    >
                      Request Sent{' '}
                      <i
                        className='fas fa-check'
                        style={{
                          background: 'black',
                          color: 'white',
                          borderRadius: 30,
                          padding: 2,
                          fontSize: 8,
                          marginTop: '-2px'
                        }}
                      ></i>{' '}
                    </button>
                  </div>
                </div>
                {requestState.requests.length - 1 === i ? (
                  <small></small>
                ) : (
                  <hr />
                )}
              </div>
            ))}

          {requestState.state === 'success' &&
            requestState.requests.length === 0 && (
              <div style={{ padding: 15 }}>
                <div className='alert alert-info'>
                  <h6 className='text-center' style={{ marginTop: 7 }}>
                    NO REQUEST SUBMITTED
                  </h6>
                </div>
              </div>
            )}
        </div>
      </>
    );
  };

  return (
    <>
      

      <RequestModal
        show={showRequests}
        handleClose={handleCloseRequest}
        handleShow={handleShowRequest}
      >
        {renderRequest()}
      </RequestModal>
      <div className='mainHeader'>
        <div className='topHeader'>
          <div className='headerLeft'>
            <img
              alt='User'
              className='logo1'
              src={logo}
              onClick={() => history.push('/')}
            />

            <h3 className='logo' onClick={() => history.push('/')}>
            Expert <span style={{ color: '#0d1136' }}>Marketplace</span>
            </h3>
          </div>
          <div className='headerRight'>
            {authState.user && authState.user.role === 'Client' && (
              <>
                <i
                  className='far fa-comment-alt'
                  style={{
                    color: '#3aa7e4',
                    marginRight: 6,
                    marginTop: 2
                  }}
                ></i>
                <span
                  style={{
                    color: '#3aa7e4',
                    fontWeight: 'bold',
                    marginRight: 15
                  }}
                  onClick={() => handleShowRequest()}
                >
                  Requests
                </span>
              </>
            )}

            {authState.user && authState.user.firstName && (
              <div>
                <div className='menu-p' onClick={handleClickDropDown}>
                  <span className='user-details'>
                    {authState.user.firstName.charAt(0).toUpperCase()}
                    {authState.user.lastName.charAt(0).toUpperCase()}
                  </span>
                  <i
                    className='fas fa-sort-down'
                    style={{ marginLeft: 5, fontSize: 20 }}
                  ></i>
                </div>

                <Menu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  style={{ marginTop: 65 }}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseDropDown}
                >
                  {authState.user && authState.user.role === 'Expert' ? (
                    <MenuItem
                      style={{ width: 200, color: '#3aa7e4' }}
                      onClick={() => history.push('expert-profile')}
                    >
                      <i
                        className='fas fa-user'
                        style={{ color: '#3aa7e4', marginRight: 10 }}
                      ></i>{' '}
                      Profile
                    </MenuItem>
                  ) : (
                    <MenuItem
                      style={{ width: 200, color: '#3aa7e4' }}
                      onClick={() => history.push(`client-profile`)}
                    >
                      <i
                        className='fas fa-user'
                        style={{ color: '#3aa7e4', marginRight: 10 }}
                      ></i>{' '}
                      Profile
                    </MenuItem>
                  )}
                  <MenuItem
                    style={{ width: 200, color: '#3aa7e4' }}
                    onClick={() =>
                      history.push({
                        pathname: '/changePassword',
                        search: `?changeType=${authState.user.role}`
                      })
                    }
                  >
                    <i
                      className='fas fa-sign-out-alt'
                      style={{ color: '#3aa7e4', marginRight: 10 }}
                    ></i>{' '}
                    Change Password
                  </MenuItem>
                  <MenuItem
                    style={{ width: 200, color: '#3aa7e4' }}
                    onClick={() => logoutHandler()}
                  >
                    <i
                      className='fas fa-sign-out-alt'
                      style={{ color: '#3aa7e4', marginRight: 10 }}
                    ></i>{' '}
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
          {!authState.token && (
            <Join text='JOIN' action='login' customClass='custom-btn' />
          )}
        </div>
      </div>
    </>
  );
};

export default FrontHeader;
