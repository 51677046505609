import { select, call, put, takeLatest, all } from 'redux-saga/effects';

import { push } from 'connected-react-router';
import apiUrl from '../Configs/ApiUrl';
import apiCall from '../Services/ApiCall';
import AuthorizedPostHeaders from '../Configs/AuthorizedPostHeaders';
import { toastr } from 'react-redux-toastr';
import { tokenSelector } from '../selectors/authSelecter';
import { adminTokenSelector } from '../selectors/adminSelecter';
import {
  REGISTER,
  LOGIN,
  LOGOUT,
  ADMIN_LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ADMIN_LOGIN,
  VERIFY_MAIL,
  CHANGE_PASSWORD,
  FORGOT_ADMIN_PASSWORD,
  RESET_ADMIN_PASSWORD
} from '../constants';
import {
  clearUser,
  forgotPasswordError,
  forgotPasswordSuccess,
  loginError,
  loginSuccess,
  adminLoginError,
  adminLoginSuccess,
  adminLogoutSuccess,
  logoutSuccess,
  registerError,
  registerSuccess,
  resetPasswordError,
  resetPasswordSuccess,
  verifyEmailSuccess,
  verifyEmailError,
  forgotAdminPasswordSuccess,
  forgotAdminPasswordError,
  resetAdminPasswordSuccess,
  resetAdminPasswordError,
  changePasswordSuccess,
  changePasswordError,
  adminLogoutUser,
  logoutUser
} from '../actions';
import PostHeaders from '../Configs/PostHeaders';
export function* logout() {
  yield put(clearUser());
  yield put(logoutSuccess());
}

export function* adminLogout() {
  yield put(adminLogoutSuccess());
}

export function* adminLogin(action) {
  const requestUrl = `${apiUrl}/loginAdmin`;
  try {
    const response = yield call(
      apiCall,
      requestUrl,
      PostHeaders(action.details)
    );

    if (response.status === 'error') {
      yield put(adminLoginError(response.message));
      toastr.error('Error!', response.message);
    } else {
      yield put(adminLoginSuccess(response.data.user, response.data.token));
      if (response.data.user.role === 'Admin') {
        yield put(push('/dashboard'));
      } else {
        yield put(push('/'));
      }
    }
  } catch (e) {
    yield put(adminLoginError(e.message));
    toastr.error('Error!', e.message);
  }
}

export function* login(action) {
  const requestUrl = `${apiUrl}/login`;
  try {
    const response = yield call(
      apiCall,
      requestUrl,
      PostHeaders(action.details)
    );

    if (response.status === 'error') {
      yield put(loginError(response.message));
      toastr.error('Error!', response.message);
    } else {
      yield put(loginSuccess(response.data.user, response.data.token));
      if (response.data.user.role === 'Client') {
        yield put(push('/client'));
      }
      if (response.data.user.role === 'Expert') {
        yield put(push('/expert-profile'));
      }
    }
  } catch (e) {
    if (e.code === '2008') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(loginError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

export function* register(action) {
  const requestUrl = `${apiUrl}/register`;
  try {
    const response = yield call(
      apiCall,
      requestUrl,
      PostHeaders(action.details)
    );
    if (response.status === 'error') {
      yield put(registerError(response.message));
      toastr.error('Error!', response.message);
    } else {
      yield put(registerSuccess());

      yield put(push('/verify-page'));
    }
  } catch (e) {
    if (e.code === '2008') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(registerError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

export function* forgotAdminPassword(action) {
  const requestUrl = `${apiUrl}/forgotPassword`;
  try {
    yield call(apiCall, requestUrl, PostHeaders(action.email));
    yield put(forgotAdminPasswordSuccess());
    toastr.success(
      'Success!',
      'Successfully sent an email. Please check your inbox.'
    );
  } catch (e) {
    if (e.code === '2008') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(forgotAdminPasswordError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

export function* resetAdminPassword(action) {
  const requestUrl = `${apiUrl}/resetPassword`;
  try {
    const response = yield call(
      apiCall,
      requestUrl,
      PostHeaders(action.formData)
    );

    if (response.status === 'success') {
      yield put(resetAdminPasswordSuccess());
      toastr.success('Success!', 'Password Updated Successfully.');
    } else {
      if (response.status === 'jwt expired') {
        yield put(clearUser());
        yield put(push('/'));
      } else {
        yield put(
          resetPasswordError('Something went wrong or token is expired')
        );
        toastr.error('Error!', 'Something went wrong or token is expired');
      }
    }
  } catch (e) {
    if (e.code === '2008') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(resetAdminPasswordError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

export function* forgotPassword(action) {
  const requestUrl = `${apiUrl}/forgotPassword`;
  try {
    yield call(apiCall, requestUrl, PostHeaders(action.email));
    yield put(forgotPasswordSuccess());
    toastr.success(
      'Success!',
      'Successfully sent an email. Please check your inbox.'
    );
  } catch (e) {
    if (e.code === '2008') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(forgotPasswordError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

export function* resetPassword(action) {
  const requestUrl = `${apiUrl}/resetPassword`;
  try {
    const response = yield call(
      apiCall,
      requestUrl,
      PostHeaders(action.formData)
    );

    if (response.status === 'success') {
      yield put(resetPasswordSuccess());
      toastr.success('Success!', 'Password Updated Successfully.');
    } else {
      if (response.status === 'jwt expired') {
        yield put(clearUser());
        yield put(push('/'));
      } else {
        yield put(
          resetPasswordError('Something went wrong or token is expired')
        );
        toastr.error('Error!', 'Something went wrong or token is expired');
      }
    }
  } catch (e) {
    if (e.code === '2008') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(resetPasswordError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

export function* changePasswordDetails(action) {
  let token = null;
  if (action.changeType === 'Admin') {
    token = yield select(adminTokenSelector);
  } else {
    token = yield select(tokenSelector);
  }
  const requestUrl = `${apiUrl}/changePassword`;
  try {
    const response = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(action.formData, token)
    );

    if (response.status === 'success') {
      yield put(changePasswordSuccess());
      toastr.success('Success!', 'Password Updated Successfully.');
    } else {
      if (response.status === 'error') {
        yield put(changePasswordError(response.message));
        toastr.error('Error!', response.message);
        if (
          response.code === 2006 ||
          response.code === 2007 ||
          response.code === 2008
        ) {
          yield put(adminLogoutUser());
          yield put(logoutUser());
        }
      } else {
        yield put(
          changePasswordError('Something went wrong or token is expired')
        );
        toastr.error('Error!', response.message);
      }
    }
  } catch (e) {
    if (e.code === '2008') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(changePasswordError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

export function* verifyEmailDetail(action) {
  const requestUrl = `${apiUrl}/verifyEmail`;
  const data = {
    token: action.token
  };
  try {
    const response = yield call(apiCall, requestUrl, PostHeaders(data));

    if (response.status === 'success') {
      yield put(verifyEmailSuccess());
      toastr.success('Success!', 'Email Verified Successfully.');
    } else {
      if (response.status === 'jwt expired') {
        yield put(clearUser());
        yield put(push('/'));
      } else {
        yield put(verifyEmailError('Something went wrong or token is expired'));
        toastr.error('Error!', 'Something went wrong or token is expired');
      }
    }
  } catch (e) {
    if (e.code === '2008') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(verifyEmailError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* adminLoginSaga() {
  yield takeLatest(ADMIN_LOGIN, adminLogin);
}

function* loginSaga() {
  yield takeLatest(LOGIN, login);
}

function* registerSaga() {
  yield takeLatest(REGISTER, register);
}

function* logoutSaga() {
  yield takeLatest(LOGOUT, logout);
}

function* adminLogoutSaga() {
  yield takeLatest(ADMIN_LOGOUT, adminLogout);
}

function* forgotAdminPasswordSaga() {
  yield takeLatest(FORGOT_ADMIN_PASSWORD, forgotAdminPassword);
}

function* resetAdminPasswordSaga() {
  yield takeLatest(RESET_ADMIN_PASSWORD, resetAdminPassword);
}

function* forgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
}

function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}

function* changePasswordSaga() {
  yield takeLatest(CHANGE_PASSWORD, changePasswordDetails);
}

function* verifyEmailSaga() {
  yield takeLatest(VERIFY_MAIL, verifyEmailDetail);
}

export default function* authSaga() {
  yield all([
    adminLoginSaga(),
    loginSaga(),
    registerSaga(),
    logoutSaga(),
    adminLogoutSaga(),
    forgotPasswordSaga(),
    resetPasswordSaga(),
    verifyEmailSaga(),
    changePasswordSaga(),
    forgotAdminPasswordSaga(),
    resetAdminPasswordSaga()
  ]);
}
