import React from 'react';
import { Modal } from 'react-bootstrap';
import './modalRequest.css';

export default function BorderedModal({ title, children, show, handleClose }) {
  return (
    <>
      <Modal
        scrollable={true}
        size='lg'
        dialogClassName='custom-request-modal'
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <i className='fas fa-times close-icon' onClick={handleClose}></i>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}
