import React from 'react';
import { Modal } from 'react-bootstrap';

import './modal.css';

export default function LoginModal({ children, show, handleClose }) {
  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}
