import {
  FETCH_CLIENT_PROFILE,
  FETCH_CLIENT_PROFILE_SUCCESS,
  FETCH_CLIENT_PROFILE_ERROR,
  UPDATE_CLIENT_PROFILE,
  UPDATE_CLIENT_PROFILE_SUCCESS,
  UPDATE_CLIENT_PROFILE_ERROR,
  UPLOAD_CLIENT_PROFILE_MEDIA,
  UPLOAD_CLIENT_PROFILE_MEDIA_SUCCESS,
  UPLOAD_CLIENT_PROFILE_MEDIA_ERROR,
  FETCH_CLIENT_EXPERT_PROFILE,
  FETCH_CLIENT_EXPERT_PROFILE_SUCCESS,
  FETCH_CLIENT_EXPERT_PROFILE_ERROR,
  CREATE_EXPERT_REQUEST,
  CREATE_EXPERT_REQUEST_SUCCESS,
  CREATE_EXPERT_REQUEST_ERROR,
  FILTER_EXPERT_PROFILE,
  FILTER_EXPERT_PROFILE_SUCCESS,
  FILTER_EXPERT_PROFILE_ERROR,
  SET_OFFSET_COUNT,
  CLEAR_CLIENT_STATE
} from '../constants';

export const fetchClientProfile = () => ({
  type: FETCH_CLIENT_PROFILE
});

export const fetchClientProfileSuccess = details => ({
  type: FETCH_CLIENT_PROFILE_SUCCESS,
  details
});

export const fetchClientProfileError = status => ({
  type: FETCH_CLIENT_PROFILE_ERROR,
  status
});

export const filterExpertProfile = (query, industry, skill, service) => ({
  type: FILTER_EXPERT_PROFILE,
  query,
  industry,
  skill,
  service
});

export const filterExpertProfileSuccess = (count, details) => ({
  type: FILTER_EXPERT_PROFILE_SUCCESS,
  count,
  details
});

export const filterExpertProfileError = status => ({
  type: FILTER_EXPERT_PROFILE_ERROR,
  status
});

export const fetchClientExpertProfile = offset => ({
  type: FETCH_CLIENT_EXPERT_PROFILE,
  offset
});

export const fetchClientExpertProfileSuccess = (count, details) => ({
  type: FETCH_CLIENT_EXPERT_PROFILE_SUCCESS,
  count,
  details
});

export const fetchClientExpertProfileError = status => ({
  type: FETCH_CLIENT_EXPERT_PROFILE_ERROR,
  status
});

export const updateClientProfile = details => ({
  type: UPDATE_CLIENT_PROFILE,
  details
});

export const updateClientProfileError = status => ({
  type: UPDATE_CLIENT_PROFILE_ERROR,
  status
});

export const updateClientProfileSuccess = () => ({
  type: UPDATE_CLIENT_PROFILE_SUCCESS
});

export const uploadClientProfileMedia = files => ({
  type: UPLOAD_CLIENT_PROFILE_MEDIA,
  files
});

export const uploadClientProfileMediaError = status => ({
  type: UPLOAD_CLIENT_PROFILE_MEDIA_ERROR,
  status
});

export const uploadClientProfileMediaSuccess = () => ({
  type: UPLOAD_CLIENT_PROFILE_MEDIA_SUCCESS
});

export const createRequest = details => ({
  type: CREATE_EXPERT_REQUEST,
  details
});

export const createRequestSuccess = () => ({
  type: CREATE_EXPERT_REQUEST_SUCCESS
});

export const createRequestError = status => ({
  type: CREATE_EXPERT_REQUEST_ERROR,
  status
});

export const setOffsetCount = offset => ({
  type: SET_OFFSET_COUNT,
  offset
});

export const clearClientState = () => ({
  type: CLEAR_CLIENT_STATE
});
