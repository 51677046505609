import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../Components/InputField';
import Spinner from '../../Components/Spinner';
import { adminLogin } from '../../actions';
import history from '../../Configs/history';
import { useForm } from 'react-hook-form';

const Login = () => {
  const dispatch = useDispatch();
  const authSelector = useSelector(state => state.auth);
  useEffect(() => {
    if (authSelector.adminToken) {
      if (authSelector.adminToken !== '') {
        history.push('/dashboard');
      }
    }
  }, [authSelector.adminToken]);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => dispatch(adminLogin(data));
  return (
    <div className='auth-bg'>
      <div className='auth'>
        <h3 className='logo' onClick={() => history.push('/')}>
          Enter <span style={{ color: '#0d1136' }}>Marketplace</span>
        </h3>
        <div className='au-section'>
          <p className='title'>Welcome Back</p>
          <p className='subTitle'>Login with your email & password</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              keyError={errors}
              name='email'
              type='email'
              value=''
              keyRef={register({ required: true })}
              placeholder='Email'
            />
            {errors.email && <span className='error'>Email is required</span>}
            <InputField
              keyError={errors}
              name='password'
              type='password'
              keyRef={register({ required: true })}
              value=''
              placeholder='Password'
            />
            {errors.password && (
              <span className='error'>Password is required</span>
            )}
            <button
              disabled={authSelector.state === 'loading' ? true : false}
              className='btn btn-block btn-primary custom-btn'
              type='submit'
            >
              {authSelector.state === 'loading' ? <Spinner /> : 'Continue'}
            </button>
          </form>
          <p className='subTitle text-center' style={{ marginTop: 20 }}>
            Forgot your password.{' '}
            <span
              style={{ color: '#3aa7e4', fontWeight: 'bold' }}
              onClick={() => history.push('/forgotAdminPassword')}
            >
              Reset it
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
