import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import apiUrl from '../Configs/ApiUrl';
import apiCall from '../Services/ApiCall';
import { FETCH_REQUEST } from '../constants';
import { fetchRequestSuccess, fetchRequestError, logoutUser } from '../actions';
import AuthorizedGetHeaders from '../Configs/AuthorizedGetHeaders';
import { tokenSelector } from '../selectors/authSelecter';

export function* fetchRequestDetails() {
  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/request/getRequests`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchRequestError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(fetchRequestSuccess(response.data));
    }
  } catch (e) {
    yield put(fetchRequestError(e.message));
  }
}

function* fetchRequestSaga() {
  yield takeLatest(FETCH_REQUEST, fetchRequestDetails);
}

export default function* rootSaga() {
  yield all([fetchRequestSaga()]);
}
