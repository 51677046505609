import React from 'react';
import FrontHeader from '../../Components/FrontHeader';
import Footer from '../../Components/Footer';



export default function Home() {
  return (
    <div>
      <FrontHeader />
      <div className='interview' style={{minHeight: '100vh',padding: '0px 30px 30px 30px'}}>
          <h2>
          Terms and conditions
          </h2>
          <p>
            Hire Interviewer, Inc owns the project Experts Labs in one which is committed to protect your privacy. This Privacy Policy (“Policy”) explains how we collect, use, disclose and protect Personal Information (as it is defined below) of our experts and Clients. It applies to all Personal Information collected by us on the experts Labs Web application.<br/>
            By using our Services, you accept the Terms and Conditions, and accept our privacy practices described in this Policy. If you do not feel comfortable with any part of this Policy or our Terms and Conditions, you must not use or access our Services.
            The Company has two major users in this app experts and clients. The app collects 
            experts professional Information required to showcase their skills mainly, name, email, phone, resume, skills, services, industry, experience and his availability.
            For clients it collects name, company email, phone.
          </p>
          <h4>
          1. Collection of Personal Information 
          </h4>
          <p>
          When you use our Services, we may collect or receive personal Information about you to provide our services and operate our business. The types of Personal Information that we may collect about you are:
          <br/><br/>
          a. <span style={{textDecoration: 'underline'}}>Information You Provide to Us Directly</span><br/>
          You may provide your Personal Information to us when you use our services, including when you register as an expert you supposed to give information about the Working experience, services, skills and industry.<br/><br/>
          b. <span style={{textDecoration: 'underline'}}>Information Received From You as Part of Your Communications</span><br/>
          When you use our services, we may automatically collect and store certain information about you and the activity you engaged in, for example: your name and contact.
          
          <br/><br/>
          c. <span style={{textDecoration: 'underline'}}>To Protect You, Others, and Our Business</span><br/>
          We use your information to protect you, others, and our business, including, without limitation, using information for fraud prevention, for enforcement of our Terms of Service, to comply with the law, and to protect against security risks.
          <br/><br/>
          d. <span style={{textDecoration: 'underline'}}>For Our Business Purposes</span><br/>
          We may use your information for any other purpose of exchanging the information, about the services or otherwise with your consent.
          </p>
          <h4>
          2. Data Retention 
          </h4>
          <p>
          We will retain your personal Information for as long as your account is active or as needed to provide you services and to maintain a record for reporting purposes. We will also retain and use your personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
          </p>
        </div>
      <Footer />
    </div>
  );
}
