import React, { useState } from 'react';
import Spinner from '../Components/Spinner';
import './dropzone.css';

import Dropzone from 'react-dropzone';

export default function DropZone({
  state,
  mode,
  styles,
  onUploadMedia,
  type,
  showButton,
  dropType
}) {
  const [fileNames, setFileNames] = useState([]);

  let handleDrop;
  if (type === 'autoSubmit') {
    handleDrop = acceptedFiles => {
      acceptedFiles.map(file => onUploadMedia(file));
      setFileNames(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    };
  } else if (showButton === true) {
    handleDrop = acceptedFiles => {
      setFileNames(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    };
  } else {
    handleDrop = acceptedFiles => {
      acceptedFiles.map(file => onUploadMedia(file));
      setFileNames(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    };
  }

  const handleRemove = () => {
    setFileNames([]);
    onUploadMedia(null);
  };

  return (
    <div className='dropzone-app dropzone-parent' style={styles}>
      <Dropzone onDrop={handleDrop} accept={dropType}>
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject
        }) => {
          const additionalClass = isDragAccept
            ? 'accept'
            : isDragReject
            ? 'reject'
            : '';

          return (
            <div
              {...getRootProps({
                className: `dropzone ${additionalClass}`
              })}
            >
              <input {...getInputProps()} />
              <span className='dropzone-app-ul-span'>
                {isDragActive ? (
                  <i className='fas fa-file-upload upload-file'></i>
                ) : (
                  <i className='fas fa-file-upload upload-file'></i>
                )}
              </span>
              <p>
                Choose a file{' '}
                <span style={{ color: '#3aa7e4' }}>or drag it here</span>
              </p>

              {type !== 'simple' && fileNames.length > 0 && (
                <div>
                  <img
                    src={fileNames[0].preview}
                    alt={fileNames[0].name}
                    width={80}
                    height={80}
                  />
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
      {type === 'simple' && fileNames.length > 0 && (
        <div className='selection-big'>
          <small style={{ marginLeft: 20 }}>{fileNames[0].name}</small>

          <i
            onClick={() => handleRemove()}
            style={{ marginLeft: 10 }}
            title='Remove File'
            className='far fa-trash-alt'
          ></i>
        </div>
      )}
      {showButton === true && (
        <button
          disabled={state === 'loading' ? true : false}
          className='btn custom-btn btn-block'
          onClick={() => onUploadMedia(fileNames)}
        >
          {state === 'loading' ? <Spinner /> : 'Upload'}
        </button>
      )}
    </div>
  );
}
