import React from 'react';
import { Modal } from 'react-bootstrap';
import './modalHire.css';

export default function BorderedModal({ title, children, show, handleClose }) {
  return (
    <>
      <Modal
        scrollable={true}
        size='lg'
        dialogClassName='custom-hire-modal'
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header
          closeButton
          style={{
            background: '#3aa7e4 !important',
            color: 'white !important'
          }}
        >
          <Modal.Title id='contained-modal-title-vcenter'>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}
