import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export default function Spinner({ color, cWdith, cHeight }) {
  return (
    <div>
      <Loader
        type='Puff'
        color={color ? color : '#fff'}
        height={cHeight ? cHeight : 40}
        width={cWdith ? cWdith : 30}
      />
    </div>
  );
}
