import {
  FETCH_SKILLS_PROFILE,
  FETCH_SKILLS_PROFILE_SUCCESS,
  FETCH_SKILLS_PROFILE_ERROR,
  FETCH_SERVICES_PROFILE,
  FETCH_SERVICES_PROFILE_SUCCESS,
  FETCH_SERVICES_PROFILE_ERROR,
  FETCH_INDUSTRIES_PROFILE,
  FETCH_INDUSTRIES_PROFILE_SUCCESS,
  FETCH_INDUSTRIES_PROFILE_ERROR
} from '../constants';

export const fetchSkills = () => ({
  type: FETCH_SKILLS_PROFILE
});

export const fetchSkillsSuccess = details => ({
  type: FETCH_SKILLS_PROFILE_SUCCESS,
  details
});

export const fetchSkillsError = status => ({
  type: FETCH_SKILLS_PROFILE_ERROR,
  status
});

export const fetchServices = () => ({
  type: FETCH_SERVICES_PROFILE
});

export const fetchServicesSuccess = details => ({
  type: FETCH_SERVICES_PROFILE_SUCCESS,
  details
});

export const fetchServicesError = status => ({
  type: FETCH_SERVICES_PROFILE_ERROR,
  status
});

export const fetchIndustries = () => ({
  type: FETCH_INDUSTRIES_PROFILE
});

export const fetchIndustriesSuccess = details => ({
  type: FETCH_INDUSTRIES_PROFILE_SUCCESS,
  details
});

export const fetchIndustriesError = status => ({
  type: FETCH_INDUSTRIES_PROFILE_ERROR,
  status
});
