import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const RestrictedRoute = ({ component: Component, role, ...rest }) => {
  const authState = useSelector(state => state.auth);
    return (
      <Route
        {...rest}
        render={props =>
            authState?.user?.role != 'Client' ? <Component {...props} /> : <Redirect to='/client' />
        }
      />
    );

};

export default RestrictedRoute;
