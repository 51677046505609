import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TopHeader from '../../../../Components/TopHeader';
import Spinner from '../../../../Components/Spinner';
import { resetPassword } from '../../../../actions';
import { useForm } from 'react-hook-form';

export default function ProfileClient() {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const authState = useSelector(state => state.auth);
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');
  const onSubmit = data => {
    const formData = {
      password: data.password,
      token
    };
    dispatch(resetPassword(formData));
  };

  return (
    <React.Fragment>
      <TopHeader />
      <div>
        <div className='profileSection'>
          <div className='profile'>
            <h3 className='text-center' style={{ fontWeight: 'bold' }}>
              ENTER NEW PASSWORD
            </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='profileBody'>
                <div
                  className='input-row'
                  style={{ justifyContent: 'space-between' }}
                >
                  <input
                    name='password'
                    type='password'
                    defaultValue=''
                    ref={register({
                      required: 'You must specify a password',
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters'
                      }
                    })}
                    className='form-control'
                    placeholder='Password'
                  />
                  <i title='Edit' className='fas fa-pencil-alt'></i>
                </div>
                <hr />

                {errors.password && (
                  <span className='error'>{errors.password.message}</span>
                )}

                <div className='input-row'>
                  <input
                    className='form-control'
                    placeholder='Confirm Password'
                    name='password_repeat'
                    type='password'
                    defaultValue=''
                    ref={register({
                      validate: value =>
                        value === password.current ||
                        'The passwords do not match'
                    })}
                  />
                  <i title='Edit' className='fas fa-pencil-alt'></i>
                </div>

                <hr />

                {errors.password_repeat && (
                  <span className='error'>
                    {errors.password_repeat.message}
                  </span>
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    disabled={authState.state === 'loading' ? true : false}
                    className='btn custom-btn'
                    style={{ width: '200px' }}
                    type='submit'
                  >
                    {authState.state === 'loading' ? (
                      <Spinner />
                    ) : (
                      'Update Password'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
