import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_ERROR
} from '../constants';

const initialAuthState = {
  state: 'initial',
  mode: 'initial',
  dashboard: {},
  status: ''
};

export function dashboardReducer(state = initialAuthState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD:
      return {
        ...state,
        mode: 'fetch_dashboard',
        state: 'loading',
        status: ''
      };

    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        state: 'success',
        dashboard: action.details,
        status: ''
      };

    case FETCH_DASHBOARD_ERROR:
      return { ...state, state: 'error', status: action.status };

    default:
      return state;
  }
}
