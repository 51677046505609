import React from 'react';
import Carousel from 'react-elastic-carousel';
// import Item from './Item';
import { placeholder } from '../Assets';
import './carousel.css';

export default function Testimonials() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 4 }
  ];
  return (
    <div className='carosel-wrapper'>
      <h2 className='int-h3' style={{ textAlign: 'center' }}>
        Testimonials
      </h2>
      <Carousel itemsToShow={2} breakPoints={breakPoints}>
        {/* <Item /> */}
        <div className='test-p'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <div className='test-icon'>
            <i className='fas fa-quote-right'></i>
          </div>
          <img
            alt='user logo'
            src={placeholder}
            width='150'
            height='150'
            style={{ marginTop: 20 }}
          />
          <h3 className='text-center'>
            {' '}
            Senior Manager, <br />
            <small>
              Information Security @ American multinational cybersecurity company
            </small>
          </h3>
          <p className='text-center'>
            This solution may be useful when we are looking into positions that
            are in a new area/domain and don’t have good internal resources for
            the interview. So, we will consider getting help from their experts as
            Interviewer/Co-Interviewer in our hiring process.
            {/* <span style={{ color: 'blue' }}> Read More</span> */}
          </p>
        </div>
    </div>

    <div className='test-p' style={{minHeight:'450px'}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <div className='test-icon'>
            <i className='fas fa-quote-right'></i>
          </div>
          <img
            alt='user logo'
            src={placeholder}
            width='150'
            height='150'
            style={{ marginTop: 20 }}
          />
          <h3 className='text-center'>
            {' '}
            Director, <br />
            <small>
            in Financial Services company
            </small>
          </h3>
          <p className='text-center'>
          This sounds great and promising when we are working on newer or less known technologies and want some expert to walk us through areas to ramp up our team, so we can deliver quality product, on time.
          </p>
        </div>
    </div>
      </Carousel>
    </div>
  );
}
