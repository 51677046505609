import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import apiUrl from '../Configs/ApiUrl';
import apiCall from '../Services/ApiCall';
import history from '../Configs/history';
import { toastr } from 'react-redux-toastr';
import {
  FETCH_ADMIN_REQUEST,
  CREATE_ADMIN_NOTES,
  CREATE_ADMIN_FEEDBACK,
  UPLOAD_ADMIN_FEEDBACK_MEDIA,
  UPDATE_REQUEST_STATUS,
  UPDATE_COMPENSATION
} from '../constants';
import {
  fetchAdminRequestError,
  fetchAdminRequestSuccess,
  createAdminNotesSuccess,
  createAdminNotesError,
  createAdminFeedbackSuccess,
  createAdminFeedbackError,
  fetchAdminRequests,
  uploadAdminFeedbackMediaSuccess,
  uploadAdminFeedbackMediaError,
  updateRequestStatusSuccess,
  updateRequestStatusError,
  updateCompensationSuccess,
  updateCompensationError,
  adminLogoutUser
} from '../actions';
import AuthorizedGetHeaders from '../Configs/AuthorizedGetHeaders';
import AuthorizedPutHeaders from '../Configs/AuthorizedPutHeaders';
import AuthorizedPutDataHeaders from '../Configs/AuthorizedPutDataHeaders';
import { adminTokenSelector } from '../selectors/adminSelecter';

export function* fetchRequestDetails(action) {
  const token = yield select(adminTokenSelector);

  const requestUrl = `${apiUrl}/admin/getRequests?status=${action.status}`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchAdminRequestError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(fetchAdminRequestSuccess(response.data));
    }
  } catch (e) {
    yield put(fetchAdminRequestError(e.message));
    toastr.error('Error!', e.message);
  }
}

function* fetchRequestSaga() {
  yield takeLatest(FETCH_ADMIN_REQUEST, fetchRequestDetails);
}

export function* createAdminNotesDetails(action) {
  const token = yield select(adminTokenSelector);
  const data = {
    id: parseInt(action.id),
    notes: action.details
  };
  const requestUrl = `${apiUrl}/admin/updateAdminNotes`;
  const options = AuthorizedPutHeaders(data, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(createAdminNotesError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(createAdminNotesSuccess(response.data));
      toastr.success('Success!', 'Successfully created.');
      yield put(fetchAdminRequests(action.status));
    }
  } catch (e) {
    yield put(createAdminNotesError(e.message));
    toastr.error('Error!', e.message);
  }
}

function* createAdminNotesSaga() {
  yield takeLatest(CREATE_ADMIN_NOTES, createAdminNotesDetails);
}

//
export function* updateCompensationDetails(action) {
  const token = yield select(adminTokenSelector);
  const data = {
    expertProfileId: parseInt(action.id),
    compensation: action.details
  };
  const requestUrl = `${apiUrl}/admin/updateCompensation`;
  const options = AuthorizedPutHeaders(data, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(updateCompensationError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(updateCompensationSuccess(response.data));
      toastr.success('Success!', 'Successfully updated.');
    }
  } catch (e) {
    yield put(updateCompensationError(e.message));
    toastr.error('Error!', e.message);
  }
}

function* updateCompensationDetailsSaga() {
  yield takeLatest(UPDATE_COMPENSATION, updateCompensationDetails);
}
//

export function* createAdminFeedbackDetails(action) {
  const token = yield select(adminTokenSelector);

  const requestUrl = `${apiUrl}/admin/updateExpertsFeedback`;
  const options = AuthorizedPutDataHeaders(action.details, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(createAdminFeedbackError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(createAdminFeedbackSuccess(action.requestId));
      toastr.success('Success!', 'Successfully created.');
      yield put(fetchAdminRequests(action.status));
    }
  } catch (e) {
    yield put(createAdminFeedbackError(e.message));
    toastr.error('Error!', e.message);
  }
}

function* createAdminFeedbackSaga() {
  yield takeLatest(CREATE_ADMIN_FEEDBACK, createAdminFeedbackDetails);
}

export function* uploadAdminFeedbackMediaDetails(action) {
  const token = yield select(adminTokenSelector);

  const requestUrl = `${apiUrl}/admin/updateExpertFile`;
  const options = AuthorizedPutDataHeaders(action.details, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(uploadAdminFeedbackMediaError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(uploadAdminFeedbackMediaSuccess(response.data));
      toastr.success('Success!', 'Successfully updated.');
      yield put(fetchAdminRequests('Requested'));
    }
  } catch (e) {
    yield put(uploadAdminFeedbackMediaError(e.message));
    toastr.error('Error!', e.message);
  }
}

function* uploadAdminFeedbackMediaSaga() {
  yield takeLatest(
    UPLOAD_ADMIN_FEEDBACK_MEDIA,
    uploadAdminFeedbackMediaDetails
  );
}

export function* updateRequestDetails(action) {
  const token = yield select(adminTokenSelector);
  const data = {
    id: parseInt(action.id),
    status: action.details
  };
  const requestUrl = `${apiUrl}/admin/updateRequestStatus`;
  const options = AuthorizedPutHeaders(data, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(updateRequestStatusError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(updateRequestStatusSuccess(response.data));
      toastr.success('Success!', 'Successfully updated.');
      history.push('/requests');
    }
  } catch (e) {
    yield put(updateRequestStatusError(e.message));
    toastr.error('Error!', e.message);
  }
}

function* updateRequestSaga() {
  yield takeLatest(UPDATE_REQUEST_STATUS, updateRequestDetails);
}

export default function* rootSaga() {
  yield all([
    fetchRequestSaga(),
    createAdminNotesSaga(),
    createAdminFeedbackSaga(),
    uploadAdminFeedbackMediaSaga(),
    updateRequestSaga(),
    updateCompensationDetailsSaga()
  ]);
}
