// Constants
export const LOGIN = 'MarketPlace/Authenticate/LOGIN';

export const LOGIN_SUCCESS = 'MarketPlace/Authenticate/LOGIN_SUCCESS';

export const LOGIN_ERROR = 'MarketPlace/Authenticate/LOGIN_ERROR';

export const ADMIN_LOGIN = 'MarketPlace/Authenticate/ADMIN_LOGIN';

export const ADMIN_LOGIN_SUCCESS =
  'MarketPlace/Authenticate/ADMIN_LOGIN_SUCCESS';

export const ADMIN_LOGIN_ERROR = 'MarketPlace/Authenticate/ADMIN_LOGIN_ERROR';

export const REGISTER = 'MarketPlace/Authenticate/REGISTER';

export const REGISTER_SUCCESS = 'MarketPlace/Authenticate/REGISTER_SUCCESS';

export const REGISTER_ERROR = 'MarketPlace/Authenticate/REGISTER_ERROR';

export const LOGOUT = 'MarketPlace/Authenticate/LOGOUT';

export const LOGOUT_SUCCESS = 'MarketPlace/Authenticate/LOGOUT_SUCCESS';

export const LOGOUT_ERROR = 'MarketPlace/Authenticate/LOGOUT_ERROR';

export const ADMIN_LOGOUT = 'MarketPlace/Authenticate/ADMIN_LOGOUT';

export const ADMIN_LOGOUT_SUCCESS =
  'MarketPlace/Authenticate/ADMIN_LOGOUT_SUCCESS';

export const ADMIN_LOGOUT_ERROR = 'MarketPlace/Authenticate/ADMIN_LOGOUT_ERROR';

export const FORGOT_ADMIN_PASSWORD =
  'MarketPlace/Authenticate/FORGOT_ADMIN_PASSWORD';

export const FORGOT_ADMIN_PASSWORD_SUCCESS =
  'MarketPlace/Authenticate/FORGOT_ADMIN_PASSWORD_SUCCESS';

export const FORGOT_ADMIN_PASSWORD_ERROR =
  'MarketPlace/Authenticate/FORGOT_ADMIN_PASSWORD_ERROR';

export const RESET_ADMIN_PASSWORD =
  'MarketPlace/Authenticate/RESET_ADMIN_PASSWORD';

export const RESET_ADMIN_PASSWORD_SUCCESS =
  'MarketPlace/Authenticate/RESET_ADMIN_PASSWORD_SUCCESS';

export const RESET_ADMIN_PASSWORD_ERROR =
  'MarketPlace/Authenticate/RESET_ADMIN_PASSWORD_ERROR';

export const FORGOT_PASSWORD = 'MarketPlace/Authenticate/FORGOT_PASSWORD';

export const FORGOT_PASSWORD_SUCCESS =
  'MarketPlace/Authenticate/FORGOT_PASSWORD_SUCCESS';

export const FORGOT_PASSWORD_ERROR =
  'MarketPlace/Authenticate/FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD = 'MarketPlace/Authenticate/RESET_PASSWORD';

export const RESET_PASSWORD_SUCCESS =
  'MarketPlace/Authenticate/RESET_PASSWORD_SUCCESS';

export const RESET_PASSWORD_ERROR =
  'MarketPlace/Authenticate/RESET_PASSWORD_ERROR';

export const CHANGE_PASSWORD = 'MarketPlace/Authenticate/CHANGE_PASSWORD';

export const CHANGE_PASSWORD_SUCCESS =
  'MarketPlace/Authenticate/CHANGE_PASSWORD_SUCCESS';

export const CHANGE_PASSWORD_ERROR =
  'MarketPlace/Authenticate/CHANGE_PASSWORD_ERROR';

export const VERIFY_MAIL = 'MarketPlace/Authenticate/VERIFY_MAIL';

export const VERIFY_MAIL_SUCCESS =
  'MarketPlace/Authenticate/VERIFY_MAIL_SUCCESS';

export const VERIFY_MAIL_ERROR = 'MarketPlace/Authenticate/VERIFY_MAIL_ERROR';

export const CLEAR_USER = 'MarketPlace/Authenticate/CLEAR_USER';

export const CLEAR_ADMIN = 'MarketPlace/Authenticate/CLEAR_ADMIN';
