import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopHeader from '../../Components/TopHeader';
import LeftSideBar from '../../Components/LeftSideBar';
import Content from '../../Components/Content';
import DrawerComponent from '../../Components/DrawerComponent';

import {
  fetchAdminRequests,
  createAdminNotes,
  createAdminFeedback,
  updateRequestStatus
} from '../../actions';

export default function Request() {
  const dispatch = useDispatch();
  const [showExpertFile, setShowExpertFile] = useState(false);
  const [notesActive, setNotesActive] = useState(false);
  const [feedbackActive, setFeedbackActive] = useState(false);
  const [requestMedia, setRequestMedia] = useState(null);
  const [state, setState] = React.useState({
    right: false
  });

  const [singleUser, setSingleUser] = useState(null);
  const [notes, setAdminNotes] = useState(null);
  const [feedback, setAdminFeedback] = useState(null);

  useEffect(() => {
    dispatch(fetchAdminRequests('Completed'));
  }, [dispatch]);
  let counter = 0;

  const requestSelector = useSelector(state => state.adminRequests);

  useEffect(() => {
    if (
      requestSelector.state === 'loading' &&
      requestSelector.mode === 'fetch_admin_request'
    ) {
      setShowExpertFile(false);
      setNotesActive(false);
      setAdminFeedback(false);
      setFeedbackActive(false);
      setNotesActive(false);
    }
  }, [requestSelector.mode, requestSelector.state]);

  useEffect(() => {
    if (requestSelector.singleRequestId) {
      if (requestSelector.requests.length > 0) {
        let DataFilter = requestSelector.requests.filter(
          request => request.id === requestSelector.singleRequestId
        );
        setSingleUser(DataFilter[0]);
      }
    }
  }, [requestSelector.requests, requestSelector.singleRequestId]);

  const toggleDrawer = (anchor, open, userData) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({
      ...state,
      [anchor]: open
    });

    setSingleUser(userData);
    if (userData && userData.expertFeedback !== null) {
      setAdminFeedback(userData.expertFeedback);
    } else {
      setAdminFeedback(null);
    }
    if (userData && userData.adminNotes !== null) {
      setAdminNotes(userData.adminNotes);
    } else {
      setAdminNotes(null);
    }
  };

  const handleExpertFileShow = () => {
    setShowExpertFile(true);
  };

  const onSubmitAdminNotesHanlder = (requestId, status) => {
    dispatch(createAdminNotes(notes !== '' ? notes : null, requestId, status));
  };

  const onSubmitAdminFeedbackHanlder = (requestId, status) => {
    const formData = new FormData();
    formData.append('id', requestId);
    formData.append('expertFeedback', feedback !== '' ? feedback : null);
    if (requestMedia) {
      formData.append('file', requestMedia);
    }

    dispatch(createAdminFeedback(formData, status, requestId));
  };

  const onUploadExpertMedia = fileNames => {
    setRequestMedia(fileNames);
  };

  const onSubmitRequestStatus = () => {
    dispatch(updateRequestStatus('Completed', singleUser.id));
  };
  return (
    <React.Fragment>
      <div>
        <TopHeader role='Admin' />
        <div className='wrapper'>
          <LeftSideBar />
          <Content style={{ position: 'relative' }}>
            <DrawerComponent
              toggleDrawer={toggleDrawer}
              state={state}
              status='Completed'
              singleUser={singleUser}
              notes={notes}
              setAdminNotes={setAdminNotes}
              onSubmitAdminNotesHanlder={onSubmitAdminNotesHanlder}
              feedback={feedback}
              setAdminFeedback={setAdminFeedback}
              onSubmitAdminFeedbackHanlder={onSubmitAdminFeedbackHanlder}
              requestSelector={requestSelector}
              handleExpertFileShow={handleExpertFileShow}
              onSubmitRequestStatus={onSubmitRequestStatus}
              notesActive={notesActive}
              setNotesActive={setNotesActive}
              feedbackActive={feedbackActive}
              setFeedbackActive={setFeedbackActive}
              onUploadExpertMedia={onUploadExpertMedia}
              pathReload='project-completion'
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='table-responsive'>
                  <table
                    className='table table-bordered'
                    style={{ background: 'white' }}
                  >
                    <thead>
                      <tr className='d-flex'>
                        <th scope='col' className='col-1'>
                          ID
                        </th>
                        <th scope='col' className='col-2'>
                          Client Name
                        </th>
                        <th scope='col' className='col-2'>
                          Expert Name
                        </th>
                        <th scope='col' className='col-7'>
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestSelector.requests &&
                        requestSelector.requests.length > 0 &&
                        requestSelector.requests.map(userData => (
                          <tr className='d-flex' key={`tr-${userData.id}`}>
                            <td className='col-1'>{(counter = counter + 1)}</td>
                            <td className='col-2'>
                              {userData.ClientUser.firstName +
                                ' ' +
                                userData.ClientUser.lastName}
                            </td>
                            <td className='col-2'>
                              {userData.ExpertUser.firstName +
                                ' ' +
                                userData.ExpertUser.lastName}
                            </td>
                            <td className='col-5'>{userData.description}</td>
                            <td className='col-2'>
                              <button
                                className='profile-btn'
                                onClick={toggleDrawer('right', true, userData)}
                                title='View Profile'
                              >
                                Details
                              </button>
                            </td>
                          </tr>
                        ))}
                      {requestSelector.requests &&
                        requestSelector.state === 'success' &&
                        requestSelector.requests.length === 0 && (
                          <tr>
                            <td className='col-12'>
                              <p
                                style={{ fontSize: 11, marginBottom: 0 }}
                                className='text-center'
                              >
                                No Data Found
                              </p>
                            </td>
                          </tr>
                        )}

                      {requestSelector.requests &&
                        requestSelector.state === 'loading' && (
                          <tr>
                            <td className='col-12'>
                              <p
                                style={{ fontSize: 11, marginBottom: 0 }}
                                className='text-center'
                              >
                                Loading...
                              </p>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Content>
        </div>
      </div>
    </React.Fragment>
  );
}
