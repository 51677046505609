import React, { useState, useEffect } from 'react';
// import Spinner from '../Components/Spinner';

import Dropzone from 'react-dropzone';

export default function DropZone({ state, mode, styles, onUploadMedia }) {
  const [fileNames, setFileNames] = useState([]);

  const handleDrop = acceptedFiles => {
    acceptedFiles.map(file => onUploadMedia(file));
    setFileNames(acceptedFiles[0]);
  };

  useEffect(() => {
    if (state === 'loading' && mode === 'fetch_admin_request') {
      setFileNames([]);
    }
  }, [state, mode]);

  const handleRemove = () => {
    setFileNames([]);
    onUploadMedia(null);
  };

  return (
    <div className='dropzone-parent' style={styles}>
      <Dropzone onDrop={handleDrop}>
        {({
          getRootProps,
          getInputProps,
          // isDragActive,
          isDragAccept,
          isDragReject
        }) => {
          const additionalClass = isDragAccept
            ? 'accept'
            : isDragReject
            ? 'reject'
            : '';

          return (
            <div
              {...getRootProps({
                className: ` ${additionalClass}`
              })}
            >
              <input {...getInputProps()} />
              <div className='file-details-upload'>
                <div className='btn fileBtn'>
                  <i
                    className='fas fa-cloud-upload-alt'
                    style={{
                      color: '#fff',
                      marginRight: 5,
                      marginLeft: '-8px',
                      fontSize: 20
                    }}
                  ></i>
                  Choose
                </div>
              </div>
            </div>
          );
        }}
      </Dropzone>
      <div className='selection'>
        <small style={{ marginLeft: 20 }}>
          {fileNames && fileNames.name ? fileNames.name : 'No File Selected'}
        </small>
        {fileNames && fileNames.name ? (
          <i
            onClick={() => handleRemove()}
            style={{ marginLeft: 10 }}
            title='Remove File'
            className='far fa-trash-alt'
          ></i>
        ) : null}
      </div>
    </div>
  );
}
