import React from 'react';
import { placeholder } from '../Assets';
import Spinner from '../Components/Spinner';
import apiUrl from '../Configs/ApiUrl';
import history from '../Configs/history';

export default function PopuplarExpertClient({
  expertSelector,
  setSelectedExpert,
  handleHireShow
}) {
  return (
    <div className='main-banner-section'>
      <div className='popular-details'>
        <div className=''>
          <h2 style={{marginLeft: 15}}>Most Popular Experts</h2>
         
          {expertSelector.state === 'loading' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10
              }}
            >
              <Spinner color='#000' cWdith={50} cHeight={60} />
            </div>
          )}

          {expertSelector.experts &&
            expertSelector.experts.length === 0 &&
            expertSelector.state === 'success' && (
              <div className='alert alert-info'>
                <h6 className='text-center' style={{ marginTop: 7 }}>
                  NO RECORD FOUND
                </h6>
              </div>
            )}
          <div className='row cRow'>
            {expertSelector.experts &&
              expertSelector.experts.length > 0 &&
              expertSelector.experts.map(expert => (
                <div className='col-md-3' key={`expert-${expert.id}`}>
                  <div className='main-area'>
                    <div className='expertBannerPopular'></div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <img
                        src={
                          expert.ExpertProfile?.profileImageUrl
                            ? apiUrl +
                              '/' +
                              expert.ExpertProfile.profileImageUrl
                            : placeholder
                        }
                        className='profileExpertImg'
                        alt='User Logo'
                      />

                      <div style={{ marginTop: 10 }}>
                        <h6 className='text-center'>
                          {expert.firstName.toUpperCase() +
                            ' ' +
                            expert.lastName.toUpperCase()}
                        </h6>
                        <p className='text-center prof'>
                          {expert.ExpertProfile?.headline}
                        </p>
                        {expert.ExpertProfile?.role &&
                          expert.ExpertProfile?.organization && (
                            <p className='text-center prof'>
                              {expert.ExpertProfile.role} at{' '}
                              {expert.ExpertProfile.organization}
                            </p>
                          )}

                        <div className='al-list'>
                          {expert.ExpertProfile?.ExpertSkill.length > 0 &&
                            expert.ExpertProfile?.ExpertSkill.map(skill => (
                              <small key={`sk-cl-${skill.id}`}>
                                <i className='fas fa-circle'></i>{' '}
                                {skill.Skill.title}
                              </small>
                            ))}
                        </div>

                        <small
                          className='details-list'
                          onClick={() =>
                            history.push(
                              `/expert-details/${expert.ExpertProfile.id}`
                            )
                          }
                        >
                          View details
                        </small>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <button
                            className='btn add-user-request'
                            onClick={() => {
                              handleHireShow();
                              setSelectedExpert(expert);
                            }}
                          >
                            <i
                              className='fas fa-user'
                              style={{ color: '#3aa7e4' }}
                            ></i>{' '}
                            Hire
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
