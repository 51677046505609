// Constants
export const FETCH_SKILLS_PROFILE = 'MarketPlace/Skills/FETCH_SKILLS_PROFILE';

export const FETCH_SKILLS_PROFILE_SUCCESS =
  'MarketPlace/Skills/FETCH_SKILLS_PROFILE_SUCCESS';

export const FETCH_SKILLS_PROFILE_ERROR =
  'MarketPlace/Skills/FETCH_SKILLS_PROFILE_ERROR';

export const FETCH_SERVICES_PROFILE =
  'MarketPlace/Services/FETCH_SERVICES_PROFILE';

export const FETCH_SERVICES_PROFILE_SUCCESS =
  'MarketPlace/Services/FETCH_SERVICES_PROFILE_SUCCESS';

export const FETCH_SERVICES_PROFILE_ERROR =
  'MarketPlace/Services/FETCH_SERVICES_PROFILE_ERROR';

export const FETCH_INDUSTRIES_PROFILE =
  'MarketPlace/Industries/FETCH_INDUSTRIES_PROFILE';

export const FETCH_INDUSTRIES_PROFILE_SUCCESS =
  'MarketPlace/Industries/FETCH_INDUSTRIES_PROFILE_SUCCESS';

export const FETCH_INDUSTRIES_PROFILE_ERROR =
  'MarketPlace/Industries/FETCH_INDUSTRIES_PROFILE_ERROR';
