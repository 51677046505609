// Constants
export const FETCH_EXPERT_PROFILE =
  'MarketPlace/ExpertProfile/FETCH_EXPERT_PROFILE';

export const FETCH_EXPERT_PROFILE_SUCCESS =
  'MarketPlace/ExpertProfile/FETCH_EXPERT_PROFILE_SUCCESS';

export const FETCH_EXPERT_PROFILE_ERROR =
  'MarketPlace/ExpertProfile/FETCH_EXPERT_PROFILE_ERROR';

export const UPDATE_EXPERT_PROFILE =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_PROFILE';

export const UPDATE_EXPERT_PROFILE_SUCCESS =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_PROFILE_SUCCESS';

export const UPDATE_EXPERT_PROFILE_ERROR =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_PROFILE_ERROR';

export const UPDATE_EXPERT_GENERAL_DETAILS =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_GENERAL_DETAILS';

export const UPDATE_EXPERT_GENERAL_DETAILS_SUCCESS =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_GENERAL_DETAILS_SUCCESS';

export const UPDATE_EXPERT_GENERAL_DETAILS_ERROR =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_GENERAL_DETAILS_ERROR';

export const UPLOAD_EXPERT_PROFILE_MEDIA =
  'MarketPlace/ExpertProfile/UPLOAD_EXPERT_PROFILE_MEDIA';

export const UPLOAD_EXPERT_PROFILE_MEDIA_SUCCESS =
  'MarketPlace/ExpertProfile/UPLOAD_EXPERT_PROFILE_MEDIA_SUCCESS';

export const UPLOAD_EXPERT_PROFILE_MEDIA_ERROR =
  'MarketPlace/ExpertProfile/UPLOAD_EXPERT_PROFILE_MEDIA_ERROR';

export const CREATE_EXPERT_ACHEIVEMENT =
  'MarketPlace/ExpertProfile/CREATE_EXPERT_ACHEIVEMENT';

export const CREATE_EXPERT_ACHEIVEMENT_SUCCESS =
  'MarketPlace/ExpertProfile/CREATE_EXPERT_ACHEIVEMENT_SUCCESS';

export const CREATE_EXPERT_ACHEIVEMENT_ERROR =
  'MarketPlace/ExpertProfile/CREATE_EXPERT_ACHEIVEMENT_ERROR';

export const CREATE_EXPERT_PUBLICATION =
  'MarketPlace/ExpertProfile/CREATE_EXPERT_PUBLICATION';

export const CREATE_EXPERT_PUBLICATION_SUCCESS =
  'MarketPlace/ExpertProfile/CREATE_EXPERT_PUBLICATION_SUCCESS';

export const CREATE_EXPERT_PUBLICATION_ERROR =
  'MarketPlace/ExpertProfile/CREATE_EXPERT_PUBLICATION_ERROR';

export const UPDATE_EXPERT_PUBLICATION =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_PUBLICATION';

export const UPDATE_EXPERT_PUBLICATION_SUCCESS =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_PUBLICATION';

export const UPDATE_EXPERT_PUBLICATION_ERROR =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_PUBLICATION';

export const UPDATE_EXPERT_INDUSTRY =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_INDUSTRY';

export const UPDATE_EXPERT_INDUSTRY_SUCCESS =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_INDUSTRY_SUCCESS';

export const UPDATE_EXPERT_INDUSTRY_ERROR =
  'MarketPlace/ExpertProfile/UPDATE_EXPERT_INDUSTRY_ERROR';

export const CREATE_TIMINGS = 'MarketPlace/ExpertProfile/CREATE_TIMINGS';

export const CREATE_TIMINGS_SUCCESS =
  'MarketPlace/ExpertProfile/CREATE_TIMINGS_SUCCESS';

export const CREATE_TIMINGS_ERROR =
  'MarketPlace/ExpertProfile/CREATE_TIMINGS_ERROR';

export const CLEAR_STATUS = 'CLEAR_STATUS';
