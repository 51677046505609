export default function authorizedGetHeaders(token) {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-access-token': `${token}`
    }
  };
}
