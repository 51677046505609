import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { toastr } from 'react-redux-toastr';
import Moment from 'react-moment';
import moment from 'moment';

import { placeholder } from '../../Assets';
import BorderedModal from '../../Components/BorderedModal';
import InputField from '../../Components/InputField';
import DropZone from '../../Pages/DropZone';
import DropZoneRequest from '../../Pages/DropZoneRequest';
import FrontHeader from '../../Components/FrontHeader';
import Timer from '../../Components/Timer';
import Spinner from '../../Components/Spinner';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import AuthorizedPutHeaders from '../../Configs/AuthorizedPutHeaders';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import {
  filterSkills,
  filterServices,
  filterTimeZone
} from '../../helpers/filterCommon';
import autoCap from '../../helpers/autoCap';
import {
  updateGeneralDetails,
  fetchExpertProfile,
  fetchSkills,
  fetchServices,
  fetchIndustries,
  clearStatus,
  createAcheivement,
  createPublication,
  updateExpertIndustry,
  createTimings
} from '../../actions';

import { useForm } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';

export default function ExpertProfile() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [publicationMedia, setPublicationMedia] = useState(null);
  const [showOrgFile, setShowOrgFile] = useState(false);
  const [showSkills, setShowSkills] = useState(false);
  const [showAcheivement, setAcheivement] = useState(false);
  const [showPublication, setShowPublication] = useState(false);
  const [showCalendly, setShowCalendly] = useState(false);
  const [showLinkedin, setShowLinkedin] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showContact, setContact] = useState(false);
  const [showEditProfile, setEditProfile] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [openDate, setOpenDate] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [addTimings, setAddTimings] = useState([]);
  const [prevTimings, prevAddTimings] = useState([]);
  const [showCvFile, setShowCvFile] = useState(false);
  const [showStartTime, setShowStartTime] = useState(false);
  const [showEndTime, setShowEndTime] = useState(false);
  const [showEditAcheivement, setEditAcheivement] = useState(false);
  const [editAcheivementMode, setEditAcheivementMode] = useState(null);
  const [editPublicationMode, setEditPublicationMode] = useState(null);
  const [singleAchievement, setSingleAchievement] = useState(null);
  const [showEditPublication, setEditPublication] = useState(false);
  const [singlePublication, setSinglePublication] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewOrgFile, setPreviewOrgFile] = useState(null);
  const [orgCvMedia, setOrgCvMedia] = useState(null);

  const [industry, setIndustry] = useState(null);
  const [skillText, setSkillText] = useState(null);
  const [serviceText, setServiceText] = useState(null);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [showOtherDiv, setShowOtherDiv] = useState(false);
  const [addNewSkill, setAddNewSkill] = useState(null);
  const [showOtherServiceDiv, setShowOtherServiceDiv] = useState(false);
  const [addNewService, setAddNewService] = useState(null);
  const [seletedTimeZone, setTimeZone] = useState(null);
  const [globalTimeZones, setGlobalTimeZones] = useState([]);

  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  const [fileDetails, setFileDetails] = useState(null);
  const [orgFileDetails, setOrgFileDetails] = useState(null);

  const expertStateSelector = useSelector(state => state.expert);
  const token = useSelector(state => state.auth.token);
  const commonSelector = useSelector(state => state.common);

  const singleUserData = expertStateSelector.userProfile;
  const { register, handleSubmit, errors } = useForm();
  useEffect(() => {
    if (token) {
      dispatch(fetchExpertProfile());

      dispatch(fetchSkills(token));

      dispatch(fetchServices(token));

      dispatch(fetchIndustries(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    setTimeout(() => setFileDetails(previewFile), 1000);
  }, [previewFile]);

  useEffect(() => {
    setTimeout(() => setOrgFileDetails(previewOrgFile), 1000);
  }, [previewOrgFile]);

  useEffect(() => {
    if (
      expertStateSelector.state === 'loading' &&
      expertStateSelector.mode === 'fetch_expert'
    ) {
      setShow(false);
      setShowFile(false);
      setPublicationMedia(false);
      setShowOrgFile(false);
      setShowSkills(false);
      setAcheivement(false);
      setShowPublication(false);
      setShowCalendly(false);
      setShowLinkedin(false);
      setPreviewOrgFile(false);
      setPreviewFile(false);
      setContact(false);
      setEditProfile(false);
      setAddTimings([]);
      setFilteredServices([]);
      setFilteredSkills([]);
      setAddNewSkill(null);
      setSkillText(null);
      setServiceText(null);
      setShowOtherDiv(false);
      setShowOtherServiceDiv(false);
      setShowCvFile(false);
      setEditAcheivement(false);
      setEditPublication(false);
    }
  }, [expertStateSelector.mode, expertStateSelector.state]);

  useEffect(() => {
    if (Object.keys(singleUserData).length > 0) {
      if (singleUserData.ExpertProfile.ExpertIndustry !== null) {
        setIndustry(singleUserData.ExpertProfile.ExpertIndustry.Industry.id);
      }

      let skillStructure = [];
      singleUserData.ExpertProfile.ExpertSkill.map(skill =>
        skillStructure.push(skill.Skill)
      );
      if (skillStructure.length > 0) {
        setSelectedSkills(skillStructure);
      }
      let industryStructure = [];
      singleUserData.ExpertProfile.ExpertService.map(service =>
        industryStructure.push(service.Service)
      );
      if (industryStructure.length > 0) {
        setSelectedServices(industryStructure);
      }

      let timeStructure = [];
      singleUserData.ExpertProfile.TimeAvailability.map(time =>
        timeStructure.push({
          id: time.id,
          date: time.date,
          startTime: time.startTime,
          endTime: time.endTime,
          timeZoneId: time.TimeZone,
          isRecursive: time.isRecursive
        })
      );

      prevAddTimings(timeStructure);
    }
  }, [singleUserData]);
  useEffect(() => {
    if (commonSelector.skills.length > 0) {
      setSkills(commonSelector.skills);
    }
  }, [commonSelector.skills.length]);

  useEffect(() => {
    if (commonSelector.services.length > 0) {
      setServices(commonSelector.services);
    }
  }, [commonSelector.services.length]);

  useEffect(() => {
    const requestUrl = `${apiUrl}/timezone`;
    const options = AuthorizedGetHeaders(token);

    fetch(requestUrl, options)
      .then(response => response.json())
      .then(data => {
        setGlobalTimeZones(data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const updateRecurringHanlder = (id, isRecursive) => {
    const requestUrl = `${apiUrl}/expertProfile/updateTimeAvailability?id=${id}&isRecursive=${isRecursive}`;
    const options = AuthorizedPutHeaders(
      {
        id,
        isRecursive
      },
      token
    );

    fetch(requestUrl, options)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          dispatch(fetchExpertProfile());
          toastr.success('Success!', 'Record updated.');
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onChangeSkillHandler = ev => {
    if (skills.length > 0) {
      setSkillText(ev.target.value);
      let fSkills = filterSkills(ev.target.value, skills);

      setFilteredSkills(fSkills);
    }
  };

  const selectSkillHanlder = skill => {
    var skillIndex = selectedSkills.findIndex(x => x.id == skill.id);
    if (skillIndex === -1) {
      if (skill.title === 'Others') {
        setShowOtherDiv(true);
      } else {
        setSelectedSkills([...selectedSkills, skill]);
      }
    }
  };

  const selectServicesHanlder = service => {
    var serviceIndex = selectedServices.findIndex(x => x.id == service.id);
    if (serviceIndex === -1) {
      if (service.title === 'Others') {
        setShowOtherServiceDiv(true);
      } else {
        setSelectedServices([...selectedServices, service]);
      }
    }
  };

  const removeSkillHanlder = skill => {
    let filteredSkillData = selectedSkills.filter(el => el.id !== skill.id);
    setSelectedSkills(filteredSkillData);
  };
  const addNewSkillHandler = () => {
    const requestUrl = `${apiUrl}/skills/createSkill`;
    const options = AuthorizedPostHeaders(
      {
        title: addNewSkill
      },
      token
    );

    fetch(requestUrl, options)
      .then(response => response.json())
      .then(data => {
        let responseData = {
          id: data.data.id,
          title: data.data.title,
          isActive: data.data.isActive
        };
        var skillIndex = selectedSkills.findIndex(x => x.id == responseData.id);
        if (skillIndex === -1) {
          setSelectedSkills([...selectedSkills, responseData]);
          // setAddNewSkill(null);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  // Services

  const addNewServiceHandler = () => {
    const requestUrl = `${apiUrl}/services/createService`;
    const options = AuthorizedPostHeaders(
      {
        title: addNewService
      },
      token
    );

    fetch(requestUrl, options)
      .then(response => response.json())
      .then(data => {
        let responseData = {
          id: data.data.id,
          title: data.data.title,
          isActive: data.data.isActive
        };
        var serviceIndex = selectedServices.findIndex(
          x => x.id == responseData.id
        );
        if (serviceIndex === -1) {
          setSelectedServices([...selectedServices, responseData]);
          // setAddNewSkill(null);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onChangeServicesHandler = ev => {
    setServiceText(ev.target.value);
    if (services.length > 0) {
      let fServices = filterServices(ev.target.value, services);
      setFilteredServices(fServices);
    }
  };

  // const selectServicesHanlder = service => {
  //   var serviceIndex = selectedServices.findIndex(x => x.id == service.id);
  //   if (serviceIndex === -1) {
  //     setSelectedServices([...selectedServices, service]);
  //   }
  // };

  const removeServicesHanlder = service => {
    let filteredServiceData = selectedServices.filter(
      el => el.id !== service.id
    );
    setSelectedServices(filteredServiceData);
  };

  const onSubmitSkills = () => {
    const data = {
      industry,
      selectedSkills,
      selectedServices
    };
    dispatch(updateExpertIndustry(data));
  };

  const handleClose = () => {
    setShow(false);
    setPreviewOrgFile(null);
    setOrgFileDetails(null);
    dispatch(clearStatus());
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleFileClose = () => {
    setShowFile(false);
    setPreviewFile(null);
    dispatch(clearStatus());
  };

  const handleFileShow = () => {
    setShowFile(true);
  };

  const handleOrgFileShow = () => {
    setShowOrgFile(true);
  };

  const handleOrgFileClose = () => {
    setShowOrgFile(false);

    dispatch(clearStatus());
  };

  const handleCvFileShow = () => {
    setShow(false);
    setShowCvFile(true);
  };

  const handleCvFileClose = () => {
    setShowCvFile(false);
    dispatch(clearStatus());
  };

  const handleSkillsClose = () => {
    setShowSkills(false);
    setFilteredSkills([]);
    setFilteredServices([]);
    setSkillText(null);
    setServiceText(null);
    dispatch(clearStatus());
  };
  const handleSkillsShow = () => {
    setShowSkills(true);
  };
  const handlePublicationClose = () => {
    setShowPublication(false);
    dispatch(clearStatus());
  };
  const handlePublicationShow = () => {
    setShowPublication(true);
  };
  const handleCalendlyClose = () => {
    setShowCalendly(false);
    dispatch(clearStatus());
  };
  const handleCalendlyShow = () => {
    setShowCalendly(true);
  };

  const handleLinkedinClose = () => {
    setShowLinkedin(false);
    dispatch(clearStatus());
  };
  const handleLinkedinShow = () => {
    setShowLinkedin(true);
  };

  const handleTimeClose = () => {
    setShowTime(false);
    dispatch(clearStatus());
  };
  const handleTimeShow = () => {
    setShowTime(true);
  };

  const handleContactClose = () => {
    setContact(false);
    dispatch(clearStatus());
  };
  const handleContactShow = () => {
    setContact(true);
  };
  const handleAcheivementClose = () => {
    setAcheivement(false);
    dispatch(clearStatus());
  };

  const handleAcheivementShow = () => {
    setAcheivement(true);
  };

  const handleEditAcheivementClose = () => {
    setEditAcheivement(false);
    setSingleAchievement(null);
    setEditAcheivementMode(null);
    dispatch(clearStatus());
  };

  const handleEditAcheivementShow = (userAcheivement, mode) => {
    if (userAcheivement) {
      setSingleAchievement(userAcheivement);
      setEditAcheivementMode(mode);
    } else {
      setSingleAchievement(null);
      setEditAcheivementMode(null);
    }
    setEditAcheivement(true);
  };

  const handleEditPublicationClose = () => {
    setEditPublication(false);
    setSinglePublication(null);
    setEditPublicationMode(null);
    dispatch(clearStatus());
  };

  const handleEditPublicationShow = (userPublication, mode) => {
    if (userPublication) {
      setSinglePublication(userPublication);
      setEditPublicationMode(mode);
    } else {
      setSinglePublication(null);
      setEditPublicationMode(null);
    }
    setEditPublication(true);
  };

  const handleEditProfileShow = () => {
    setEditProfile(true);
  };

  const handleEditProfileClose = () => {
    setEditProfile(false);
    setPreviewFile(null);
    setFileDetails(null);
    dispatch(clearStatus());
  };

  const onChangeStartTime = startTime => {
    setStartTime(startTime);
    setShowStartTime(false);
  };

  const onChangeEndTime = endTime => {
    setEndTime(endTime);
    setShowEndTime(false);
  };
  const renderProfile = expert => {
    return (
      <>
        <div className='m-body'>
          {Object.keys(expert.ExpertProfile).length === 0 ? (
            <Spinner color='#000' />
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmitGeneralDetails)}>
                <div className='mod-img'>
                  <div className='img-download'>
                    {previewOrgFile ? (
                      <img
                        className='img-thumbnail'
                        src={
                          orgFileDetails && orgFileDetails.preview
                            ? orgFileDetails.preview
                            : placeholder
                        }
                        alt={previewOrgFile.name}
                        className='expertImg roundImg'
                      />
                    ) : (
                      <img
                        src={
                          expert.ExpertProfile.organizationImageUrl
                            ? apiUrl +
                              '/' +
                              expert.ExpertProfile.organizationImageUrl
                            : placeholder
                        }
                        className='expertImg roundImg'
                        alt='User'
                      />
                    )}

                    <div
                      className='edit-icon'
                      onClick={() => handleOrgFileShow()}
                    >
                      <i title='Edit Image' className='fas fa-camera'></i>
                    </div>
                  </div>
                </div>

                <InputField
                  style={{ background: '#F1F1F1', fontSize: 13 }}
                  keyError={errors}
                  name='organization'
                  type='text'
                  value={expert.ExpertProfile.organization}
                  keyRef={register({ required: true })}
                  placeholder='Organization'
                />
                {errors.organization && (
                  <span className='error'>Organization is required</span>
                )}

                <InputField
                  style={{ background: '#F1F1F1', fontSize: 13 }}
                  keyError={errors}
                  name='year'
                  type='text'
                  value={expert.ExpertProfile.year}
                  keyRef={register({ required: true })}
                  placeholder='Year'
                />
                {errors.year && <span className='error'>Year is required</span>}

                <InputField
                  style={{ background: '#F1F1F1', fontSize: 13 }}
                  keyError={errors}
                  name='role'
                  type='text'
                  value={expert.ExpertProfile.role}
                  keyRef={register({ required: true })}
                  placeholder='Role'
                />
                {errors.role && <span className='error'>Role is required</span>}

                <div className='form-group'>
                  <textarea
                    placeholder='Only 300 characters allowed'
                    maxLength='300'
                    rows='5'
                    name='description'
                    ref={register}
                    className='form-control custom-input'
                    style={{
                      background: '#F1F1F1',
                      color: 'black',
                      fontSize: 13
                    }}
                  >
                    {expert.ExpertProfile.description}
                  </textarea>
                </div>

                {/* <div className='file-details-upload'>
                  <div
                    className='btn fileBtn'
                    onClick={() => handleCvFileShow()}
                  >
                    <i
                      className='fas fa-cloud-upload-alt'
                      style={{
                        color: '#fff',
                        marginRight: 5,
                        marginLeft: '-8px',
                        fontSize: 20
                      }}
                    ></i>
                    Choose
                  </div>

                  <small style={{ marginLeft: 20 }}>No File Selected</small>
                </div> */}
                <DropZoneRequest
                  state={expertStateSelector.state}
                  mode={expertStateSelector.mode}
                  onUploadMedia={onUploadCv}
                  requestSelector={expertStateSelector}
                />

                <div className='adminNotes'>
                  <button
                    disabled={
                      expertStateSelector.state === 'loading' &&
                      expertStateSelector.mode === 'update_organization'
                        ? true
                        : false
                    }
                    className='btn custom-adm-btn'
                    type='submit'
                  >
                    {expertStateSelector.state === 'loading' &&
                    expertStateSelector.mode === 'update_organization' ? (
                      <Spinner cWdith={20} cHeight={20} />
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </>
    );
  };

  const renderEditProfile = expert => {
    return (
      <>
        <div className='m-body' style={{ padding: 0 }}>
          {Object.keys(expert.ExpertProfile).length === 0 ? (
            <Spinner color='#000' />
          ) : (
            <>
              <div className='img-profile-p'>
                <div className='expertBanner' style={{ height: 120 }}></div>

                {/* <div
                  className='edit-icon'
                  style={{ right: 20, top: 15 }}
                  onClick={() => handleFileShow()}
                >
                  <i title='Edit Image' className='fas fa-camera'></i>
                </div> */}
              </div>
              <div
                className='mod-img'
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginLeft: 30,
                  marginTop: -70
                }}
              >
                <div className='img-download'>
                  {previewFile ? (
                    <img
                      className='img-thumbnail'
                      src={
                        fileDetails && fileDetails.preview
                          ? fileDetails.preview
                          : placeholder
                      }
                      alt={previewFile.name}
                      className='expertImg roundImg'
                    />
                  ) : (
                    <img
                      src={
                        expert.ExpertProfile.profileImageUrl
                          ? apiUrl + '/' + expert.ExpertProfile.profileImageUrl
                          : placeholder
                      }
                      className='expertImg roundImg'
                      alt='Expert'
                    />
                  )}

                  <div
                    className='edit-icon'
                    style={{ bottom: 5 }}
                    onClick={() => handleFileShow()}
                  >
                    <i title='Edit Image' className='fas fa-camera'></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmitGeneralInfo)}>
                <div style={{ padding: 20 }}>
                  <div className='row cRow'>
                    <div className='col-md-6'>
                      <InputField
                        style={{ background: '#F1F1F1', fontSize: 13 }}
                        keyError={errors}
                        name='firstName'
                        type='text'
                        value={expert.firstName}
                        keyRef={register}
                        placeholder='First Name'
                      />
                    </div>
                    <div className='col-md-6'>
                      <InputField
                        style={{ background: '#F1F1F1', fontSize: 13 }}
                        keyError={errors}
                        name='lastName'
                        type='text'
                        value={expert.lastName}
                        keyRef={register}
                        placeholder='Last Name'
                      />
                    </div>
                  </div>
                  <div className='row cRow'>
                    <div className='col-md-12'>
                      <InputField
                        style={{ background: '#F1F1F1', fontSize: 13 }}
                        keyError={errors}
                        name='headline'
                        type='text'
                        value={expert.ExpertProfile.headline}
                        keyRef={register({ required: true })}
                        placeholder='Headline'
                      />
                      {errors.headline && (
                        <span className='error'>Headline is required</span>
                      )}
                    </div>
                  </div>
                  <div className='row cRow'>
                    <div className='col-md-12'>
                      <div className='adminNotes'>
                        <button
                          disabled={
                            expertStateSelector.state === 'loading' &&
                            expertStateSelector.mode === 'update_general_info'
                              ? true
                              : false
                          }
                          className='btn custom-adm-btn'
                          type='submit'
                        >
                          {expertStateSelector.state === 'loading' &&
                          expertStateSelector.mode === 'update_general_info' ? (
                            <Spinner cWdith={20} cHeight={20} />
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </>
    );
  };

  const renderLinkedin = expert => {
    return (
      <>
        <div className='m-body'>
          {Object.keys(expert.ExpertProfile).length === 0 ? (
            <Spinner color='#000' />
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmitLinkedIn)}>
                <div style={{ minHeight: 200 }}>
                  <InputField
                    style={{ background: '#F1F1F1', fontSize: 13 }}
                    keyError={errors}
                    name='linkedInProfileUrl'
                    type='text'
                    value={expert.ExpertProfile.linkedInProfileUrl}
                    keyRef={register}
                    placeholder='Enter LinkedIn Profile'
                  />
                </div>
                <div className='adminNotes'>
                  <button
                    disabled={
                      expertStateSelector.state === 'loading' &&
                      expertStateSelector.mode === 'update_lindkedIn'
                        ? true
                        : false
                    }
                    className='btn custom-adm-btn'
                    type='submit'
                  >
                    {expertStateSelector.state === 'loading' &&
                    expertStateSelector.mode === 'update_lindkedIn' ? (
                      <Spinner cWdith={20} cHeight={20} />
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </>
    );
  };

  const renderCalendly = expert => {
    return (
      <>
        <div className='m-body'>
          {Object.keys(expert.ExpertProfile).length === 0 ? (
            <Spinner color='#000' />
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmitCalendly)}>
                <div style={{ minHeight: 200 }}>
                  <InputField
                    style={{ background: '#F1F1F1', fontSize: 13 }}
                    keyError={errors}
                    name='meetingUrl'
                    type='text'
                    value={expert.ExpertProfile.meetingUrl}
                    keyRef={register}
                    placeholder='Calendly Link Here'
                  />
                </div>
                <div className='adminNotes'>
                  <button
                    disabled={
                      expertStateSelector.state === 'loading' &&
                      expertStateSelector.mode === 'update_calendly'
                        ? true
                        : false
                    }
                    className='btn custom-adm-btn'
                    type='submit'
                  >
                    {expertStateSelector.state === 'loading' &&
                    expertStateSelector.mode === 'update_calendly' ? (
                      <Spinner cWdith={20} cHeight={20} />
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </>
    );
  };

  const renderContact = expert => {
    return (
      <>
        <div className='m-body'>
          {Object.keys(expert.ExpertProfile).length === 0 ? (
            <Spinner color='#000' />
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmitContact)}>
                <div style={{ minHeight: 200 }}>
                  <InputField
                    style={{ background: '#F1F1F1', fontSize: 13 }}
                    keyError={errors}
                    name='phone'
                    type='text'
                    value={expert.ExpertProfile.phone}
                    keyRef={register({ required: true })}
                    placeholder='Phone'
                  />
                  {errors.phone && (
                    <span className='error'>Phone is required</span>
                  )}
                  <InputField
                    style={{ background: '#F1F1F1', fontSize: 13 }}
                    keyError={errors}
                    name='email'
                    type='text'
                    value={expert.ExpertProfile.email}
                    keyRef={register({ required: true })}
                    placeholder='Email'
                  />
                  {errors.email && (
                    <span className='error'>Email is required</span>
                  )}
                  <InputField
                    style={{ background: '#F1F1F1', fontSize: 13 }}
                    keyError={errors}
                    name='mobile'
                    type='text'
                    value={expert.ExpertProfile.mobile}
                    keyRef={register({ required: true })}
                    placeholder='Mobile'
                  />
                  {errors.mobile && (
                    <span className='error'>Mobile is required</span>
                  )}
                </div>
                <div className='adminNotes'>
                  <button
                    disabled={
                      expertStateSelector.state === 'loading' &&
                      expertStateSelector.mode === 'update_contact'
                        ? true
                        : false
                    }
                    className='btn custom-adm-btn'
                    type='submit'
                  >
                    {expertStateSelector.state === 'loading' &&
                    expertStateSelector.mode === 'update_contact' ? (
                      <Spinner cWdith={20} cHeight={20} />
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </>
    );
  };

  const renderSkills = () => {
    return (
      <>
        <div className='m-body'>
          <div className='form-group'>
            <select
              defaultValue={industry}
              className='form-control custom-input-filter'
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '16px',
                color: '#797b8f',
                borderColor: '#f1f1f1',
                background: '#F1F1F1'
              }}
              onChange={e => setIndustry(e.target.value)}
            >
              <option value='option-1'>Industry</option>
              {commonSelector.industries.length > 0 &&
                commonSelector.industries.map(industry => (
                  <option value={industry.id} key={`ind-${industry.id}`}>
                    {industry.name}
                  </option>
                ))}
            </select>
            <i
              className='fa fa-caret-down'
              style={{ background: 'transparent' }}
            ></i>
          </div>
          <InputField
            onChange={onChangeSkillHandler}
            value={skillText}
            style={{ background: '#F1F1F1', fontSize: 13 }}
            placeholder='Skills'
          />
          {selectedSkills.length > 0 && (
            <>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selectedSkills.map(sSkill => (
                  <span
                    key={`sKill-${sSkill.id}`}
                    className='btn skillDiv'
                    style={{
                      marginBottom: 10
                    }}
                    onClick={() => removeSkillHanlder(sSkill)}
                  >
                    {sSkill.title}
                    <i
                      className='far fa-trash-alt'
                      style={{ marginLeft: 10 }}
                    ></i>
                  </span>
                ))}
              </div>
            </>
          )}
          {showOtherDiv && (
            <div className='input-search' style={{ marginBottom: 10 }}>
              <input
                className='form-control custom-input-search'
                style={{
                  background: '#F1F1F1',
                  color: 'black',
                  fontSize: 13
                }}
                onChange={ev => setAddNewSkill(ev.target.value)}
              />
              <button
                onClick={() => addNewSkillHandler()}
                className='btn custom-btn-search'
              >
                ADD
              </button>
            </div>
          )}

          {filteredSkills.length > 0 && (
            <>
              <p style={{ marginBottom: 10 }}>
                Suggested Skills based off your profile
              </p>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {filteredSkills.map(fSkill => (
                  <span
                    key={`fSkill-${fSkill.id}`}
                    className='btn skillDiv'
                    onClick={() => selectSkillHanlder(fSkill)}
                  >
                    <i className='fas fa-plus skill-icon'></i>
                    {fSkill.title}
                  </span>
                ))}
              </div>
            </>
          )}

          {/* Services */}
          <InputField
            onChange={onChangeServicesHandler}
            value={serviceText}
            style={{ background: '#F1F1F1', fontSize: 13 }}
            placeholder='Services'
          />
          {selectedServices.length > 0 && (
            <>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selectedServices.map(sService => (
                  <span
                    key={`sService-${sService.id}`}
                    className='btn skillDiv'
                    style={{
                      marginBottom: 10
                    }}
                    onClick={() => removeServicesHanlder(sService)}
                  >
                    {sService.title}
                    <i
                      className='far fa-trash-alt'
                      style={{ marginLeft: 10 }}
                    ></i>
                  </span>
                ))}
              </div>
            </>
          )}

          {showOtherServiceDiv && (
            <div className='input-search' style={{ marginBottom: 10 }}>
              <input
                className='form-control custom-input-search'
                style={{
                  background: '#F1F1F1',
                  color: 'black',
                  fontSize: 13
                }}
                onChange={ev => setAddNewService(ev.target.value)}
              />
              <button
                onClick={() => addNewServiceHandler()}
                className='btn custom-btn-search'
              >
                ADD
              </button>
            </div>
          )}

          {filteredServices.length > 0 && (
            <>
              <p style={{ marginBottom: 10 }}>
                Suggested Services based off your profile
              </p>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {filteredServices.map(fService => (
                  <span
                    key={`fService-${fService.id}`}
                    className='btn skillDiv'
                    onClick={() => selectServicesHanlder(fService)}
                  >
                    <i className='fas fa-plus skill-icon'></i>
                    {fService.title}
                  </span>
                ))}
              </div>
            </>
          )}
          {/* End Services */}

          <div className='adminNotes'>
            <button
              onClick={() => onSubmitSkills()}
              disabled={
                expertStateSelector.state === 'loading' &&
                expertStateSelector.mode === 'update_industry'
                  ? true
                  : false
              }
              className='btn custom-adm-btn'
            >
              {expertStateSelector.state === 'loading' &&
              expertStateSelector.mode === 'update_industry' ? (
                <Spinner cWdith={20} cHeight={20} />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderPubilcations = () => {
    return (
      <>
        <div className='m-body'>
          <form onSubmit={handleSubmit(onSubmitPublication)}>
            <InputField
              style={{ background: '#F1F1F1', fontSize: 13 }}
              keyError={errors}
              name='title'
              type='text'
              value=''
              keyRef={register({ required: true })}
              placeholder='Title'
            />
            {errors.title && <span className='error'>Title is required</span>}

            <div className='form-group'>
              <textarea
                placeholder='Only 300 characters allowed'
                maxLength='300'
                rows='5'
                name='description'
                ref={register}
                className='form-control custom-input'
                style={{ background: '#F1F1F1', color: 'black', fontSize: 13 }}
              >
                {/* {expert.ExpertProfile.description} */}
              </textarea>
            </div>

            <DropZone
              state={expertStateSelector.state}
              mode={expertStateSelector.mode}
              onUploadMedia={onUploadPublication}
              type='simple'
            />

            <div className='adminNotes'>
              <button
                disabled={
                  expertStateSelector.state === 'loading' &&
                  expertStateSelector.mode === 'create_publication'
                    ? true
                    : false
                }
                className='btn custom-adm-btn'
                type='submit'
              >
                {expertStateSelector.state === 'loading' &&
                expertStateSelector.mode === 'create_publication' ? (
                  <Spinner cWdith={20} cHeight={20} />
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  };

  const renderEditPubilcations = singlePublication => {
    return (
      <>
        <div className='m-body'>
          <form onSubmit={handleSubmit(onSubmitEditPublication)}>
            <InputField
              style={{ background: '#F1F1F1', fontSize: 13 }}
              keyError={errors}
              name='id'
              type='hidden'
              value={singlePublication ? singlePublication.id : ''}
              keyRef={register}
              placeholder='id'
            />
            {editPublicationMode === 'edit' ? (
              <>
                <InputField
                  style={{ background: '#F1F1F1', fontSize: 13 }}
                  keyError={errors}
                  name='title'
                  type='text'
                  value={singlePublication ? singlePublication.title : ''}
                  keyRef={register({ required: true })}
                  placeholder='Title'
                />
                {errors.title && (
                  <span className='error'>Title is required</span>
                )}

                <div className='form-group'>
                  <textarea
                    placeholder='Only 300 characters allowed'
                    maxLength='300'
                    rows='5'
                    name='description'
                    ref={register}
                    className='form-control custom-input'
                    style={{
                      background: '#F1F1F1',
                      color: 'black',
                      fontSize: 13
                    }}
                  >
                    {singlePublication ? singlePublication.description : ''}
                  </textarea>
                </div>

                <DropZone
                  state={expertStateSelector.state}
                  mode={expertStateSelector.mode}
                  onUploadMedia={onUploadPublication}
                  type='simple'
                />
              </>
            ) : (
              <h6>Are you sure you want to delete.</h6>
            )}

            <div className='adminNotes'>
              <button
                disabled={
                  expertStateSelector.state === 'loading' &&
                  expertStateSelector.mode === 'update_publication'
                    ? true
                    : false
                }
                className='btn custom-adm-btn'
                type='submit'
              >
                {expertStateSelector.state === 'loading' &&
                expertStateSelector.mode === 'update_publication' ? (
                  <Spinner cWdith={20} cHeight={20} />
                ) : editPublicationMode === 'edit' ? (
                  'Update'
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  };

  const startRef = useRef();
  const onOnDatepickerHandler = () => {
    setOpenDate(!openDate);
    setShowStartTime(false);
    setShowEndTime(false);
    startRef.current.setOpen(!openDate);
  };

  const dayGetter = date => {
    var singleDay = moment(date, 'YYYY-MM-DD');
    var dayName = singleDay.format('dddd');
    return dayName;
  };

  const handleAddTimings = () => {
    let timezoneSet = seletedTimeZone;
    let startDateObj = startDate;
    // setTimeZone(null);
    if (startTime && endTime) {
      if (startTime > endTime) {
        alert('End time should be greater than start time');
      } else {
        setAddTimings([
          ...addTimings,
          {
            date: startDateObj,
            startTime: startTime,
            endTime: endTime,
            timeZoneId: timezoneSet
          }
        ]);
      }
    }
  };

  const removeAddTimings = index => {
    const list = [...addTimings];
    list.splice(index, 1);
    setAddTimings(list);
  };

  const onStartTimeChange = () => {
    setShowStartTime(!showStartTime);
    setShowEndTime(false);
  };

  const onEndTimeChange = () => {
    setShowStartTime(false);
    setShowEndTime(!showEndTime);
  };

  const onSubmitAddTimings = () => {
    let timingsSubmit = [];
    addTimings.map(timing => {
      const obj = {
        date: moment(timing.date, 'MM-DD-YYYY').format('MM-DD-YYYY'), //startDate,
        startTime: timing.startTime,
        endTime: timing.endTime,
        timeZoneId: timing.timeZoneId.id
      };

      timingsSubmit.push(obj);
    });
    // console.log(timingsSubmit, 'timings to be submit');

    dispatch(createTimings(timingsSubmit));
  };

  const renderTime = () => {
    return (
      <>
        <div className='m-body' style={{ minHeight: 400 }}>
          <div className='cal-p'>
            <div className='cal-p-l'>
              <div className='cal'>
                <div>
                  <i className='fas fa-chevron-left'></i>
                </div>
                <div>
                  <span>
                    {dayGetter(startDate) + ', '}
                    <Moment format='DD MMMM, YYYY'>{startDate}</Moment>
                  </span>
                </div>
                <div>
                  <i className='fas fa-chevron-right'></i>
                </div>
              </div>
            </div>

            <div className='cal-p-r'>
              <div
                style={{
                  background: 'white',
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => onOnDatepickerHandler()}
              >
                <i className='fas fa-calendar' style={{ fontSize: 15 }}></i>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <DatePicker
              ref={startRef}
              setOpen={openDate}
              selected={startDate}
              onChange={date => setStartDate(date)}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>Select timezone</label>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {globalTimeZones.length > 0 &&
                globalTimeZones.map(timeZone => {
                  return (
                    <span
                      key={`timezone-${timeZone.id}`}
                      className={`btn skillDiv ${
                        seletedTimeZone && seletedTimeZone.id === timeZone.id
                          ? 'skillDivActive'
                          : ''
                      }`}
                      onClick={() => setTimeZone(timeZone)}
                    >
                      <i
                        className={`fas fa-plus skill-icon ${
                          seletedTimeZone && seletedTimeZone.id === timeZone.id
                            ? 'skill-icon-active'
                            : ''
                        }`}
                      ></i>
                      {timeZone.title}
                    </span>
                  );
                })}
            </div>
          </div>

          <div className='time-p'>
            <div className='time-p-l'>
              <div className='timings-t' onClick={() => onStartTimeChange()}>
                <div>
                  {startTime
                    ? moment(startTime, 'hh:mm').format('hh:mm A')
                    : 'Start Time'}
                </div>

                <div>
                  <i className='far fa-clock' style={{ fontSize: 15 }}></i>
                </div>
              </div>
              {showStartTime && (
                <div className='timer-p'>
                  <Timer onChangeTime={onChangeStartTime} time={startTime} />
                </div>
              )}
            </div>
            <div className='time-p-m'>
              <div className='timings-t' onClick={() => onEndTimeChange()}>
                <div>
                  {endTime
                    ? moment(endTime, 'hh:mm').format('hh:mm A')
                    : 'End Time'}
                </div>

                <div>
                  <i className='far fa-clock' style={{ fontSize: 15 }}></i>
                </div>
              </div>
              {showEndTime && (
                <div className='timer-p'>
                  <Timer onChangeTime={onChangeEndTime} time={endTime} />
                </div>
              )}
            </div>
            <div className='time-p-r' onClick={() => handleAddTimings()}>
              <i className='fas fa-plus'></i>
            </div>
          </div>

          <div className='timings-parent'>
            {prevTimings.length > 0 &&
              prevTimings.map((time, index) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div className='timings' key={`prevTime-${time.date}`}>
                      <div>
                        <small>
                          {dayGetter(time.date) + ', '}
                          <Moment format='DD MMMM, YYYY'>{time.date}</Moment>,
                          {moment(time.startTime, 'hh:mm').format('hh:mm A')} -{' '}
                          {moment(time.endTime, 'hh:mm').format('hh:mm A')} -{' '}
                          {time.timeZoneId.title}
                        </small>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <i
                          className={`fas fa-redo-alt ${
                            time.isRecursive ? 'active-rec' : ''
                          }`}
                          onClick={() =>
                            updateRecurringHanlder(
                              time.id.toString(),
                              !time.isRecursive
                            )
                          }
                        ></i>
                      </div>
                    </div>
                    <div
                      onClick={() => onSubmitDeleteTimings(time)}
                      style={{ marginTop: '-20px' }}
                    >
                      <i className='fas fa-trash'></i>
                    </div>
                  </div>
                </>
              ))}

            {addTimings.length > 0 &&
              addTimings.map((time, index) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                    key={`addtimings-${index}`}
                  >
                    <div className='timings' key={`time-${time.date}`}>
                      <div>
                        <small>
                          {dayGetter(time.date) + ', '}
                          <Moment format='DD MMMM, YYYY'>{time.date}</Moment>,
                          {moment(time.startTime, 'hh:mm').format('hh:mm A')} -{' '}
                          {moment(time.endTime, 'hh:mm').format('hh:mm A')} -{' '}
                          {time.timeZoneId && time.timeZoneId.title}
                        </small>
                      </div>
                    </div>
                    <div
                      onClick={() => removeAddTimings(index)}
                      style={{ marginTop: '-20px' }}
                    >
                      <i className='fas fa-trash'></i>
                    </div>
                  </div>
                </>
              ))}
          </div>

          <div className='adminNotes'>
            <button
              disabled={
                expertStateSelector.state === 'loading' &&
                expertStateSelector.mode === 'create_timings'
                  ? true
                  : addTimings.length > 0
                  ? false
                  : true
              }
              className='btn custom-adm-btn'
              style={{ marginTop: 10 }}
              onClick={() => onSubmitAddTimings()}
            >
              {expertStateSelector.state === 'loading' &&
              expertStateSelector.mode === 'create_timings' ? (
                <Spinner cWdith={20} cHeight={20} />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderAcheivement = () => {
    return (
      <>
        <div className='m-body'>
          <form onSubmit={handleSubmit(onSubmitAcheivement)}>
            <InputField
              style={{ background: '#F1F1F1', fontSize: 13 }}
              keyError={errors}
              name='title'
              type='text'
              value=''
              keyRef={register({ required: true })}
              placeholder='Title'
            />
            {errors.title && <span className='error'>Title is required</span>}

            <div className='form-group'>
              <textarea
                placeholder='Only 300 characters allowed'
                maxLength='300'
                rows='5'
                name='description'
                ref={register}
                className='form-control custom-input'
                style={{ background: '#F1F1F1', color: 'black', fontSize: 13 }}
              ></textarea>
            </div>
            <div className='adminNotes'>
              <button
                disabled={
                  expertStateSelector.state === 'loading' &&
                  expertStateSelector.mode === 'create_acheivement'
                    ? true
                    : false
                }
                className='btn custom-adm-btn'
                type='submit'
              >
                {expertStateSelector.state === 'loading' &&
                expertStateSelector.mode === 'create_acheivement' ? (
                  <Spinner cWdith={20} cHeight={20} />
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  };

  const renderEditAcheivement = singleAcheivment => {
    return (
      <>
        <div className='m-body'>
          <form onSubmit={handleSubmit(onSubmitEditAcheivement)}>
            <InputField
              style={{ background: '#F1F1F1', fontSize: 13 }}
              keyError={errors}
              name='id'
              type='hidden'
              value={singleAcheivment ? singleAcheivment.id : ''}
              keyRef={register}
              placeholder='id'
            />
            {editAcheivementMode === 'edit' ? (
              <>
                <InputField
                  style={{ background: '#F1F1F1', fontSize: 13 }}
                  keyError={errors}
                  name='title'
                  type='text'
                  value={singleAcheivment ? singleAcheivment.title : ''}
                  keyRef={register({ required: true })}
                  placeholder='Title'
                />
                {errors.title && (
                  <span className='error'>Title is required</span>
                )}

                <div className='form-group'>
                  <textarea
                    placeholder='Only 300 characters allowed'
                    maxLength='300'
                    rows='5'
                    name='description'
                    ref={register}
                    className='form-control custom-input'
                    style={{
                      background: '#F1F1F1',
                      color: 'black',
                      fontSize: 13
                    }}
                  >
                    {singleAcheivment ? singleAcheivment.description : ''}
                  </textarea>
                </div>
              </>
            ) : (
              <h6>Are you sure you want to delete.</h6>
            )}
            <div className='adminNotes'>
              <button
                disabled={
                  expertStateSelector.state === 'loading' &&
                  expertStateSelector.mode === 'update_achievement'
                    ? true
                    : false
                }
                className='btn custom-adm-btn'
                type='submit'
              >
                {expertStateSelector.state === 'loading' &&
                expertStateSelector.mode === 'update_achievement' ? (
                  <Spinner cWdith={20} cHeight={20} />
                ) : editAcheivementMode === 'delete' ? (
                  'Delete'
                ) : (
                  'Update'
                )}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  };

  const onSubmitGeneralDetails = data => {
    const formData = new FormData();
    formData.append('organization', data.organization);
    formData.append('year', data.year);
    formData.append('role', data.role);
    formData.append('description', data.description);
    if (previewOrgFile) {
      formData.append('organizationImage', previewOrgFile);
    }
    if (orgCvMedia) {
      formData.append('file', orgCvMedia);
    }
    dispatch(
      updateGeneralDetails(
        formData,
        'update_organization',
        'updateEmployment',
        'formData'
      )
    );
  };

  const onSubmitGeneralInfo = data => {
    const formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('headline', data.headline);
    if (previewFile) {
      formData.append('imageProfile', previewFile);
    }
    dispatch(
      updateGeneralDetails(
        formData,
        'update_general_info',
        'updateGeneralInfo',
        'formData'
      )
    );
  };

  const onSubmitAcheivement = data => {
    const formData = {
      title: data.title,
      description: data.description
    };
    dispatch(createAcheivement(formData));
  };

  const onSubmitEditAcheivement = data => {
    const formData = {
      id: parseInt(data.id),
      title: data.title,
      description: data.description
    };
    dispatch(
      updateGeneralDetails(
        formData,
        'update_achievement',
        editAcheivementMode === 'delete'
          ? 'deleteAchievement'
          : 'updateAchievement',
        editAcheivementMode
      )
    );
  };

  const onSubmitDeleteTimings = data => {
    let formData = '';
    formData = {
      id: parseInt(data.id)
    };
    dispatch(
      updateGeneralDetails(
        formData,
        'update_timings',
        'timeAvailability',
        'delete'
      )
    );
  };

  const onSubmitEditPublication = data => {
    let formData = '';
    if (editPublicationMode === 'delete') {
      formData = {
        id: parseInt(data.id)
      };
      dispatch(
        updateGeneralDetails(
          formData,
          'update_publication',
          editPublicationMode === 'delete'
            ? 'deletePublication'
            : 'updatePublication',
          editPublicationMode
        )
      );
      // dispatch(updateGeneralDetails(formData, editPublicationMode));
    } else {
      formData = new FormData();
      formData.append('id', parseInt(data.id));
      formData.append('title', data.title);
      if (publicationMedia) {
        formData.append('file', publicationMedia);
      }
      formData.append('description', data.description);
      dispatch(
        updateGeneralDetails(
          formData,
          'update_publication',
          'updatePublication',
          'media'
        )
      );
    }
  };

  const onSubmitLinkedIn = data => {
    const formData = {
      linkedInProfileUrl:
        data.linkedInProfileUrl !== '' ? data.linkedInProfileUrl : null
    };

    dispatch(
      updateGeneralDetails(
        formData,
        'update_lindkedIn',
        'updateLinkedInProfileLink'
      )
    );
  };

  const onSubmitCalendly = data => {
    const formData = {
      meetingUrl: data.meetingUrl !== '' ? data.meetingUrl : null
    };
    dispatch(
      updateGeneralDetails(formData, 'update_calendly', 'updateMeetingLink')
    );
  };

  const onSubmitContact = data => {
    const formData = {
      phone: data.phone,
      email: data.email,
      mobile: data.mobile
    };
    dispatch(
      updateGeneralDetails(formData, 'update_contact', 'updateFooterInfo')
    );
  };

  const onUploadProfileImg = fileNames => {
    setPreviewFile(fileNames);
    setShowFile(false);
  };

  const onUploadPublication = fileNames => {
    setPublicationMedia(fileNames);
  };

  const onSubmitPublication = data => {
    const formData = new FormData();
    formData.append('title', data.title);
    if (publicationMedia) {
      formData.append('file', publicationMedia);
    }
    formData.append('description', data.description);
    dispatch(createPublication(formData));
  };

  const onUploadOrgImg = fileNames => {
    setPreviewOrgFile(fileNames);
    setShowOrgFile(false);
  };

  const onUploadCv = fileNames => {
    setOrgCvMedia(fileNames);
  };

  const { userProfile } = expertStateSelector;

  return (
    <React.Fragment>
      {showFile && (
        <BorderedModal
          title='Upload a File'
          show={showFile}
          handleClose={handleFileClose}
          handleShow={handleFileShow}
        >
          <DropZone
            state={expertStateSelector.state}
            mode={expertStateSelector.mode}
            styles={{ padding: 30 }}
            onUploadMedia={onUploadProfileImg}
            type='autoSubmit'
            dropType='image/*'
          />
        </BorderedModal>
      )}

      {showOrgFile && (
        <BorderedModal
          title='Upload a File'
          show={showOrgFile}
          handleClose={handleOrgFileClose}
          handleShow={handleOrgFileShow}
        >
          <DropZone
            state={expertStateSelector.state}
            mode={expertStateSelector.mode}
            styles={{ padding: 30 }}
            onUploadMedia={onUploadOrgImg}
            type='autoSubmit'
            dropType='image/*'
          />
        </BorderedModal>
      )}

      {showCvFile && (
        <BorderedModal
          title='Upload a File'
          show={showCvFile}
          handleClose={handleCvFileClose}
          handleShow={handleCvFileShow}
        >
          <DropZone
            state={expertStateSelector.state}
            mode={expertStateSelector.mode}
            styles={{ padding: 30 }}
            onUploadMedia={onUploadCv}
            type='simple'
            showButton={true}
          />
        </BorderedModal>
      )}

      {show && (
        <BorderedModal
          title='Current Employement'
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
        >
          {renderProfile(userProfile)}
        </BorderedModal>
      )}

      {showEditProfile && (
        <BorderedModal
          title='General Information'
          show={showEditProfile}
          handleClose={handleEditProfileClose}
          handleShow={handleEditProfileShow}
        >
          {renderEditProfile(userProfile)}
        </BorderedModal>
      )}

      {showLinkedin && (
        <BorderedModal
          title='LinkedIn'
          show={showLinkedin}
          handleClose={handleLinkedinClose}
          handleShow={handleLinkedinShow}
        >
          {renderLinkedin(userProfile)}
        </BorderedModal>
      )}

      {showCalendly && (
        <BorderedModal
          title='Calendly Link'
          show={showCalendly}
          handleClose={handleCalendlyClose}
          handleShow={handleCalendlyShow}
        >
          {renderCalendly(userProfile)}
        </BorderedModal>
      )}

      {showContact && (
        <BorderedModal
          title='Contact Information'
          show={showContact}
          handleClose={handleContactClose}
          handleShow={handleContactShow}
        >
          {renderContact(userProfile)}
        </BorderedModal>
      )}

      <BorderedModal
        title='Skills & Service'
        show={showSkills}
        handleClose={handleSkillsClose}
        handleShow={handleSkillsShow}
      >
        {renderSkills()}
      </BorderedModal>

      {showAcheivement && (
        <BorderedModal
          title='Achievement(s)'
          show={showAcheivement}
          handleClose={handleAcheivementClose}
          handleShow={handleAcheivementShow}
        >
          {renderAcheivement()}
        </BorderedModal>
      )}

      {showEditAcheivement && (
        <BorderedModal
          title='Achievement(s)'
          show={showEditAcheivement}
          handleClose={handleEditAcheivementClose}
          handleShow={handleEditAcheivementShow}
        >
          {renderEditAcheivement(singleAchievement)}
        </BorderedModal>
      )}
      {showPublication && (
        <BorderedModal
          title='Publication(s)'
          show={showPublication}
          handleClose={handlePublicationClose}
          handleShow={handlePublicationShow}
        >
          {renderPubilcations()}
        </BorderedModal>
      )}

      {showEditPublication && (
        <BorderedModal
          title='Publication(s)'
          show={showEditPublication}
          handleClose={handleEditPublicationClose}
          handleShow={handleEditPublicationShow}
        >
          {renderEditPubilcations(singlePublication)}
        </BorderedModal>
      )}

      <BorderedModal
        title='Time Availability'
        show={showTime}
        handleClose={handleTimeClose}
        handleShow={handleTimeShow}
      >
        {renderTime()}
      </BorderedModal>

      <FrontHeader />
      {expertStateSelector.mode === 'fetch_expert' &&
      expertStateSelector.state === 'loading' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 100
          }}
        >
          <Spinner color='#000' cWdith={50} cHeight={60} />
        </div>
      ) : (
        <div style={{ background: '#e3e1e1' }}>
          <div className='profileSection'>
            <div className='profileExpert'>
              <div className='img-profile-p'>
                <div className='expertBanner'></div>

                <div
                  className='edit-icon'
                  onClick={() => handleEditProfileShow()}
                >
                  <i title='Edit Image' className='fas fa-pencil-alt'></i>
                </div>
              </div>
              <img
                src={
                  userProfile.ExpertProfile?.profileImageUrl
                    ? apiUrl + '/' + userProfile.ExpertProfile.profileImageUrl
                    : placeholder
                }
                className='profileExpertImg roundImg'
                style={{ marginLeft: 40, position: 'absolute' }}
                alt='User'
              />

              <div className='profileExpertBody' style={{ marginTop: 100 }}>
                <div className='expertSection'>
                  <h5>
                    {userProfile &&
                      userProfile.firstName &&
                      autoCap(userProfile.firstName) +
                        ' ' +
                        autoCap(userProfile.lastName)}
                  </h5>
                  <p>
                    {userProfile.ExpertProfile &&
                    userProfile.ExpertProfile.headline !== null
                      ? userProfile.ExpertProfile.headline
                      : 'Please update headline'}
                  </p>
                </div>
                <div className='expertSection'>
                  <div
                    className='exployeDetails empCol'
                    style={{ marginTop: 10 }}
                  >
                    <div className='empLeft'>
                      <h5>Current Employement</h5>
                    </div>
                    <div className='empRight'>
                      {userProfile.ExpertProfile &&
                        userProfile.ExpertProfile.cvUrl !== null && (
                          <div>
                            <a
                              className='btn btnCv'
                              href={`${
                                userProfile.ExpertProfile &&
                                userProfile.ExpertProfile.cvUrl !== null
                                  ? apiUrl +
                                    `/` +
                                    userProfile.ExpertProfile.cvUrl
                                  : '#!'
                              }`}
                              target='_blank'
                            >
                              <i
                                className='fas fa-arrow-circle-down'
                                style={{
                                  color: '#3aa7e4',
                                  marginRight: 5,
                                  marginLeft: '-8px',
                                  fontSize: 30
                                }}
                              ></i>
                              Download CV
                            </a>
                          </div>
                        )}
                      <div
                        className='employe-icon'
                        onClick={() => handleLinkedinShow()}
                      >
                        <i
                          className='fab fa-linkedin-in'
                          style={{
                            color: '#fff',
                            fontSize: 25
                          }}
                        ></i>
                      </div>

                      <div className='edit-icon' onClick={() => handleShow()}>
                        <i title='Edit' className='fas fa-pencil-alt'></i>
                      </div>
                    </div>
                  </div>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      {userProfile.ExpertProfile ? (
                        <>
                          <div>
                            <img
                              src={
                                userProfile.ExpertProfile.organizationImageUrl
                                  ? apiUrl +
                                    '/' +
                                    userProfile.ExpertProfile
                                      .organizationImageUrl
                                  : placeholder
                              }
                              className='expertImg'
                              alt='User Logo'
                            />
                          </div>
                          <div style={{ marginLeft: 10 }}>
                            <p className='p-exp'>
                              {userProfile.ExpertProfile &&
                              userProfile.ExpertProfile.organization !== null
                                ? userProfile.ExpertProfile.organization
                                : 'Organization: N/A'}
                            </p>
                            <p className='p-det'>
                              {userProfile.ExpertProfile &&
                              userProfile.ExpertProfile.year !== null
                                ? userProfile.ExpertProfile.year
                                : 'Year: N/A'}
                            </p>
                            <p className='p-det'>
                              {userProfile.ExpertProfile &&
                              userProfile.ExpertProfile.role !== null
                                ? userProfile.ExpertProfile.role
                                : 'Role: N/A'}
                            </p>
                            <p className='p-det'>
                              {userProfile.ExpertProfile &&
                              userProfile.ExpertProfile.description !== null
                                ? userProfile.ExpertProfile.description
                                : 'Description: N/A'}
                            </p>
                          </div>
                        </>
                      ) : (
                        <small>Please update your current employment.</small>
                      )}
                    </div>
                    {/* right */}
                  </div>
                </div>

                <div className='expertSection'>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <h5>Industry</h5>
                    </div>
                    <div className='empRight'>
                      <div
                        className='edit-icon'
                        onClick={() => handleSkillsShow()}
                      >
                        <i
                          title='Edit Admin Notes'
                          className='fas fa-pencil-alt'
                        ></i>
                      </div>
                    </div>
                  </div>

                  <div className='exployeSkills'>
                    {userProfile.ExpertProfile &&
                    userProfile.ExpertProfile.ExpertIndustry ? (
                      <div className='skill'>
                        <span className='text-center'>
                          {
                            userProfile.ExpertProfile.ExpertIndustry.Industry
                              .name
                          }
                        </span>
                      </div>
                    ) : (
                      <div>
                        <h6>N/A</h6>
                      </div>
                    )}
                  </div>
                </div>

                <div className='expertSection'>
                  <h5>Skill(s)</h5>
                  <div className='exployeSkills'>
                    {userProfile.ExpertProfile &&
                    userProfile.ExpertProfile.ExpertSkill.length > 0 ? (
                      userProfile.ExpertProfile.ExpertSkill.map(skill => (
                        <div className='skill' key={`skill-${skill.Skill.id}`}>
                          <span className='text-center'>
                            {skill.Skill.title}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h6>N/A</h6>
                      </div>
                    )}
                  </div>
                </div>

                <div className='expertSection'>
                  <h5>Service(s)</h5>
                  <div className='exployeSkills'>
                    {userProfile.ExpertProfile &&
                    userProfile.ExpertProfile.ExpertService.length > 0 ? (
                      userProfile.ExpertProfile.ExpertService.map(service => (
                        <div
                          className='skill'
                          key={`service-${service.Service.id}`}
                        >
                          <span className='text-center'>
                            {service.Service.title}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h6>N/A</h6>
                      </div>
                    )}
                  </div>
                </div>

                {/*  */}
                <div className='expertSection'>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <h5>Publication(s)</h5>
                    </div>
                    <div className='empRight'>
                      <div className='' onClick={() => handlePublicationShow()}>
                        <i
                          title='Add Publication'
                          style={{
                            fontSize: 35,
                            color: '#3aa7e4',
                            marginRight: 5,
                            marginTop: 5
                          }}
                          className='fas fa-plus'
                        ></i>
                      </div>
                    </div>
                  </div>
                  {userProfile.ExpertProfile &&
                  userProfile.ExpertProfile.Publication.length > 0 ? (
                    userProfile.ExpertProfile.Publication.map(
                      userPublication => (
                        <div
                          key={'d-' + userPublication.id}
                          className='exployeDetails'
                          style={{ marginTop: 10 }}
                        >
                          <div className='empLeft'>
                            <div>
                              <img
                                src={placeholder}
                                className='expertImg'
                                alt='User Logo'
                              />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                              <p className='p-exp'>{userPublication.title}</p>
                              <p className='p-det'>
                                {userPublication.description}
                              </p>
                              {userPublication &&
                                userPublication.fileUrl !== null && (
                                  <a
                                    href={`${apiUrl}/${userPublication.fileUrl}`}
                                    target='_blank'
                                    className='p-det'
                                    style={{
                                      color: '#3aa7e4',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Download File
                                  </a>
                                )}
                            </div>
                          </div>
                          <div className='empRight'>
                            <div
                              className='edit-icon'
                              onClick={() =>
                                handleEditPublicationShow(
                                  userPublication,
                                  'edit'
                                )
                              }
                            >
                              <i
                                title='Edit Publications'
                                className='fas fa-pencil-alt'
                              ></i>
                            </div>

                            <div
                              className='edit-icon'
                              onClick={() =>
                                handleEditPublicationShow(
                                  userPublication,
                                  'delete'
                                )
                              }
                            >
                              <i
                                title='Edit Admin Notes'
                                className='far fa-trash-alt'
                              ></i>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className='exployeDetails' style={{ marginTop: 10 }}>
                      <div className='empLeft'>
                        <div>
                          <img
                            src={placeholder}
                            className='expertImg'
                            alt='User Logo'
                          />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          <p className='p-exp'>Title</p>
                          <p className='p-det'>Description</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* end */}

                {/* start */}
                <div className='expertSection'>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <h5>Achievement(s)</h5>
                    </div>

                    <div className='empRight'>
                      <div className='' onClick={() => handleAcheivementShow()}>
                        <i
                          title='Add Achievements'
                          className='fas fa-plus'
                          style={{
                            fontSize: 35,
                            color: '#3aa7e4',
                            marginRight: 5,
                            marginTop: 5
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  {userProfile.ExpertProfile &&
                  userProfile.ExpertProfile.Achievement.length > 0 ? (
                    userProfile.ExpertProfile.Achievement.map(
                      userAcheivement => (
                        <div
                          className='exployeDetails'
                          key={'da' + userAcheivement.id}
                          style={{ marginTop: 10 }}
                        >
                          <div className='empLeft'>
                            <div>
                              <img
                                src={placeholder}
                                className='expertImg'
                                alt='User Logo'
                              />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                              <p className='p-exp'>{userAcheivement.title} </p>
                              <p className='p-det'>
                                {userAcheivement.description}
                              </p>
                            </div>
                          </div>
                          <div className='empRight'>
                            <div
                              className='edit-icon'
                              onClick={() =>
                                handleEditAcheivementShow(
                                  userAcheivement,
                                  'edit'
                                )
                              }
                            >
                              <i
                                title='Edit Admin Notes'
                                className='fas fa-pencil-alt'
                              ></i>
                            </div>

                            <div
                              className='edit-icon'
                              onClick={() =>
                                handleEditAcheivementShow(
                                  userAcheivement,
                                  'delete'
                                )
                              }
                            >
                              <i
                                title='Edit Admin Notes'
                                className='far fa-trash-alt'
                              ></i>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className='exployeDetails' style={{ marginTop: 10 }}>
                      <div className='empLeft'>
                        <div>
                          <img
                            src={placeholder}
                            className='expertImg'
                            alt='User Logo'
                          />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          <p className='p-exp'>Title</p>
                          <p className='p-det'>Description</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* end */}

                {/* start */}
                <div className='expertSection'>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <h5>Time Availability</h5>
                    </div>
                    <div className='empRight'>
                      <div
                        className='edit-icon'
                        onClick={() => handleCalendlyShow()}
                      >
                        <i
                          title='Edit Admin Notes'
                          className='far fa-calendar'
                        ></i>
                      </div>
                      <div
                        className='edit-icon'
                        onClick={() => handleTimeShow()}
                      >
                        <i
                          title='Edit Time Availability'
                          className='fas fa-pencil-alt'
                        ></i>
                      </div>
                    </div>
                  </div>

                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <div>
                        {userProfile.ExpertProfile &&
                        userProfile.ExpertProfile.TimeAvailability.length >
                          0 ? (
                          userProfile.ExpertProfile.TimeAvailability.map(
                            time => (
                              <p className='t-emp' key={`tTime-${time.id}`}>
                                <span>
                                  {' '}
                                  {dayGetter(time.date) + ', '}
                                  <Moment format='DD MMMM, YYYY'>
                                    {time.date}
                                  </Moment>
                                </span>{' '}
                                <small>
                                  {moment(time.startTime, 'hh:mm').format(
                                    'hh:mm A'
                                  )}
                                  -{' '}
                                  {moment(time.endTime, 'hh:mm').format(
                                    'hh:mm A'
                                  )}{' '}
                                  - {time.TimeZone.title}
                                </small>{' '}
                                {time.isRecursive ? ' - Recurring' : ''}
                              </p>
                            )
                          )
                        ) : (
                          <div>
                            <h6>N/A</h6>
                          </div>
                        )}
                        {userProfile.ExpertProfile &&
                          userProfile.ExpertProfile.TimeAvailability.length >
                            0 && (
                            <button
                              onClick={() => handleTimeShow()}
                              className='btn custom-btn'
                              style={{
                                borderRadius: 50,
                                height: 30,
                                fontSize: 12,
                                fontWeight: 'bold',
                                padding: '0px 10px 0px 10px'
                              }}
                            >
                              View More
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end */}

                {/* start */}
                <div className='expertSection'>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <h5>Contact Information</h5>
                    </div>
                    <div className='empRight'>
                      <div
                        className='edit-icon'
                        onClick={() => handleContactShow()}
                      >
                        <i
                          title='Edit Contact Information'
                          className='fas fa-pencil-alt'
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      {userProfile.ExpertProfile &&
                        (userProfile.ExpertProfile.phone ? (
                          <div>
                            <p className='p-exp'>Phone </p>
                            <p className='p-det'>
                              {userProfile.ExpertProfile.phone}
                            </p>

                            <p className='p-exp'>Email </p>
                            <p className='p-det'>
                              {userProfile.ExpertProfile.email}
                            </p>

                            <p className='p-exp'>Mobile </p>
                            <p className='p-det'>
                              {userProfile.ExpertProfile.mobile}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className='p-exp'>Phone </p>
                            <p className='p-det'>N/A</p>

                            <p className='p-exp'>Email </p>
                            <p className='p-det'>N/A</p>

                            <p className='p-exp'>Mobile </p>
                            <p className='p-det'>N/A</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                {/* end */}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
