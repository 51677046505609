// Constants
export const FETCH_CLIENT_PROFILE =
  'MarketPlace/ClientProfile/FETCH_CLIENT_PROFILE';

export const FETCH_CLIENT_PROFILE_SUCCESS =
  'MarketPlace/ClientProfile/FETCH_CLIENT_PROFILE_SUCCESS';

export const FETCH_CLIENT_PROFILE_ERROR =
  'MarketPlace/ClientProfile/FETCH_CLIENT_PROFILE_ERROR';

export const FILTER_EXPERT_PROFILE =
  'MarketPlace/ClientProfile/FILTER_EXPERT_PROFILE';

export const FILTER_EXPERT_PROFILE_SUCCESS =
  'MarketPlace/ClientProfile/FILTER_EXPERT_PROFILE_SUCCESS';

export const FILTER_EXPERT_PROFILE_ERROR =
  'MarketPlace/ClientProfile/FILTER_EXPERT_PROFILE_ERROR';

export const UPDATE_CLIENT_PROFILE =
  'MarketPlace/ClientProfile/UPDATE_CLIENT_PROFILE';

export const UPDATE_CLIENT_PROFILE_SUCCESS =
  'MarketPlace/ClientProfile/UPDATE_CLIENT_PROFILE_SUCCESS';

export const UPDATE_CLIENT_PROFILE_ERROR =
  'MarketPlace/ClientProfile/UPDATE_CLIENT_PROFILE_ERROR';

export const UPLOAD_CLIENT_PROFILE_MEDIA =
  'MarketPlace/ClientProfile/UPLOAD_CLIENT_PROFILE_MEDIA';

export const UPLOAD_CLIENT_PROFILE_MEDIA_SUCCESS =
  'MarketPlace/ClientProfile/UPLOAD_CLIENT_PROFILE_MEDIA_SUCCESS';

export const UPLOAD_CLIENT_PROFILE_MEDIA_ERROR =
  'MarketPlace/ClientProfile/UPLOAD_CLIENT_PROFILE_MEDIA_ERROR';

export const FETCH_CLIENT_EXPERT_PROFILE =
  'MarketPlace/ClientProfile/FETCH_CLIENT_EXPERT_PROFILE';

export const FETCH_CLIENT_EXPERT_PROFILE_SUCCESS =
  'MarketPlace/ClientProfile/FETCH_CLIENT_EXPERT_PROFILE_SUCCESS';

export const FETCH_CLIENT_EXPERT_PROFILE_ERROR =
  'MarketPlace/ClientProfile/FETCH_CLIENT_EXPERT_PROFILE_ERROR';

export const CREATE_EXPERT_REQUEST =
  'MarketPlace/ClientProfile/CREATE_EXPERT_REQUEST';

export const CREATE_EXPERT_REQUEST_SUCCESS =
  'MarketPlace/ClientProfile/CREATE_EXPERT_REQUEST_SUCCESS';

export const CREATE_EXPERT_REQUEST_ERROR =
  'MarketPlace/ClientProfile/CREATE_EXPERT_REQUEST_ERROR';

export const SET_OFFSET_COUNT = 'MarketPlace/ClientProfile/SET_OFFSET_COUNT';

export const CLEAR_CLIENT_STATE =
  'MarketPlace/ClientProfile/CLEAR_CLIENT_STATE';
