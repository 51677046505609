// Constants
export const FETCH_USER = 'MarketPlace/User/FETCH_USER';

export const FETCH_USER_SUCCESS = 'MarketPlace/User/FETCH_USER_SUCCESS';

export const FETCH_USER_ERROR = 'MarketPlace/User/FETCH_USER_ERROR';

export const FETCH_SINGLE_USER = 'MarketPlace/User/FETCH_SINGLE_USER';

export const FETCH_SINGLE_USER_SUCCESS =
  'MarketPlace/User/FTECH_SINGLE_USER_SUCCESS';

export const FETCH_SINGLE_USER_ERROR =
  'MarketPlace/User/FETCH_SINGLE_USER_ERROR';

export const FILTER_USERS = 'MarketPlace/User/FILTER_USERS';

export const FILTER_USERS_SUCCESS = 'MarketPlace/User/FILTER_USERS_SUCCESS';

export const FILTER_USERS_ERROR = 'MarketPlace/User/FILTER_USERS_ERROR';

export const UPDATE_USER_STATUS = 'MarketPlace/User/UPDATE_USER_STATUS';

export const UPDATE_USER_STATUS_SUCCESS =
  'MarketPlace/User/UPDATE_USER_STATUS_SUCCESS';

export const UPDATE_USER_STATUS_ERROR =
  'MarketPlace/User/UPDATE_USER_STATUS_ERROR';
