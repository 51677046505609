import React from 'react';

export default function Timer({ onChangeTime, time }) {
  return (
    <React.Fragment>
      <div
        onClick={() => onChangeTime('00:00')}
        className={`timer ${time === '00:00' && 'timerActive'}`}
      >
        <span>12:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('00:30')}
        className={`timer ${time === '00:30' && 'timerActive'}`}
      >
        <span>12:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('01:00')}
        className={`timer ${time === '01:00' && 'timerActive'}`}
      >
        <span>01:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('01:30')}
        className={`timer ${time === '01:30' && 'timerActive'}`}
      >
        <span>01:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('02:00')}
        className={`timer ${time === '02:00' && 'timerActive'}`}
      >
        <span>02:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('02:30')}
        className={`timer ${time === '02:30' && 'timerActive'}`}
      >
        <span>02:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('03:00')}
        className={`timer ${time === '03:00' && 'timerActive'}`}
      >
        <span>03:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('03:30')}
        className={`timer ${time === '03:30' && 'timerActive'}`}
      >
        <span>03:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('04:00')}
        className={`timer ${time === '04:00' && 'timerActive'}`}
      >
        <span>04:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('04:30')}
        className={`timer ${time === '04:30' && 'timerActive'}`}
      >
        <span>04:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('05:00')}
        className={`timer ${time === '05:00' && 'timerActive'}`}
      >
        <span>05:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('05:30')}
        className={`timer ${time === '05:30' && 'timerActive'}`}
      >
        <span>05:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('06:00')}
        className={`timer ${time === '06:00' && 'timerActive'}`}
      >
        <span>06:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('06:30')}
        className={`timer ${time === '06:30' && 'timerActive'}`}
      >
        <span>06:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('07:00')}
        className={`timer ${time === '07:00' && 'timerActive'}`}
      >
        <span>07:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('07:30')}
        className={`timer ${time === '07:30' && 'timerActive'}`}
      >
        <span>07:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('08:00')}
        className={`timer ${time === '08:00' && 'timerActive'}`}
      >
        <span>08:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('08:30')}
        className={`timer ${time === '08:30' && 'timerActive'}`}
      >
        <span>08:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('09:00')}
        className={`timer ${time === '09:00' && 'timerActive'}`}
      >
        <span>09:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('09:30')}
        className={`timer ${time === '09:30' && 'timerActive'}`}
      >
        <span>09:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('10:00')}
        className={`timer ${time === '10:00' && 'timerActive'}`}
      >
        <span>10:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('10:30')}
        className={`timer ${time === '10:30' && 'timerActive'}`}
      >
        <span>10:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('11:00')}
        className={`timer ${time === '11:00' && 'timerActive'}`}
      >
        <span>11:00 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('11:30')}
        className={`timer ${time === '11:30' && 'timerActive'}`}
      >
        <span>11:30 AM</span>
      </div>
      <div
        onClick={() => onChangeTime('12:00')}
        className={`timer ${time === '12:00' && 'timerActive'}`}
      >
        <span>12:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('12:30')}
        className={`timer ${time === '12:30' && 'timerActive'}`}
      >
        <span>12:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('13:00')}
        className={`timer ${time === '13:00' && 'timerActive'}`}
      >
        <span>01:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('13:30')}
        className={`timer ${time === '13:30' && 'timerActive'}`}
      >
        <span>01:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('14:00')}
        className={`timer ${time === '14:00' && 'timerActive'}`}
      >
        <span>02:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('14:30')}
        className={`timer ${time === '14:30' && 'timerActive'}`}
      >
        <span>02:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('15:00')}
        className={`timer ${time === '15:00' && 'timerActive'}`}
      >
        <span>03:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('15:30')}
        className={`timer ${time === '15:30' && 'timerActive'}`}
      >
        <span>03:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('16:00')}
        className={`timer ${time === '16:00' && 'timerActive'}`}
      >
        <span>04:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('16:30')}
        className={`timer ${time === '16:30' && 'timerActive'}`}
      >
        <span>04:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('17:00')}
        className={`timer ${time === '17:00' && 'timerActive'}`}
      >
        <span>05:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('17:30')}
        className={`timer ${time === '17:30' && 'timerActive'}`}
      >
        <span>05:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('18:00')}
        className={`timer ${time === '18:00' && 'timerActive'}`}
      >
        <span>06:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('18:30')}
        className={`timer ${time === '18:30' && 'timerActive'}`}
      >
        <span>06:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('19:00')}
        className={`timer ${time === '19:00' && 'timerActive'}`}
      >
        <span>07:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('19:30')}
        className={`timer ${time === '19:30' && 'timerActive'}`}
      >
        <span>07:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('20:00')}
        className={`timer ${time === '20:00' && 'timerActive'}`}
      >
        <span>08:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('20:30')}
        className={`timer ${time === '20:30' && 'timerActive'}`}
      >
        <span>08:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('21:00')}
        className={`timer ${time === '21:00' && 'timerActive'}`}
      >
        <span>09:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('21:30')}
        className={`timer ${time === '21:30' && 'timerActive'}`}
      >
        <span>09:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('22:00')}
        className={`timer ${time === '22:00' && 'timerActive'}`}
      >
        <span>10:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('22:30')}
        className={`timer ${time === '22:30' && 'timerActive'}`}
      >
        <span>10:30 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('23:00')}
        className={`timer ${time === '23:00' && 'timerActive'}`}
      >
        <span>11:00 PM</span>
      </div>
      <div
        onClick={() => onChangeTime('23:30')}
        className={`timer ${time === '23:30' && 'timerActive'}`}
      >
        <span>11:30 PM</span>
      </div>
    </React.Fragment>
  );
}
