import {
  FETCH_ADMIN_REQUEST,
  FETCH_ADMIN_REQUEST_SUCCESS,
  FETCH_ADMIN_REQUEST_ERROR,
  CREATE_ADMIN_NOTES,
  CREATE_ADMIN_NOTES_SUCCESS,
  CREATE_ADMIN_NOTES_ERROR,
  CREATE_ADMIN_FEEDBACK,
  CREATE_ADMIN_FEEDBACK_SUCCESS,
  CREATE_ADMIN_FEEDBACK_ERROR,
  UPLOAD_ADMIN_FEEDBACK_MEDIA,
  UPLOAD_ADMIN_FEEDBACK_MEDIA_SUCCESS,
  UPLOAD_ADMIN_FEEDBACK_MEDIA_ERROR,
  UPDATE_REQUEST_STATUS,
  UPDATE_REQUEST_STATUS_SUCCESS,
  UPDATE_REQUEST_STATUS_ERROR,
  UPDATE_COMPENSATION,
  UPDATE_COMPENSATION_SUCCESS,
  UPDATE_COMPENSATION_ERROR
} from '../constants';

const initialAuthState = {
  state: 'initial',
  mode: 'initial',
  requests: [],
  singleRequestId: '',
  status: ''
};

export function adminRequestReducer(state = initialAuthState, action) {
  switch (action.type) {
    case FETCH_ADMIN_REQUEST:
      return {
        ...state,
        mode: 'fetch_admin_request',
        state: 'loading',
        status: ''
      };

    case FETCH_ADMIN_REQUEST_SUCCESS:
      return {
        ...state,
        state: 'success',
        requests: action.details,
        status: ''
      };

    case FETCH_ADMIN_REQUEST_ERROR:
      return { ...state, state: 'error', status: action.status };

    case CREATE_ADMIN_NOTES:
      return {
        ...state,
        mode: 'create_admin_notes',
        state: 'loading',
        status: ''
      };

    case CREATE_ADMIN_NOTES_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case CREATE_ADMIN_NOTES_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPDATE_COMPENSATION:
      return {
        ...state,
        mode: 'update_compensation',
        state: 'loading',
        status: ''
      };

    case UPDATE_COMPENSATION_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPDATE_COMPENSATION_ERROR:
      return { ...state, state: 'error', status: action.status };

    case CREATE_ADMIN_FEEDBACK:
      return {
        ...state,
        mode: 'create_admin_feedback',
        state: 'loading',
        status: ''
      };

    case CREATE_ADMIN_FEEDBACK_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: '',
        singleRequestId: action.requestId
      };

    case CREATE_ADMIN_FEEDBACK_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPDATE_REQUEST_STATUS:
      return {
        ...state,
        mode: 'update_request_status',
        state: 'loading',
        status: ''
      };

    case UPDATE_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPDATE_REQUEST_STATUS_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPLOAD_ADMIN_FEEDBACK_MEDIA:
      return {
        ...state,
        mode: 'upload_feedback_media',
        state: 'loading',
        status: ''
      };

    case UPLOAD_ADMIN_FEEDBACK_MEDIA_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPLOAD_ADMIN_FEEDBACK_MEDIA_ERROR:
      return { ...state, state: 'error', status: action.status };

    default:
      return state;
  }
}
