import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../../Components/InputField';
import Spinner from '../../../../Components/Spinner';
import { forgotPassword } from '../../../../actions';
import { useForm } from 'react-hook-form';

export default function LoginModal({ setAuthStatus }) {
  const dispatch = useDispatch();
  const authSelector = useSelector(state => state.auth);
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => dispatch(forgotPassword(data));
  return (
    <div>
      <div className='m-body'>
        <p className='title'>Forgot Password</p>
        <p className='subTitle'>We'll send you a link to reset your password</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            keyError={errors}
            name='email'
            type='email'
            value=''
            keyRef={register({ required: true })}
            placeholder='Email'
          />
          {errors.email && <span className='error'>Email is required</span>}

          <button
            disabled={authSelector.state === 'loading' ? true : false}
            className='btn btn-block btn-primary custom-btn'
            type='submit'
          >
            {authSelector.state === 'loading' ? <Spinner /> : 'Reset Password'}
          </button>
        </form>
      </div>
      <div className='modal-footer-body'>
        <p className='subTitle text-center' style={{ marginTop: 20 }}>
          Back to{' '}
          <span
            style={{ color: '#3aa7e4', fontWeight: 'bold' }}
            onClick={() => setAuthStatus('login')}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
}
