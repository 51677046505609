let apiUrl = 'https://experts-labs.com/backend';
if (process.env.REACT_APP_STAGE === 'local') {
  apiUrl = 'http://db3d379e2408.ngrok.io';
} else if (process.env.REACT_APP_STAGE === 'dev') {
  apiUrl = 'https://hiringinterviewer.com/backend';
}
else if (process.env.REACT_APP_STAGE === 'staging') {
  apiUrl = 'https://hiringinterviewer.com/backend';
} 
else if (process.env.REACT_APP_STAGE === 'production') {
  apiUrl = 'https://experts-labs.com/backend';
}  
else {
  apiUrl = 'https://experts-labs.com/backend';
}

export default apiUrl;
