import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopHeader from '../../Components/TopHeader';
import LeftSideBar from '../../Components/LeftSideBar';
import Content from '../../Components/Content';
import history from '../../Configs/history';
import apiUrl from '../../Configs/ApiUrl';
import { placeholder } from '../../Assets';
import { fetchUser, filterUsers } from '../../actions';

export default function Users() {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState('-1');
  const [selectedStatus, setSelectedStatus] = useState('-1');
  const userStateSelector = useSelector(state => state.user);
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);
  let counter = 0;

  const onRoleChangeHandler = value => {
    setSelectedRole(value);
    dispatch(filterUsers(searchQuery, value, selectedStatus));
  };

  const onQueryChangeHandler = value => {
    setSearchQuery(value);
    dispatch(filterUsers(value, selectedRole, selectedStatus));
  };

  const onStatusHandler = value => {
    setSelectedStatus(value);
    dispatch(filterUsers(searchQuery, selectedRole, value));
  };

  return (
    <React.Fragment>
      <TopHeader role='Admin' />
      <div className='wrapper'>
        <LeftSideBar />
        <Content>
          <div className='user-filter'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='filter-select'>
                  <div className='form-group'>
                    <select
                      className='form-control custom-input-filter'
                      defaultValue={selectedRole}
                      onChange={ev => onRoleChangeHandler(ev.target.value)}
                    >
                      <option value='-1'>Select Role</option>
                      <option value='Expert'>Expert</option>
                      <option value='Client'>Client</option>
                    </select>
                    <i className='fa fa-caret-down'></i>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'row',
                      marginTop: 20
                    }}
                  >
                    <button
                      className='btn btn-block custom-btn-reset'
                      style={{
                        width: '100%',
                        marginLeft: 0,
                        height: 50
                      }}
                      onClick={() => history.push('/users')}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <input
                        onChange={ev => onQueryChangeHandler(ev.target.value)}
                        className='form-control custom-input-filter'
                        placeholder='Search By Name OR Email'
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='filter-actions'>
                      <div className='filter-action-items'>
                        Active
                        <div className='form-group'>
                          <input
                            checked={selectedStatus === true ? true : false}
                            onClick={() => onStatusHandler(true)}
                            type='checkbox'
                            className='form-check-input'
                            id='exampleCheck1'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='filter-actions'>
                      <div className='filter-action-items'>
                        Block
                        <div className='form-group'>
                          <input
                            checked={selectedStatus === false ? true : false}
                            onClick={() => onStatusHandler(false)}
                            type='checkbox'
                            className='form-check-input'
                            id='exampleCheck1'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='table-responsive custom-tb'>
                <table
                  className='table table-bordered'
                  style={{ background: 'white' }}
                >
                  <thead>
                    <tr className='d-flex'>
                      <th scope='col' className='col-1'>
                        ID
                      </th>
                      <th scope='col' className='col-1'>
                        Image
                      </th>
                      <th scope='col' className='col-2'>
                        Name
                      </th>
                      {/* <th scope='col' className='col-2'>
                        Contact
                      </th> */}
                      <th scope='col' className='col-4'>
                        Email
                      </th>
                      <th scope='col' className='col-1'>
                        Types
                      </th>
                      <th scope='col' className='col-3'>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userStateSelector.users.rows &&
                      userStateSelector.users.rows.length > 0 &&
                      userStateSelector.users.rows.map(userData => {
                        let profileImage = null;
                        if (
                          userData.ClientProfile &&
                          userData.ClientProfile.profileImageUrl
                        ) {
                          profileImage =
                            apiUrl +
                            '/' +
                            userData.ClientProfile.profileImageUrl;
                        } else if (
                          userData.ExpertProfile &&
                          userData.ExpertProfile.profileImageUrl
                        ) {
                          profileImage =
                            apiUrl +
                            '/' +
                            userData.ExpertProfile.profileImageUrl;
                        } else {
                          profileImage = placeholder;
                        }
                        return (
                          <tr key={`us-${userData.id}`} className='d-flex'>
                            <td className='col-1'>{(counter = counter + 1)}</td>
                            <td className='col-1'>
                              <img
                                alt='User'
                                className='user-logo'
                                src={profileImage}
                              />
                            </td>
                            <td className='col-2'>
                              {userData.firstName + ' ' + userData.lastName}
                            </td>
                            {/* <td className='col-2'>715-371-3507</td> */}
                            <td className='col-4'>{userData.email}</td>
                            <td className='col-1'>{userData.role}</td>
                            <td className='col-1'>
                              <span
                                className='table-active-col'
                                style={{ fontSize: 11 }}
                              >
                                {userData.isActive ? 'Active' : 'In Active'}
                              </span>
                            </td>
                            <td className='col-2'>
                              <button
                                className='profile-btn'
                                title='View Profile'
                                onClick={() =>
                                  history.push({
                                    pathname:
                                      userData.role === 'Client'
                                        ? `profile/${userData.id}/${userData.role}`
                                        : `expert/${userData.id}/${userData.role}`,
                                    search: `?from=users`
                                  })
                                }
                              >
                                View Profile
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                    {userStateSelector.requests &&
                      userStateSelector.state === 'success' &&
                      userStateSelector.users.rows.length === 0 && (
                        <tr>
                          <td className='col-12'>
                            <p
                              style={{ fontSize: 11, marginBottom: 0 }}
                              className='text-center'
                            >
                              No Data Found
                            </p>
                          </td>
                        </tr>
                      )}

                    {userStateSelector.users &&
                      userStateSelector.state === 'loading' && (
                        <tr>
                          <td className='col-12'>
                            <p
                              style={{ fontSize: 11, marginBottom: 0 }}
                              className='text-center'
                            >
                              Loading...
                            </p>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Content>
      </div>
    </React.Fragment>
  );
}
