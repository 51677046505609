export default function authorizedPutHeaders(request, token) {
  return {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-access-token': `${token}`
    },
    body: JSON.stringify(request)
  };
}
