import React from 'react';
import TopHeader from '../../../../Components/TopHeader';

export default function VerifyPage() {
  return (
    <React.Fragment>
      <TopHeader />
      <div>
        <div className='profileSection'>
          <div className='profile' style={{ width: '80%' }}>
            <div className='col-md-12 '>
              <div
                className='alert alert-success text-center'
                style={{ padding: '50px 10px 50px 10px' }}
              >
                <strong>
                  Verification email has been sent to your email. Please check
                  your email.
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
