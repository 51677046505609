import React from 'react';
import { banner } from '../Assets';

export default function Banner() {
  return (
    <div className='main-banner'>
      
      <div className='banner-details'>
        <div className=''>
          {/* <h1>Introduction</h1> */}
          <h2 className='banner-h1' style={{marginBottom: 15}}>
          <span style={{ color: '#3aa7e4' }}>Handpicked Experts</span> for your
          crucial task
         </h2>
          <p>
            For decades, getting help from Industry’s top experts with 
            short-term essential tasks has been a challenge.
            <br />
            Introducing a unique solution for companies, to get help in their essential tasks from highly reputed experts working in well know institutions and technologists of the industry.
            <br /> 
            Companies can handpick experts for different kind of tasks e.g., Interview process, advisor, coach, guest speaker, or any other customize assignment. 
            <br/>
            Tasks can be as short as 30 minutes, or as long as
            companies would like.
          </p>
          {/* <a
            className='btn custom-btn'
            style={{ width: 150 }}
            href='https://www.hireinterviewer.com'
          >
            <span style={{ display: 'inline-block', marginTop: 6 }}>
              Expert Help
            </span>
          </a> */}
        </div>
        <div className='banner-img'>
          <img alt='Banner' src={banner} />
        </div>
      </div>

      <div className='mob-v'>
        <iframe
          width='100%'
          height='400'
          src='https://www.youtube.com/embed/HXY-cMYsbbI'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
