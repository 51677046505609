import {
  FETCH_SKILLS_PROFILE,
  FETCH_SKILLS_PROFILE_SUCCESS,
  FETCH_SKILLS_PROFILE_ERROR,
  FETCH_SERVICES_PROFILE,
  FETCH_SERVICES_PROFILE_SUCCESS,
  FETCH_SERVICES_PROFILE_ERROR,
  FETCH_INDUSTRIES_PROFILE,
  FETCH_INDUSTRIES_PROFILE_SUCCESS,
  FETCH_INDUSTRIES_PROFILE_ERROR
} from '../constants';

const initialAuthState = {
  state: 'initial',
  mode: 'initial',
  skills: [],
  services: [],
  industries: [],
  status: ''
};

export function commonReducer(state = initialAuthState, action) {
  switch (action.type) {
    case FETCH_SKILLS_PROFILE:
      return { ...state, mode: 'fetch_skills', state: 'loading', status: '' };

    case FETCH_SKILLS_PROFILE_SUCCESS:
      return {
        ...state,
        state: 'success',
        skills: action.details,
        status: ''
      };

    case FETCH_SKILLS_PROFILE_ERROR:
      return { ...state, state: 'error', status: action.status };

    case FETCH_SERVICES_PROFILE:
      return { ...state, mode: 'fetch_services', state: 'loading', status: '' };

    case FETCH_SERVICES_PROFILE_SUCCESS:
      return {
        ...state,
        state: 'success',
        services: action.details,
        status: ''
      };

    case FETCH_SERVICES_PROFILE_ERROR:
      return { ...state, state: 'error', status: action.status };

    case FETCH_INDUSTRIES_PROFILE:
      return { ...state, mode: 'fetch_services', state: 'loading', status: '' };

    case FETCH_INDUSTRIES_PROFILE_SUCCESS:
      return {
        ...state,
        state: 'success',
        industries: action.details,
        status: ''
      };

    case FETCH_INDUSTRIES_PROFILE_ERROR:
      return { ...state, state: 'error', status: action.status };

    default:
      return state;
  }
}
