import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import history from '../../Configs/history';
import {
  filterSkillFront,
  filterServiceFront
} from '../../helpers/filterCommon';
import FrontHeader from '../../Components/FrontHeader';
import PopuplarExpertClient from '../../Components/PopularExpertClient';

import DropZoneRequest from '../DropZoneRequest';
import CreateRequestModal from '../../Components/CreateRequestModal';
import Spinner from '../../Components/Spinner';

import {
  fetchClientExpertProfile,
  createRequest,
  fetchServices,
  fetchSkills,
  fetchIndustries,
  filterExpertProfile,
  setOffsetCount,
  clearClientState
} from '../../actions';

export default function ClientHome() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [showHire, setShowHire] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('-1');
  const [selectedSkill, setSelectedSkill] = useState('-1');
  const [selectedService, setSelectedService] = useState('-1');
  const [requestMedia, setRequestMedia] = useState(null);
  const [selectedExpert, setSelectedExpert] = useState(null);
  const [showPagination, setShowPagination] = useState(true);
  const [offset, setOffset] = useState(0);
  const expertSelector = useSelector(state => state.client);
  const token = useSelector(state => state.auth.token);
  const clientState = useSelector(state => state.client);
  const commonSelector = useSelector(state => state.common);

  useEffect(() => {
    if (token) {
      if (showPagination === true) {
        dispatch(fetchClientExpertProfile(clientState.offset));
      }
      dispatch(fetchIndustries());
      dispatch(fetchSkills());
      dispatch(fetchServices());
      dispatch(setOffsetCount(offset));
    }
  }, [token, dispatch, clientState.offset, showPagination]);

  useEffect(() => {
    if (clientState.offset >= clientState.count) {
      setShowPagination(false);
    }
  }, [clientState.offset]);

  useEffect(() => {
    if (clientState.experts.length === clientState.count) {
      setShowPagination(false);
    }
    if (clientState.experts.length === 0 && clientState.state === 'success') {
      setShowPagination(false);
    }
  }, [clientState.count, clientState.experts, clientState.experts.length]);

  const handleHireClose = () => {
    setShowHire(false);
    dispatch(clearClientState());
  };
  const handleHireShow = () => {
    setShowHire(true);
  };

  const onUploadRequest = fileNames => {
    setRequestMedia(fileNames);
  };

  const onSubmitRequest = data => {
    const formData = new FormData();
    formData.append('expertUserId', data.expertUserId);
    if (requestMedia) {
      formData.append('file', requestMedia);
    }
    formData.append('description', data.description);
    dispatch(createRequest(formData));
  };

  const renderHire = () => {
    if (
      clientState.state === 'success' &&
      clientState.mode === 'create_request'
    ) {
      history.push(`/client`);
    }
    return (
      <>
        <div className='m-body' style={{ minHeight: 200 }}>
          <>
            <form onSubmit={handleSubmit(onSubmitRequest)}>
              <input
                name='expertUserId'
                type='hidden'
                ref={register}
                defaultValue={selectedExpert.id}
              />
              <div className='form-group'>
                <small style={{ fontWeight: 'bold' }}>Description</small>
                <textarea
                  placeholder='Only 300 characters allowed'
                  maxLength='300'
                  rows='5'
                  name='description'
                  ref={register({ required: true })}
                  className='form-control custom-input'
                  style={{
                    background: '#E1E1E1',
                    color: 'black',
                    fontSize: 13
                  }}
                ></textarea>
                {errors.description && (
                  <span className='error'>Description is required</span>
                )}
              </div>

              <DropZoneRequest
                state={clientState.state}
                mode={clientState.mode}
                onUploadMedia={onUploadRequest}
                clientState={clientState}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <button
                  disabled={
                    clientState.state === 'loading' &&
                    clientState.mode === 'create_request'
                      ? true
                      : false
                  }
                  className='btn custom-adm-btn'
                  style={{ width: 300 }}
                  type='submit'
                >
                  {clientState.state === 'loading' &&
                  clientState.mode === 'create_request' ? (
                    <Spinner cWdith={20} cHeight={20} />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          </>
        </div>
      </>
    );
  };

  const onChangeQuery = ev => {
    setSearchQuery(ev.target.value);
  };

  const onFilterExperts = () => {
    dispatch(
      filterExpertProfile(
        searchQuery,
        selectedIndustry,
        selectedSkill,
        selectedService
      )
    );
  };

  const onSelectIndustry = ev => {
    setSelectedIndustry(ev.target.value);
    dispatch(
      filterExpertProfile(
        searchQuery,
        ev.target.value,
        selectedSkill,
        selectedService
      )
    );
  };

  const onSelectSkills = ev => {
    setSelectedSkill(ev.target.value);
    dispatch(
      filterExpertProfile(
        searchQuery,
        selectedIndustry,
        ev.target.value,
        selectedService
      )
    );
  };

  const onSelectServices = ev => {
    setSelectedService(ev.target.value);
    dispatch(
      filterExpertProfile(
        searchQuery,
        selectedIndustry,
        selectedSkill,
        ev.target.value
      )
    );
  };

  const loadMoreClickHandler = () => {
    setOffset(offset + 4);
    dispatch(setOffsetCount(offset + 4));
    dispatch(fetchClientExpertProfile(offset + 4));
  };
  return (
    <div>
      {showHire && (
        <CreateRequestModal
          title='Create Description'
          show={showHire}
          handleClose={handleHireClose}
          handleShow={handleHireShow}
        >
          {renderHire()}
        </CreateRequestModal>
      )}
      <FrontHeader role='client' />
      <div className='client-banner'>
        <h1 className='client-h1'>Find the next expert</h1>
        <p className='client-p'>
          Get Connected to Expert directly or using a client service
        </p>
        <div className='search-parent'>
          <div className='row cRow' style={{ marginTop: 20 }}>
            <div className='col-md-12'>
              <div className='input-search'>
                <input
                  required
                  onChange={ev => onChangeQuery(ev)}
                  className='form-control custom-input-search'
                  placeholder='What are you looking for?'
                />
                <button
                  className='btn custom-btn-search'
                  onClick={() => onFilterExperts()}
                >
                  <i className='fas fa-search'></i> Search
                </button>
                <button
                  className='btn custom-btn-reset hidden-sm'
                  onClick={() => history.push('/client')}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <div className='row cRow' style={{ marginTop: 20 }}>
            <div className='col-md-4'>
              <div className='form-group'>
                <select
                  className='form-control custom-input-filter'
                  onChange={ev => onSelectIndustry(ev)}
                  defaultValue={selectedIndustry}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',
                    color: '#797b8f',
                    borderColor: '#f1f1f1',
                    background: '#f7f7f7'
                  }}
                >
                  <option value='-1'>Industry</option>
                  {commonSelector.industries.length > 0 &&
                    commonSelector.industries.map(industry => (
                      <option
                        key={`industry-${industry.id}`}
                        value={industry.id}
                      >
                        {industry.name}
                      </option>
                    ))}
                </select>
                <i
                  className='fa fa-caret-down'
                  style={{ background: 'transparent' }}
                ></i>
              </div>
            </div>

            <div className='col-md-4'>
              <div className='form-group'>
                <select
                  onChange={ev => onSelectSkills(ev)}
                  defaultValue={selectedSkill}
                  className='form-control custom-input-filter'
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',
                    color: '#797b8f',
                    borderColor: '#f1f1f1',
                    background: '#f7f7f7'
                  }}
                >
                  <option value='-1'>Skills</option>
                  {commonSelector.skills.length > 0 &&
                    filterSkillFront(commonSelector.skills).map(skill => {
                      return (
                        <option key={`skill-${skill.id}`} value={skill.id}>
                          {skill.title}
                        </option>
                      );
                    })}
                </select>
                <i
                  className='fa fa-caret-down'
                  style={{ background: 'transparent' }}
                ></i>
              </div>
            </div>

            <div className='col-md-4'>
              <div className='form-group'>
                <select
                  onChange={ev => onSelectServices(ev)}
                  defaultValue={selectedService}
                  className='form-control custom-input-filter'
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',
                    color: '#797b8f',
                    borderColor: '#f1f1f1',
                    background: '#f7f7f7'
                  }}
                >
                  <option value='-1'>Services</option>
                  {commonSelector.services.length > 0 &&
                    filterServiceFront(commonSelector.services).map(service => (
                      <option key={`service-${service.id}`} value={service.id}>
                        {service.title}
                      </option>
                    ))}
                </select>
                <i
                  className='fa fa-caret-down'
                  style={{ background: 'transparent' }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopuplarExpertClient
        handleHireShow={handleHireShow}
        setSelectedExpert={setSelectedExpert}
        expertSelector={expertSelector}
      />
      {showPagination !== false && (
        <div className='loader-p' onClick={() => loadMoreClickHandler()}>
          <button className='btn add-user-request'>Load More</button>
        </div>
      )}
    </div>
  );
}
