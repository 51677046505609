import {
  FETCH_ADMIN_REQUEST,
  FETCH_ADMIN_REQUEST_SUCCESS,
  FETCH_ADMIN_REQUEST_ERROR,
  CREATE_ADMIN_NOTES,
  CREATE_ADMIN_NOTES_SUCCESS,
  CREATE_ADMIN_NOTES_ERROR,
  CREATE_ADMIN_FEEDBACK,
  CREATE_ADMIN_FEEDBACK_SUCCESS,
  CREATE_ADMIN_FEEDBACK_ERROR,
  UPLOAD_ADMIN_FEEDBACK_MEDIA,
  UPLOAD_ADMIN_FEEDBACK_MEDIA_SUCCESS,
  UPLOAD_ADMIN_FEEDBACK_MEDIA_ERROR,
  UPDATE_REQUEST_STATUS,
  UPDATE_REQUEST_STATUS_SUCCESS,
  UPDATE_REQUEST_STATUS_ERROR,
  UPDATE_COMPENSATION,
  UPDATE_COMPENSATION_SUCCESS,
  UPDATE_COMPENSATION_ERROR
} from '../constants';

export const fetchAdminRequests = (status, requestId) => ({
  type: FETCH_ADMIN_REQUEST,
  status,
  requestId
});

export const fetchAdminRequestSuccess = details => ({
  type: FETCH_ADMIN_REQUEST_SUCCESS,
  details
});

export const fetchAdminRequestError = status => ({
  type: FETCH_ADMIN_REQUEST_ERROR,
  status
});

export const createAdminNotes = (details, id, status) => ({
  type: CREATE_ADMIN_NOTES,
  details,
  id,
  status
});

export const createAdminNotesSuccess = () => ({
  type: CREATE_ADMIN_NOTES_SUCCESS
});

export const createAdminNotesError = status => ({
  type: CREATE_ADMIN_NOTES_ERROR,
  status
});

export const updateCompensation = (details, id) => ({
  type: UPDATE_COMPENSATION,
  details,
  id
});

export const updateCompensationSuccess = () => ({
  type: UPDATE_COMPENSATION_SUCCESS
});

export const updateCompensationError = status => ({
  type: UPDATE_COMPENSATION_ERROR,
  status
});

export const createAdminFeedback = (details, status, requestId) => ({
  type: CREATE_ADMIN_FEEDBACK,
  details,
  status,
  requestId
});

export const createAdminFeedbackSuccess = requestId => ({
  type: CREATE_ADMIN_FEEDBACK_SUCCESS,
  requestId
});

export const createAdminFeedbackError = status => ({
  type: CREATE_ADMIN_FEEDBACK_ERROR,
  status
});

export const uploadAdminFeedbackMedia = details => ({
  type: UPLOAD_ADMIN_FEEDBACK_MEDIA,
  details
});

export const uploadAdminFeedbackMediaSuccess = () => ({
  type: UPLOAD_ADMIN_FEEDBACK_MEDIA_SUCCESS
});

export const uploadAdminFeedbackMediaError = status => ({
  type: UPLOAD_ADMIN_FEEDBACK_MEDIA_ERROR,
  status
});

export const updateRequestStatus = (details, id) => ({
  type: UPDATE_REQUEST_STATUS,
  details,
  id
});

export const updateRequestStatusSuccess = () => ({
  type: UPDATE_REQUEST_STATUS_SUCCESS
});

export const updateRequestStatusError = status => ({
  type: UPDATE_REQUEST_STATUS_ERROR,
  status
});
