import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Moment from 'react-moment';
import moment from 'moment';
import TopHeader from '../../../Components/TopHeader';
import BorderedModal from '../../../Components/BorderedModal';
import DropZoneRequest from '../../DropZoneRequest';

import CreateRequestModal from '../../../Components/CreateRequestModal';
import { placeholder } from '../../../Assets';
import {
  fetchExpertProfile,
  createRequest,
  clearClientState
} from '../../../actions';
import apiUrl from '../../../Configs/ApiUrl';
import history from '../../../Configs/history';
import autoCap from '../../../helpers/autoCap';
import Spinner from '../../../Components/Spinner';

export default function ExpertFront() {
  const params = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [showLinkedin, setShowLinkedin] = useState(false);
  const [showCalendly, setShowCalendly] = useState(false);
  const [showHire, setShowHire] = useState(false);
  const [requestMedia, setRequestMedia] = useState(null);
  const userState = useSelector(state => state.expert);
  const clientState = useSelector(state => state.client);
  useEffect(() => {
    dispatch(fetchExpertProfile(params.id));
  }, [dispatch]);

  const dayGetter = date => {
    var singleDay = moment(date, 'YYYY-MM-DD');
    var dayName = singleDay.format('dddd');
    return dayName;
  };

  const handleLinkedinClose = () => {
    setShowLinkedin(false);
  };
  const handleLinkedinShow = () => {
    setShowLinkedin(true);
  };

  const handleHireClose = () => {
    setShowHire(false);
    dispatch(clearClientState());
  };
  const handleHireShow = () => {
    setShowHire(true);
  };

  const handleCalendlyClose = () => {
    setShowCalendly(false);
  };
  const handleCalendlyShow = () => {
    setShowCalendly(true);
  };

  const onUploadRequest = fileNames => {
    setRequestMedia(fileNames);
  };

  const onSubmitRequest = data => {
    const formData = new FormData();
    formData.append('expertUserId', data.expertUserId);
    if (requestMedia) {
      formData.append('file', requestMedia);
    }
    formData.append('description', data.description);
    dispatch(createRequest(formData));
  };

  const renderLinkedin = expert => {
    return (
      <>
        <div className='m-body' style={{ minHeight: 200 }}>
          <>
            <div
              style={{
                minHeight: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F1F1F1',
                fontSize: 13,
                borderRadius: 10
              }}
            >
              {expert.linkedInProfileUrl}
            </div>
          </>
        </div>
      </>
    );
  };

  const renderHire = expert => {
    if (
      clientState.state === 'success' &&
      clientState.mode === 'create_request'
    ) {
      history.push(`/expert-details/${expert.id}`);
    }
    return (
      <>
        <div className='m-body' style={{ minHeight: 200 }}>
          <>
            <form onSubmit={handleSubmit(onSubmitRequest)}>
              <input
                name='expertUserId'
                type='hidden'
                ref={register}
                defaultValue={expert.userId}
              />
              <div className='form-group'>
                <small style={{ fontWeight: 'bold' }}>Description</small>
                <textarea
                  placeholder='Only 300 characters allowed'
                  maxLength='300'
                  rows='5'
                  name='description'
                  ref={register({ required: true })}
                  className='form-control custom-input'
                  style={{
                    background: '#E1E1E1',
                    color: 'black',
                    fontSize: 13
                  }}
                ></textarea>
                {errors.description && (
                  <span className='error'>Description is required</span>
                )}
              </div>

              <DropZoneRequest
                state={clientState.state}
                mode={clientState.mode}
                onUploadMedia={onUploadRequest}
                clientState={clientState}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <button
                  disabled={
                    clientState.state === 'loading' &&
                    clientState.mode === 'create_request'
                      ? true
                      : false
                  }
                  className='btn custom-adm-btn'
                  style={{ width: 300 }}
                  type='submit'
                >
                  {clientState.state === 'loading' &&
                  clientState.mode === 'create_request' ? (
                    <Spinner cWdith={20} cHeight={20} />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          </>
        </div>
      </>
    );
  };

  const renderCalendly = expert => {
    return (
      <>
        <div className='m-body' style={{ minHeight: 200 }}>
          <>
            <div
              style={{
                minHeight: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F1F1F1',
                fontSize: 13,
                borderRadius: 10
              }}
            >
              {expert.meetingUrl}
            </div>
          </>
        </div>
      </>
    );
  };
  return (
    <React.Fragment>
      {showHire && (
        <CreateRequestModal
          title='Create Description'
          show={showHire}
          handleClose={handleHireClose}
          handleShow={handleHireShow}
        >
          {renderHire(userState.userProfile.ExpertProfile)}
        </CreateRequestModal>
      )}
      {showLinkedin && (
        <BorderedModal
          title='Linkedin'
          show={showLinkedin}
          handleClose={handleLinkedinClose}
          handleShow={handleLinkedinShow}
        >
          {renderLinkedin(userState.userProfile.ExpertProfile)}
        </BorderedModal>
      )}
      {showCalendly && (
        <BorderedModal
          title='Meeting Url'
          show={showCalendly}
          handleClose={handleCalendlyClose}
          handleShow={handleCalendlyShow}
        >
          {renderCalendly(userState.userProfile.ExpertProfile)}
        </BorderedModal>
      )}
      <TopHeader role='Client' />
      {/* {userState.mode === 'fetch_expert' && userState.state === 'loading' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 100
          }}
        >
          <Spinner color='#000' cWdith={50} cHeight={60} />
        </div>
      )} */}

      {userState.userProfile && Object.keys(userState.userProfile).length > 0 && (
        <div style={{ background: '#e3e1e1' }}>
          <div className='profileSection'>
            <div className='profileExpert'>
              <div className='img-profile-p'>
                <div className='expertBanner'></div>

                <div
                  className='edit-icon'
                  style={{ width: 100 }}
                  onClick={() => handleHireShow()}
                >
                  Hire
                </div>
              </div>

              <img
                src={
                  userState.userProfile.ExpertProfile.profileImageUrl
                    ? apiUrl +
                      '/' +
                      userState.userProfile.ExpertProfile.profileImageUrl
                    : placeholder
                }
                className='profileExpertImg roundImg'
                style={{ marginLeft: 40, position: 'absolute' }}
                alt='User Logo'
              />

              <div className='profileExpertBody' style={{ marginTop: 100 }}>
                <div className='expertSection'>
                  <h5>
                    {userState.userProfile &&
                      userState.userProfile.firstName &&
                      autoCap(userState.userProfile.firstName) +
                        ' ' +
                        autoCap(userState.userProfile.lastName)}
                  </h5>
                  <p>
                    {userState.userProfile.ExpertProfile &&
                      userState.userProfile.ExpertProfile.headline}
                  </p>
                </div>
                <div className='expertSection'>
                  <div
                    className='exployeDetails empCol'
                    style={{ marginTop: 10 }}
                  >
                    <div className='empLeft'>
                      <h5>Current Employement</h5>
                    </div>
                    <div className='empRight'>
                      {userState.userProfile.ExpertProfile &&
                        userState.userProfile.ExpertProfile.cvUrl !== null && (
                          <div>
                            <a
                              className='btn btnCv'
                              href={`${
                                userState.userProfile.ExpertProfile.cvUrl !==
                                null
                                  ? apiUrl +
                                    `/` +
                                    userState.userProfile.ExpertProfile.cvUrl
                                  : '#!'
                              }`}
                              target='_blank'
                            >
                              <i
                                className='fas fa-arrow-circle-down'
                                style={{
                                  color: '#3aa7e4',
                                  marginRight: 5,
                                  marginLeft: '-8px',
                                  fontSize: 30
                                }}
                              ></i>
                              Download CV
                            </a>
                          </div>
                        )}
                      {userState.userProfile &&
                        userState.userProfile.ExpertProfile
                          .linkedInProfileUrl && (
                          <a
                            target='_blank'
                            className='employe-icon'
                            href={
                              userState.userProfile.ExpertProfile
                                .linkedInProfileUrl
                            }
                          >
                            <i
                              className='fab fa-linkedin-in'
                              style={{
                                color: '#fff',
                                fontSize: 25
                              }}
                            ></i>
                          </a>
                        )}
                    </div>
                  </div>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <div>
                        <img
                          src={
                            userState.userProfile.ExpertProfile
                              .organizationImageUrl
                              ? apiUrl +
                                '/' +
                                userState.userProfile.ExpertProfile
                                  .organizationImageUrl
                              : placeholder
                          }
                          className='expertImg'
                          alt='User Logo'
                        />
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <p className='p-exp'>
                          {userState.userProfile.ExpertProfile &&
                          userState.userProfile.ExpertProfile.organization !==
                            null
                            ? userState.userProfile.ExpertProfile.organization
                            : 'Organization: N/A'}
                        </p>
                        <p className='p-det'>
                          {userState.userProfile.ExpertProfile &&
                          userState.userProfile.ExpertProfile.year !== null
                            ? userState.userProfile.ExpertProfile.year
                            : 'Year: N/A'}
                        </p>
                        <p className='p-det'>
                          {userState.userProfile.ExpertProfile &&
                          userState.userProfile.ExpertProfile.role !== null
                            ? userState.userProfile.ExpertProfile.role
                            : 'Role: N/A'}
                        </p>
                        <p className='p-det'>
                          {userState.userProfile.ExpertProfile &&
                          userState.userProfile.ExpertProfile.description !==
                            null
                            ? userState.userProfile.ExpertProfile.description
                            : 'Description: N/A'}
                        </p>
                      </div>
                    </div>

                    {/* right */}
                  </div>
                </div>

                <div className='expertSection'>
                  <h5>Industry</h5>
                  <div className='exployeSkills'>
                    {userState.userProfile.ExpertProfile.ExpertIndustry &&
                    Object.keys(
                      userState.userProfile.ExpertProfile.ExpertIndustry
                    ).length > 0 ? (
                      <div className='skill'>
                        <span className='text-center'>
                          {
                            userState.userProfile.ExpertProfile.ExpertIndustry
                              .Industry.name
                          }
                        </span>
                      </div>
                    ) : (
                      <div>
                        <h6>N/A</h6>
                      </div>
                    )}
                  </div>
                </div>

                <div className='expertSection'>
                  <h5>Skills</h5>
                  <div className='exployeSkills'>
                    {userState.userProfile.ExpertProfile.ExpertSkill &&
                    userState.userProfile.ExpertProfile.ExpertSkill.length >
                      0 ? (
                      userState.userProfile.ExpertProfile.ExpertSkill.map(
                        skill => (
                          <div
                            className='skill'
                            key={`skill-${skill.Skill.id}`}
                          >
                            <span className='text-center'>
                              {skill.Skill.title}
                            </span>
                          </div>
                        )
                      )
                    ) : (
                      <div>
                        <h6>N/A</h6>
                      </div>
                    )}
                  </div>
                </div>

                <div className='expertSection'>
                  <h5>Services</h5>
                  <div className='exployeSkills'>
                    {userState.userProfile.ExpertProfile.ExpertService &&
                    userState.userProfile.ExpertProfile.ExpertService.length >
                      0 ? (
                      userState.userProfile.ExpertProfile.ExpertService.map(
                        service => (
                          <div
                            className='skill'
                            key={`service-${service.Service.id}`}
                          >
                            <span className='text-center'>
                              {service.Service.title}
                            </span>
                          </div>
                        )
                      )
                    ) : (
                      <div>
                        <h6>N/A</h6>
                      </div>
                    )}
                  </div>
                </div>

                {/*  */}
                <div className='expertSection'>
                  <h5>Publication</h5>
                  {userState.userProfile.ExpertProfile.Publication &&
                  userState.userProfile.ExpertProfile.Publication.length > 0 ? (
                    userState.userProfile.ExpertProfile.Publication.map(
                      userPublication => (
                        <div
                          key={'d-' + userPublication.id}
                          className='exployeDetails'
                          style={{ marginTop: 10 }}
                        >
                          <div className='empLeft'>
                            <div>
                              <img
                                src={placeholder}
                                className='expertImg'
                                alt='User Logo'
                              />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                              <p className='p-exp'>{userPublication.title}</p>
                              <p className='p-det'>
                                {userPublication.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div>
                      <h6>N/A</h6>
                    </div>
                  )}
                </div>

                {/* end */}

                {/* start */}
                <div className='expertSection'>
                  <h5>Achievement(s)</h5>
                  {userState.userProfile.ExpertProfile.Achievement &&
                  userState.userProfile.ExpertProfile.Achievement.length > 0 ? (
                    userState.userProfile.ExpertProfile.Achievement.map(
                      userAcheivement => (
                        <div
                          className='exployeDetails'
                          key={'da' + userAcheivement.id}
                          style={{ marginTop: 10 }}
                        >
                          <div className='empLeft'>
                            <div>
                              <img
                                src={placeholder}
                                className='expertImg'
                                alt='User Logo'
                              />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                              <p className='p-exp'>{userAcheivement.title} </p>
                              <p className='p-det'>
                                {userAcheivement.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div>
                      <h6>N/A</h6>
                    </div>
                  )}
                </div>
                {/* end */}

                {/* start */}
                <div className='expertSection'>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div className='empLeft'>
                      <h5>Time Availability</h5>
                    </div>
                    <div className='empRight'>
                      {userState.userProfile.ExpertProfile &&
                        userState.userProfile.ExpertProfile.meetingUrl && (
                          <a
                            className='edit-icon'
                            href={
                              userState.userProfile.ExpertProfile.meetingUrl
                            }
                            target='_blank'
                          >
                            <i
                              className='far fa-calendar'
                              style={{
                                color: '#fff',
                                fontSize: 25
                              }}
                            ></i>
                          </a>
                        )}
                    </div>
                  </div>
                  <div className='exployeDetails' style={{ marginTop: 10 }}>
                    <div>
                      {userState.userProfile.ExpertProfile.TimeAvailability &&
                      userState.userProfile.ExpertProfile.TimeAvailability
                        .length > 0 ? (
                        userState.userProfile.ExpertProfile.TimeAvailability.map(
                          time => (
                            <p className='t-emp'>
                              <span>
                                {' '}
                                {dayGetter(time.date) + ', '}
                                <Moment format='DD MMMM, YYYY'>
                                  {time.date}
                                </Moment>
                              </span>{' '}
                              <small>
                                {moment(time.startTime, 'hh:mm').format(
                                  'hh:mm A'
                                )}{' '}
                                -{' '}
                                {moment(time.endTime, 'hh:mm').format(
                                  'hh:mm A'
                                )}{' '}
                                - {time.TimeZone.title}
                              </small>{' '}
                              {time.isRecursive ? ' - Recurring' : ''}
                            </p>
                          )
                        )
                      ) : (
                        <div>
                          <h6>N/A</h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* end */}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
