import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const RestrictedRoute = ({ component: Component, role, ...rest }) => {
  const authState = useSelector(state => state.auth);
  let token = '';
  let redirectUrl = '/';
  if (authState) {
    if (role === 'Client') {
      token = authState.token;
      redirectUrl = '/';
    }
    if (role === 'Expert') {
      token = authState.token;
      redirectUrl = '/';
    }
    if (role === 'Admin') {
      token = authState.adminToken;
      redirectUrl = '/admin';
    }
    return (
      <Route
        {...rest}
        render={props =>
          token != '' ? <Component {...props} /> : <Redirect to={redirectUrl} />
        }
      />
    );
  } else {
    return <Spinner />;
  }
};

export default RestrictedRoute;
