import React from 'react';
import { ArrowBack } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import history from '../Configs/history';

const TopHeader = ({ role, showArrow, isChangePass }) => {
  const location = useLocation();
  const fromPath = new URLSearchParams(location.search).get('from');

  let url = '/';
  if (role === 'Admin' && fromPath) {
    url = `/${fromPath}`;
  } else if (role === 'Admin' && isChangePass) {
    url = '/dashboard';
  } else if (role === 'Client') {
    url = '/client';
  } else {
    url = '/';
  }
  return (
    <div className='mainHeader'>
      <div className='topHeader-b'>
        {role !== 'Admin' && (
          <ArrowBack
            className='custom-icon'
            onClick={() => history.push(url)}
          />
        )}

        {role === 'Admin' && showArrow && (
          <ArrowBack
            className='custom-icon'
            onClick={() => history.push(url)}
            // onClick={() => history.goBack()}
          />
        )}
        <h3
          className='logo'
          onClick={() => history.push(role === 'Admin' ? '/dashboard' : '/')}
        >
          Expert <span style={{ color: '#0d1136' }}>Marketplace</span>
        </h3>
      </div>
    </div>
  );
};

export default TopHeader;
