export default function authorizedPutMediaHeaders(request, token) {
  return {
    method: 'PUT',
    headers: {
      Accept: '*/*',
      'x-access-token': `${token}`
    },
    body: request
  };
}
