import {
  FETCH_REQUEST,
  FETCH_REQUEST_SUCCESS,
  FETCH_REQUEST_ERROR
} from '../constants';

export const fetchRequests = () => ({
  type: FETCH_REQUEST
});

export const fetchRequestSuccess = details => ({
  type: FETCH_REQUEST_SUCCESS,
  details
});

export const fetchRequestError = status => ({
  type: FETCH_REQUEST_ERROR,
  status
});
