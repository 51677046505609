import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopHeader from '../../Components/TopHeader';
import LeftSideBar from '../../Components/LeftSideBar';
import Content from '../../Components/Content';
import { DoneAllOutlined } from '@material-ui/icons';
import { fetchDashboard } from '../../actions';
import Spinner from '../../Components/Spinner';

export default function Dashboard() {
  const dispatch = useDispatch();
  const dashboardState = useSelector(state => state.dashboard);
  useEffect(() => {
    dispatch(fetchDashboard());
  }, [dispatch]);
  return (
    <React.Fragment>
      <TopHeader role='Admin' />
      <div className='wrapper'>
        <LeftSideBar />

        <Content>
          {Object.keys(dashboardState.dashboard).length > 0 ? (
            <div className='row'>
              <div className='col-md-4'>
                <div className='dashboardItems'>
                  <div className='items'>
                    <h6>Total Projects</h6>
                    <div className='itemIcon'>
                      <DoneAllOutlined style={{ color: '#3aa7e4' }} />
                    </div>
                  </div>

                  <div className='itemCounter'>
                    <h2 className='text-center'>
                      {dashboardState.dashboard?.projects.total}
                    </h2>
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='dashboardItems'>
                  <div className='items'>
                    <h6>Total Users</h6>
                    <div className='itemIcon'>
                      <i
                        className='fas fa-users'
                        style={{ color: '#3aa7e4' }}
                      ></i>
                    </div>
                  </div>

                  <div className='itemCounter'>
                    <h2 className='text-center'>
                      {dashboardState.dashboard?.users.total}
                    </h2>
                  </div>
                  <div className='clientDetails'>
                    <h6 style={{ fontSize: 13 }}>
                      <span style={{ color: '#3aa7e4' }}>Client</span>{' '}
                      {dashboardState.dashboard?.users.clients}
                    </h6>
                    <h6 style={{ fontSize: 13 }}>
                      Expert {dashboardState.dashboard?.users.experts}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner color='#000' />
            </div>
          )}
        </Content>
      </div>
    </React.Fragment>
  );
}
