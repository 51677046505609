export const FETCH_ADMIN_REQUEST = 'MarketPlace/Admin/FETCH_ADMIN_REQUEST';

export const FETCH_ADMIN_REQUEST_SUCCESS =
  'MarketPlace/Admin/FETCH_ADMIN_REQUEST_SUCCESS';

export const FETCH_ADMIN_REQUEST_ERROR =
  'MarketPlace/Admin/FETCH_ADMIN_REQUEST_ERROR';

export const CREATE_ADMIN_NOTES = 'MarketPlace/Admin/CREATE_ADMIN_NOTES';

export const CREATE_ADMIN_NOTES_SUCCESS =
  'MarketPlace/Admin/CREATE_ADMIN_NOTES_SUCCESS';

export const CREATE_ADMIN_NOTES_ERROR =
  'MarketPlace/Admin/CREATE_ADMIN_NOTES_ERROR';

export const UPDATE_COMPENSATION = 'MarketPlace/Admin/UPDATE_COMPENSATION';

export const UPDATE_COMPENSATION_SUCCESS =
  'MarketPlace/Admin/UPDATE_COMPENSATION_SUCCESS';

export const UPDATE_COMPENSATION_ERROR =
  'MarketPlace/Admin/UPDATE_COMPENSATION_ERROR';

export const CREATE_ADMIN_FEEDBACK = 'MarketPlace/Admin/CREATE_ADMIN_FEEDBACK';

export const CREATE_ADMIN_FEEDBACK_SUCCESS =
  'MarketPlace/Admin/CREATE_ADMIN_FEEDBACK_SUCCESS';

export const CREATE_ADMIN_FEEDBACK_ERROR =
  'MarketPlace/Admin/CREATE_ADMIN_FEEDBACK_ERROR';

export const UPLOAD_ADMIN_FEEDBACK_MEDIA =
  'MarketPlace/Admin/UPLOAD_ADMIN_FEEDBACK_MEDIA';

export const UPLOAD_ADMIN_FEEDBACK_MEDIA_SUCCESS =
  'MarketPlace/Admin/UPLOAD_ADMIN_FEEDBACK_MEDIA_SUCCESS';

export const UPLOAD_ADMIN_FEEDBACK_MEDIA_ERROR =
  'MarketPlace/Admin/UPLOAD_ADMIN_FEEDBACK_MEDIA_ERROR';

export const UPDATE_REQUEST_STATUS = 'MarketPlace/Admin/UPDATE_REQUEST_STATUS';

export const UPDATE_REQUEST_STATUS_SUCCESS =
  'MarketPlace/Admin/UPDATE_REQUEST_STATUS_SUCCESS';

export const UPDATE_REQUEST_STATUS_ERROR =
  'MarketPlace/Admin/UPDATE_REQUEST_STATUS_ERROR';
