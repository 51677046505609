import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import apiUrl from '../Configs/ApiUrl';
import apiCall from '../Services/ApiCall';
import { toastr } from 'react-redux-toastr';
import {
  UPDATE_CLIENT_PROFILE,
  FETCH_CLIENT_PROFILE,
  UPLOAD_CLIENT_PROFILE_MEDIA,
  FETCH_CLIENT_EXPERT_PROFILE,
  CREATE_EXPERT_REQUEST,
  FILTER_EXPERT_PROFILE
} from '../constants';
import {
  updateClientProfileSuccess,
  updateClientProfileError,
  clearUser,
  fetchClientProfileError,
  fetchClientProfileSuccess,
  uploadClientProfileMediaSuccess,
  uploadClientProfileMediaError,
  fetchClientProfile,
  fetchClientExpertProfileSuccess,
  fetchClientExpertProfileError,
  createRequestSuccess,
  createRequestError,
  filterExpertProfileSuccess,
  filterExpertProfileError,
  logoutUser
} from '../actions';
import AuthorizedPutMediaHeaders from '../Configs/AuthorizedPutMediaHeaders';
import AuthorizedPutDataHeaders from '../Configs/AuthorizedPutDataHeaders';
import AuthorizedGetHeaders from '../Configs/AuthorizedGetHeaders';
import AuthorizedPostMediaHeaders from '../Configs/AuthorizedPostMediaHeaders';
import { tokenSelector } from '../selectors/authSelecter';

export function* updateClientProfile(action) {
  const token = yield select(tokenSelector);

  const requestUrl = `${apiUrl}/clientProfile/update`;
  const options = AuthorizedPutDataHeaders(action.details, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(updateClientProfileError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(updateClientProfileSuccess());
      yield put(fetchClientProfile());
      toastr.success('Success!', 'Successfully updated.');
    }
  } catch (e) {
    if (e.code === '2008') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(updateClientProfileError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* clientProfileSaga() {
  yield takeLatest(UPDATE_CLIENT_PROFILE, updateClientProfile);
}

export function* fetchClientProfileDetails() {
  const token = yield select(tokenSelector);

  const requestUrl = `${apiUrl}/clientProfile`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchClientProfileError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(fetchClientProfileSuccess(response.data));
    }
  } catch (e) {
    yield put(fetchClientProfileError(e.message));
  }
}

function* fetchProfileSaga() {
  yield takeLatest(FETCH_CLIENT_PROFILE, fetchClientProfileDetails);
}

export function* fetchClientExpertProfileDetails(action) {
  const token = yield select(tokenSelector);
  let offsetData = 0;
  if (action.offset === -1) {
    offsetData = 0;
  } else {
    offsetData = action.offset;
  }

  const requestUrl = `${apiUrl}/expertProfile/getExperts?limit=4&offset=${offsetData}`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(fetchClientExpertProfileError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(
        fetchClientExpertProfileSuccess(response.data.count, response.data.rows)
      );
    }
  } catch (e) {
    yield put(fetchClientExpertProfileError(e.message));
  }
}

function* fetchExpertProfileSaga() {
  yield takeLatest(
    FETCH_CLIENT_EXPERT_PROFILE,
    fetchClientExpertProfileDetails
  );
}

export function* filterxpertProfileDetails(action) {
  const token = yield select(tokenSelector);
  let endPoint = '';
  if (action.query !== '') {
    endPoint += `&q=${action.query}`;
  }

  if (action.industry !== '-1') {
    endPoint += `&industryId=${parseInt(action.industry)}`;
  }
  if (action.skill !== '-1') {
    endPoint += `&skillId=${action.skill}`;
  }
  if (action.service !== '-1') {
    endPoint += `&serviceId=${action.service}`;
  }
  const requestUrl = `${apiUrl}/expertProfile/filterExperts?limit=4000&offset=0${endPoint}`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(filterExpertProfileError(response.message));
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(
        filterExpertProfileSuccess(response.data.count, response.data.rows)
      );
    }
  } catch (e) {
    yield put(filterExpertProfileError(e.message));
  }
}

function* filterExpertProfileSaga() {
  yield takeLatest(FILTER_EXPERT_PROFILE, filterxpertProfileDetails);
}

export function* uploadClientProfileMedia(action) {
  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/clientProfile/updateProfileImage`;
  const options = AuthorizedPutMediaHeaders(
    action.files,
    token,
    'imageProfile'
  );
  try {
    const response = yield call(apiCall, requestUrl, options);
    if (response.status === 'error') {
      yield put(uploadClientProfileMediaError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(uploadClientProfileMediaSuccess());
      toastr.success('Success!', 'Successfully updated.');
      yield put(fetchClientProfile());
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(uploadClientProfileMediaError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* uploadClientProfileMediaSaga() {
  yield takeLatest(UPLOAD_CLIENT_PROFILE_MEDIA, uploadClientProfileMedia);
}

export function* createRequestDetails(action) {
  const token = yield select(tokenSelector);
  const requestUrl = `${apiUrl}/request/createRequest`;
  const options = AuthorizedPostMediaHeaders(action.details, token);
  try {
    const response = yield call(apiCall, requestUrl, options);

    if (response.status === 'error') {
      yield put(createRequestError(response.message));
      toastr.error('Error!', response.message);
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(logoutUser());
      }
    } else {
      yield put(createRequestSuccess());
      toastr.success('Success!', 'Successfully created.');
    }
  } catch (e) {
    if (e.code === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/client'));
    } else {
      yield put(createRequestError(e.message));
      toastr.error('Error!', e.message);
    }
  }
}

function* createRequestSaga() {
  yield takeLatest(CREATE_EXPERT_REQUEST, createRequestDetails);
}

export default function* rootSaga() {
  yield all([
    clientProfileSaga(),
    fetchProfileSaga(),
    uploadClientProfileMediaSaga(),
    fetchExpertProfileSaga(),
    createRequestSaga(),
    filterExpertProfileSaga()
  ]);
}
