import React from 'react';
import { Modal } from 'react-bootstrap';
import './modalExpert.css';

export default function BorderedModal({ title, children, show, handleClose }) {
  return (
    <>
      <Modal
        scrollable={true}
        size='lg'
        dialogClassName='custom-expert-modal'
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}
