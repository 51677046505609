import { applyMiddleware, compose, createStore } from 'redux';
import reducers from '../reducers/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import history from '../Configs/history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const persistConfig = {
    // Root
    key: 'root',
    storage: storage,

    // Whitelist (Save Specific Reducers)
    whitelist: ['auth']

    // Blacklist (Don't Save Specific Reducers)
    // blacklist: ['counterReducer']
  };
  // Middleware: Redux Persist Persisted Reducer
  const persistedReducer = persistReducer(persistConfig, reducers(history));

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  // Middleware: Redux Persist Persister
  persistStore(store);

  return store;
}
export { history };
