import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_SINGLE_USER,
  FETCH_SINGLE_USER_SUCCESS,
  FETCH_SINGLE_USER_ERROR,
  FILTER_USERS,
  FILTER_USERS_SUCCESS,
  FILTER_USERS_ERROR,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_ERROR
} from '../constants';

const initialAuthState = {
  state: 'initial',
  mode: 'initial',
  users: [],
  singleUser: {},
  status: ''
};

export function userReducer(state = initialAuthState, action) {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, mode: 'fetch', state: 'loading', status: '' };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        state: 'success',
        users: action.details,
        status: ''
      };

    case FETCH_USER_ERROR:
      return { ...state, state: 'error', status: action.status };

    case FETCH_SINGLE_USER:
      return { ...state, mode: 'single_user', state: 'loading', status: '' };

    case FETCH_SINGLE_USER_SUCCESS:
      return {
        ...state,
        state: 'success',
        singleUser: action.user,
        status: ''
      };

    case FETCH_SINGLE_USER_ERROR:
      return { ...state, state: 'error', status: action.status };

    case FILTER_USERS:
      return {
        ...state,
        mode: 'fetch',
        state: 'loading',
        status: ''
      };

    case FILTER_USERS_SUCCESS:
      return {
        ...state,
        state: 'success',
        users: action.details,
        status: ''
      };

    case FILTER_USERS_ERROR:
      return { ...state, state: 'error', status: action.status };

    case UPDATE_USER_STATUS:
      return {
        ...state,
        mode: 'update_user_status',
        state: 'loading',
        status: ''
      };

    case UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        state: 'success',
        status: ''
      };

    case UPDATE_USER_STATUS_ERROR:
      return { ...state, state: 'error', status: action.status };

    default:
      return state;
  }
}
