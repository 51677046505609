import {
  FETCH_EXPERT_PROFILE,
  FETCH_EXPERT_PROFILE_SUCCESS,
  FETCH_EXPERT_PROFILE_ERROR,
  UPDATE_EXPERT_PROFILE,
  UPDATE_EXPERT_PROFILE_SUCCESS,
  UPDATE_EXPERT_PROFILE_ERROR,
  UPDATE_EXPERT_GENERAL_DETAILS,
  UPDATE_EXPERT_GENERAL_DETAILS_SUCCESS,
  UPDATE_EXPERT_GENERAL_DETAILS_ERROR,
  UPLOAD_EXPERT_PROFILE_MEDIA,
  UPLOAD_EXPERT_PROFILE_MEDIA_SUCCESS,
  UPLOAD_EXPERT_PROFILE_MEDIA_ERROR,
  CREATE_EXPERT_ACHEIVEMENT,
  CREATE_EXPERT_ACHEIVEMENT_SUCCESS,
  CREATE_EXPERT_ACHEIVEMENT_ERROR,
  UPDATE_EXPERT_PUBLICATION,
  UPDATE_EXPERT_PUBLICATION_SUCCESS,
  UPDATE_EXPERT_PUBLICATION_ERROR,
  CREATE_EXPERT_PUBLICATION,
  CREATE_EXPERT_PUBLICATION_SUCCESS,
  CREATE_EXPERT_PUBLICATION_ERROR,
  UPDATE_EXPERT_INDUSTRY,
  UPDATE_EXPERT_INDUSTRY_SUCCESS,
  UPDATE_EXPERT_INDUSTRY_ERROR,
  CREATE_TIMINGS,
  CREATE_TIMINGS_SUCCESS,
  CREATE_TIMINGS_ERROR,
  CLEAR_STATUS
} from '../constants';

export const fetchExpertProfile = id => ({
  type: FETCH_EXPERT_PROFILE,
  id
});

export const fetchExpertProfileSuccess = details => ({
  type: FETCH_EXPERT_PROFILE_SUCCESS,
  details
});

export const fetchExpertProfileError = status => ({
  type: FETCH_EXPERT_PROFILE_ERROR,
  status
});

export const updateExpertProfile = details => ({
  type: UPDATE_EXPERT_PROFILE,
  details
});

export const updateExpertProfileSuccess = () => ({
  type: UPDATE_EXPERT_PROFILE_SUCCESS
});

export const updateExpertProfileError = status => ({
  type: UPDATE_EXPERT_PROFILE_ERROR,
  status
});

export const updateGeneralDetails = (details, mode, endPoint, formType) => ({
  type: UPDATE_EXPERT_GENERAL_DETAILS,
  details,
  mode,
  endPoint,
  formType
});

export const updateGeneralDetailsSuccess = () => ({
  type: UPDATE_EXPERT_GENERAL_DETAILS_SUCCESS
});

export const updateGeneralDetailsError = status => ({
  type: UPDATE_EXPERT_GENERAL_DETAILS_ERROR,
  status
});

export const uploadExpertProfileMedia = (files, mode, endPoint, fieldName) => ({
  type: UPLOAD_EXPERT_PROFILE_MEDIA,
  files,
  mode,
  endPoint,
  fieldName
});

export const uploadExpertProfileMediaError = status => ({
  type: UPLOAD_EXPERT_PROFILE_MEDIA_ERROR,
  status
});

export const uploadExpertProfileMediaSuccess = () => ({
  type: UPLOAD_EXPERT_PROFILE_MEDIA_SUCCESS
});

export const createAcheivement = details => ({
  type: CREATE_EXPERT_ACHEIVEMENT,
  details
});

export const createAcheivementSuccess = () => ({
  type: CREATE_EXPERT_ACHEIVEMENT_SUCCESS
});

export const createAcheivementError = status => ({
  type: CREATE_EXPERT_ACHEIVEMENT_ERROR,
  status
});

export const createPublication = details => ({
  type: CREATE_EXPERT_PUBLICATION,
  details
});

export const createPublicationSuccess = () => ({
  type: CREATE_EXPERT_PUBLICATION_SUCCESS
});

export const createPublicationError = status => ({
  type: CREATE_EXPERT_PUBLICATION_ERROR,
  status
});

export const updatePublication = (details, formType) => ({
  type: UPDATE_EXPERT_PUBLICATION,
  details,
  formType
});

export const updatePublicationSuccess = () => ({
  type: UPDATE_EXPERT_PUBLICATION_SUCCESS
});

export const updatePublicationError = status => ({
  type: UPDATE_EXPERT_PUBLICATION_ERROR,
  status
});

export const createTimings = details => ({
  type: CREATE_TIMINGS,
  details
});

export const createTimingsSuccess = () => ({
  type: CREATE_TIMINGS_SUCCESS
});

export const createTimingsError = status => ({
  type: CREATE_TIMINGS_ERROR,
  status
});

export const updateExpertIndustry = details => ({
  type: UPDATE_EXPERT_INDUSTRY,
  details
});

export const updateExpertIndustrySuccess = () => ({
  type: UPDATE_EXPERT_INDUSTRY_SUCCESS
});

export const updateExpertIndustryError = status => ({
  type: UPDATE_EXPERT_INDUSTRY_ERROR,
  status
});

export const clearStatus = () => ({
  type: CLEAR_STATUS
});
