import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../../Components/InputField';
import Spinner from '../../../../Components/Spinner';
import { registerUser } from '../../../../actions';
import { useForm } from 'react-hook-form';
import history from '../../../../Configs/history';

export default function RegisterModal({ setAuthStatus }) {
  const dispatch = useDispatch();
  const [role, setRole] = React.useState('Client');
  const authSelector = useSelector(state => state.auth);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    const formData = {
      ...data,
      role
    };
    dispatch(registerUser(formData));
  };
  return (
    <div>
      <div className='m-body'>
        <p className='title'>Sign Up</p>
        <p className='subTitle'>
          By signing up, you agree to Expert Marketplace
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            keyError={errors}
            name='firstName'
            type='text'
            value=''
            keyRef={register({ required: true })}
            placeholder='First Name'
          />
          {errors.firstName && (
            <span className='error'>First Name is required</span>
          )}

          <InputField
            keyError={errors}
            name='lastName'
            type='text'
            value=''
            keyRef={register({ required: true })}
            placeholder='Last Name'
          />
          {errors.lastName && (
            <span className='error'>Last Name is required</span>
          )}
          <InputField
            keyError={errors}
            name='email'
            type='email'
            value=''
            keyRef={register({ required: true })}
            placeholder='Email'
          />
          {errors.email && <span className='error'>Email is required</span>}
          <InputField
            keyError={errors}
            name='password'
            type='password'
            keyRef={register({ required: true })}
            value=''
            placeholder='Password'
          />
          {errors.password && (
            <span className='error'>Password is required</span>
          )}

          <div className='log-checkbox'>
            <div
              className='form-group'
              style={{ marginLeft: 20, fontSize: 14, color: '#797b8f' }}
            >
              <input
                onClick={() => setRole('Client')}
                checked={role === 'Client' ? true : false}
                type='checkbox'
                name='role'
                className='form-check-input'
                id='check1'
              />
              <label className='form-check-label' for='flexCheckDefault'>
                Client
              </label>
            </div>
            <div
              className='form-group'
              style={{ fontSize: 14, color: '#797b8f' }}
            >
              <input
                onClick={() => setRole('Expert')}
                checked={role === 'Expert' ? true : false}
                type='checkbox'
                name='role'
                className='form-check-input'
                id='check2'
              />
              <label className='form-check-label' for='flexCheckDefault'>
                Expert
              </label>
            </div>
          </div>
          <button
            disabled={authSelector.state === 'loading' ? true : false}
            className='btn btn-block btn-primary custom-btn'
            type='submit'
          >
            {authSelector.state === 'loading' ? <Spinner /> : 'Continue'}
          </button>
        </form>

        <p className='subTitle' style={{ marginTop: 20 }}>
          By signing up, you agree to Experts Labs.{' '}
          <span style={{ color: '#3aa7e4', fontWeight: 'bold' }} onClick={() => history.push('terms-and-conditions')}>
            Terms & conditions
          </span>
        </p>
      </div>
      <div className='modal-footer-body'>
        <p className='subTitle text-center' style={{ marginTop: 20 }}>
          Already have an account?{' '}
          <span
            style={{ color: '#3aa7e4', fontWeight: 'bold' }}
            onClick={() => setAuthStatus('login')}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
}
