export default function(request, token) {
  return {
    method: 'POST',
    headers: {
      Accept: '*/*',
      'x-access-token': `${token}`
    },
    body: request
  };
}
