import React from 'react';
import { useSelector } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { makeSelectHydrated } from '../selectors/rehydrateSelector';
import history from '../Configs/history';
import {
  Login,
  Dashboard,
  Profile,
  ProfileClient,
  Expert,
  Users,
  Request,
  ProjectCompletion,
  ProjectCancelled,
  Home,
  Terms,
  ExpertProfile,
  ClientHome,
  ExpertFront,
  ForgotAdminPassword
} from '../Pages';
import PrivateRoute from '../Components/PrivateRoute';
import PrivateRouteClient from '../Components/PrivateRouteClient';
import {
  Logout,
  ResetPassword,
  VerifyPage,
  VerifyEmail,
  ChangePassword
} from '../Pages/FrontEnd/Auth';
import Spinner from '../Components/Spinner';

export default function App() {
  const hydrated = useSelector(makeSelectHydrated());

  if (hydrated) {

    return (
      <Router history={history}>
        <Switch>
          <Route exact path='/admin'>
            <Login />
          </Route>

          <Route exact path='/terms-and-conditions'>
            <Terms />
          </Route>

          <PrivateRoute path='/dashboard' component={Dashboard} role='Admin' />

          <PrivateRoute
            path='/profile/:id/:role'
            component={Profile}
            role='Admin'
          />

          <PrivateRoute
            path='/expert/:id/:role'
            component={Expert}
            role='Admin'
          />

          <PrivateRoute path='/users' component={Users} role='Admin' />

          <PrivateRoute path='/requests' component={Request} role='Admin' />

          <PrivateRoute
            path='/project-completion'
            component={ProjectCompletion}
            role='Admin'
          />

          <PrivateRoute
            path='/project-cancelled'
            component={ProjectCancelled}
            role='Admin'
          />

          <PrivateRoute
            path='/client-profile'
            component={ProfileClient}
            role='Client'
          />

          <PrivateRoute
            path='/expert-details/:id'
            component={ExpertFront}
            role='Client'
          />

          <PrivateRoute path='/client' component={ClientHome} role='Client' />

          <PrivateRoute
            path='/expert-profile'
            component={ExpertProfile}
            role='Expert'
          />

          <PrivateRoute
            path='/changePassword'
            component={ChangePassword}
            role='Client'
          />

          <Route path='/forgotAdminPassword'>
            <ForgotAdminPassword />
          </Route>

          <Route path='/resetPassword'>
            <ResetPassword />
          </Route>

          <Route path='/logout'>
            <Logout />
          </Route>

          <Route path='/verify-page'>
            <VerifyPage />
          </Route>

          <Route path='/verifyEmail'>
            <VerifyEmail />
          </Route>

          {/* <Route exact path='/'>
            <Home />
          </Route> */}
          <PrivateRouteClient
            path='/'
            component={Home}
          />
        </Switch>
      </Router>
    );
  }
  return <Spinner color='#000' />;
}
