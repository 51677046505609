import { REHYDRATE } from 'redux-persist';

const initialState = {
  hydrated: false
};

export default function hydrateReducer(state = initialState, action) {
  if (action.type === REHYDRATE) {
    return { ...state, hydrated: true };
  }
  return state;
}
