import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import apiUrl from '../Configs/ApiUrl';
import apiCall from '../Services/ApiCall';
import { FETCH_DASHBOARD } from '../constants';
import {
  fetchDashboardSuccess,
  fetchDashboardError,
  adminLogoutUser
} from '../actions';
import AuthorizedGetHeaders from '../Configs/AuthorizedGetHeaders';
import { adminTokenSelector } from '../selectors/adminSelecter';

export function* fetchDashboardDetails() {
  const token = yield select(adminTokenSelector);
  const requestUrl = `${apiUrl}/admin/dashboard`;
  const options = AuthorizedGetHeaders(token);
  try {
    const response = yield call(apiCall, requestUrl, options);
    yield put(fetchDashboardError(response.message));
    if (response.status === 'error') {
      if (
        response.code === 2006 ||
        response.code === 2007 ||
        response.code === 2008
      ) {
        yield put(adminLogoutUser());
      }
    } else {
      yield put(fetchDashboardSuccess(response.data));
    }
  } catch (e) {
    yield put(fetchDashboardError(e.message));
  }
}

function* fetchDashboardSaga() {
  yield takeLatest(FETCH_DASHBOARD, fetchDashboardDetails);
}

export default function* rootSaga() {
  yield all([fetchDashboardSaga()]);
}
