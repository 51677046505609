import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TopHeader from '../../../../Components/TopHeader';
import Spinner from '../../../../Components/Spinner';
import { changePassword } from '../../../../actions';
import { useForm } from 'react-hook-form';

export default function ChangePassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const changeType = new URLSearchParams(location.search).get('changeType');

  const authState = useSelector(state => state.auth);
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    dispatch(changePassword(data, changeType));
  };

  return (
    <React.Fragment>
      <TopHeader role={changeType} isChangePass={true} showArrow={true} />
      <div>
        <div className='profileSection'>
          <div className='profile'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='profileBody'>
                <div
                  className='input-row'
                  style={{ justifyContent: 'space-between' }}
                >
                  <input
                    name='currentPassword'
                    type='password'
                    defaultValue=''
                    ref={register({
                      required: 'You must specify your old password',
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters'
                      }
                    })}
                    className='form-control'
                    placeholder='Old Password'
                  />
                  <i title='Edit' className='fas fa-pencil-alt'></i>
                </div>
                <hr />

                {errors.currentPassword && (
                  <span className='error'>
                    {errors.currentPassword.message}
                  </span>
                )}

                <div className='input-row'>
                  <input
                    className='form-control'
                    placeholder='New Password'
                    name='newPassword'
                    type='password'
                    defaultValue=''
                    ref={register({
                      required: 'You must specify a new password',
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters'
                      }
                    })}
                  />
                  <i title='Edit' className='fas fa-pencil-alt'></i>
                </div>

                <hr />

                {errors.newPassword && (
                  <span className='error'>{errors.newPassword.message}</span>
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    disabled={authState.state === 'loading' ? true : false}
                    className='btn custom-btn'
                    style={{ width: '200px' }}
                    type='submit'
                  >
                    {authState.state === 'loading' ? (
                      <Spinner />
                    ) : (
                      'Change Password'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
