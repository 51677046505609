import React,{ useState, useEffect }  from 'react'
import LoginModal from './LoginModal';
import { Login, Register, ForgotPassword } from '../Pages/FrontEnd/Auth';

export default function Join({text, action, customClass}) {

    const [show, setShow] = useState(false);
    const [authStatus, setAuthStatus] = useState('signup');

    const handleClose = () => {
        setShow(false);
        setAuthStatus('login');
      };
      const handleShow = () => {
        setShow(true);
        setAuthStatus(action);
      };

    const renderLogin = () => {
        return <Login setAuthStatus={setAuthStatus} />;
      };
    
      const renderSignUp = () => {
        return <Register setAuthStatus={setAuthStatus} />;
      };
      const renderReset = () => {
        return <ForgotPassword setAuthStatus={setAuthStatus} />;
      };
    return (
        <>
        <LoginModal show={show} handleClose={handleClose} handleShow={handleShow}>
        {authStatus === 'login' && renderLogin()}
        {authStatus === 'reset' && renderReset()}
        {authStatus === 'signup' && renderSignUp()}
      </LoginModal>
        <button
        className={`btn ${customClass}`}
        onClick={() => handleShow(action)}
      >
        {text}
      </button>
      </>
    )
}
