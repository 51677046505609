import {
  FETCH_REQUEST,
  FETCH_REQUEST_SUCCESS,
  FETCH_REQUEST_ERROR
} from '../constants';

const initialAuthState = {
  state: 'initial',
  mode: 'initial',
  requests: [],
  status: ''
};

export function requestReducer(state = initialAuthState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
      return { ...state, mode: 'fetch', state: 'loading', status: '' };

    case FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        state: 'success',
        requests: action.details,
        status: ''
      };

    case FETCH_REQUEST_ERROR:
      return { ...state, state: 'error', status: action.status };

    default:
      return state;
  }
}
