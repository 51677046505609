import {
  CLEAR_USER,
  CLEAR_ADMIN,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  ADMIN_LOGIN,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_ERROR,
  LOGOUT,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  ADMIN_LOGOUT,
  ADMIN_LOGOUT_SUCCESS,
  ADMIN_LOGOUT_ERROR,
  REGISTER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  VERIFY_MAIL,
  VERIFY_MAIL_SUCCESS,
  VERIFY_MAIL_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  FORGOT_ADMIN_PASSWORD,
  FORGOT_ADMIN_PASSWORD_SUCCESS,
  FORGOT_ADMIN_PASSWORD_ERROR,
  RESET_ADMIN_PASSWORD,
  RESET_ADMIN_PASSWORD_SUCCESS,
  RESET_ADMIN_PASSWORD_ERROR
} from '../constants';

export const login = details => ({
  type: LOGIN,
  details
});

export const loginError = status => ({
  type: LOGIN_ERROR,
  status
});

export const loginSuccess = (user, token) => ({
  type: LOGIN_SUCCESS,
  user,
  token
});

export const adminLogin = details => ({
  type: ADMIN_LOGIN,
  details
});

export const adminLoginError = status => ({
  type: ADMIN_LOGIN_ERROR,
  status
});

export const adminLoginSuccess = (user, token) => ({
  type: ADMIN_LOGIN_SUCCESS,
  user,
  token
});

export const adminLogoutUser = () => ({
  type: ADMIN_LOGOUT
});

export const adminLogoutSuccess = () => ({
  type: ADMIN_LOGOUT_SUCCESS
});

export const adminLogoutError = () => ({
  type: ADMIN_LOGOUT_ERROR
});

export const forgotAdminPassword = email => {
  return { type: FORGOT_ADMIN_PASSWORD, email };
};

export const forgotAdminPasswordSuccess = () => ({
  type: FORGOT_ADMIN_PASSWORD_SUCCESS
});

export const forgotAdminPasswordError = () => ({
  type: FORGOT_ADMIN_PASSWORD_ERROR
});

export const resetAdminPassword = formData => ({
  type: RESET_ADMIN_PASSWORD,
  formData
});

export const resetAdminPasswordSuccess = () => ({
  type: RESET_ADMIN_PASSWORD_SUCCESS
});

export const resetAdminPasswordError = status => ({
  type: RESET_ADMIN_PASSWORD_ERROR,
  status
});

export const registerUser = details => ({
  type: REGISTER,
  details
});

export const registerSuccess = () => ({
  type: REGISTER_SUCCESS
});

export const registerError = status => ({
  type: REGISTER_ERROR,
  status
});

export const logoutUser = () => ({
  type: LOGOUT
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

export const logoutError = () => ({
  type: LOGOUT_ERROR
});

export const forgotPassword = email => {
  return { type: FORGOT_PASSWORD, email };
};

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS
});

export const forgotPasswordError = () => ({
  type: FORGOT_PASSWORD_ERROR
});

export const resetPassword = formData => ({
  type: RESET_PASSWORD,
  formData
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
});

export const resetPasswordError = status => ({
  type: RESET_PASSWORD_ERROR,
  status
});

export const changePassword = (formData, changeType) => ({
  type: CHANGE_PASSWORD,
  formData,
  changeType
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS
});

export const changePasswordError = status => ({
  type: CHANGE_PASSWORD_ERROR,
  status
});

export const verifyEmail = token => ({
  type: VERIFY_MAIL,
  token
});

export const verifyEmailSuccess = () => ({
  type: VERIFY_MAIL_SUCCESS
});

export const verifyEmailError = status => ({
  type: VERIFY_MAIL_ERROR,
  status
});

export const clearUser = () => ({
  type: CLEAR_USER
});

export const clearAdmin = () => ({
  type: CLEAR_ADMIN
});
