import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TopHeader from '../../../../Components/TopHeader';
import Spinner from '../../../../Components/Spinner';
import { verifyEmail } from '../../../../actions';

export default function Verify() {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const authState = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(verifyEmail(token));
  }, [token]);

  return (
    <React.Fragment>
      <TopHeader />
      <div>
        <div className='profileSection'>
          <div className='profile' style={{ width: '80%' }}>
            {authState.state === 'loading' &&
            authState.mode === 'verify-email' ? (
              <Spinner color='#000' />
            ) : (
              <div className='col-md-12 '>
                {authState.mode === 'verify-email' &&
                authState.state === 'error' ? (
                  <div
                    className='alert alert-warning text-center'
                    style={{ padding: '50px 10px 50px 10px' }}
                  >
                    <strong>{authState.status}.</strong>
                  </div>
                ) : (
                  <div
                    className='alert alert-success text-center'
                    style={{ padding: '50px 10px 50px 10px' }}
                  >
                    <strong>
                      Email verified successfully. You will be able to Sign In
                      once the Admin approve your profile.
                    </strong>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
